<div
	mat-dialog-content
	class="flex flex-col">
	<div class="flex flex-row items-center justify-end gap-x-4">
		<label>{{ "protocol.share.enable.label" | translate }}</label>
		<mat-spinner
			color="primary"
			diameter="40"
			*ngIf="isLoading2"></mat-spinner>
		<mat-slide-toggle
			color="primary"
			[checked]="protocol.sharing.enabled"
			*ngIf="!isLoading2"
			(change)="enableSharing($event)"></mat-slide-toggle>
	</div>
	<div
		class="flex flex-col gap-y-4"
		*ngIf="protocol.sharing.enabled">
		<div
			class="flex flex-row"
			style="margin-top: 8px; min-height: 80px">
			<p style="margin-right: 16px">
				{{ "protocols.share.intro.label" | translate }}
			</p>
			<mat-radio-group
				[(ngModel)]="protocol.sharing.mode"
				(change)="changeMode($event)"
				class="flex flex-col">
				<mat-radio-button [value]="ESharingMode.DISABLED">{{
					"protocols.share.no-share.label" | translate
				}}</mat-radio-button>
				<mat-radio-button [value]="ESharingMode.ALL">{{
					"protocols.share.all.label" | translate
				}}</mat-radio-button>
				<mat-radio-button [value]="ESharingMode.CUSTOM">{{
					"protocols.share.custom.label" | translate
				}}</mat-radio-button>
			</mat-radio-group>
		</div>
		<div
			class="flex w-full flex-col"
			*ngIf="protocol.sharing.mode === ESharingMode.CUSTOM">
			<mat-form-field
				appearance="outline"
				class="w-full">
				<mat-label>{{ "protocols.share.recipient.placeholder" | translate }}</mat-label>
				<mat-icon
					class="mat-icon-span-prefix"
					matPrefix
					>share</mat-icon
				>
				<input
					matInput
					[(ngModel)]="searchKeyword"
					(keyup.enter)="search(searchKeyword)"
					class="w-full" />
				<mat-icon
					matSuffix
					color="primary"
					(click)="search(searchKeyword)"
					style="cursor: pointer"
					*ngIf="!isLoading"
					>search</mat-icon
				>
				<mat-spinner
					matSuffix
					color="primary"
					diameter="24"
					*ngIf="isLoading"></mat-spinner>
			</mat-form-field>
			<div class="flex w-full flex-row gap-x-4">
				<mat-card
					appearance="outlined"
					class="flex flex-1 flex-col">
					<mat-card-title class="flex flex-row justify-center">
						<div class="flex flex-row justify-center">
							<mat-icon>local_hospital</mat-icon>
						</div>
					</mat-card-title>
					<div
						*ngFor="let item of protocol.sharing.institutes"
						class="flex flex-row items-center gap-x-1">
						<label class="w-full">{{ item.label }}</label>
						<button
							mat-icon-button
							(click)="removeShareInstitute(item)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</mat-card>
				<mat-card
					appearance="outlined"
					class="flex flex-1 flex-col">
					<mat-card-title class="flex flex-row justify-center">
						<div class="flex flex-row justify-center">
							<mat-icon>place</mat-icon>
						</div>
					</mat-card-title>
					<div
						*ngFor="let item of protocol.sharing.zones"
						class="flex flex-row items-center gap-x-1">
						<label class="w-full">{{ item.label }}</label>
						<button
							mat-icon-button
							(click)="removeShareZone(item)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
	<p></p>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
