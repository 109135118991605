<div class="flex flex-col">
	<mat-card
		class="m-8"
		appearance="outlined">
		<mat-card-title
			><h2>{{ "core.search" | translate }}</h2></mat-card-title
		>
		<mat-card-content class="flex flex-col">
			<div class="flex flex-col gap-y-4">
				<div class="flex flex-row justify-end">
					<mat-slide-toggle
						[(ngModel)]="isJanusMode"
						(change)="onJanusToggle($event)"
						>Janus
					</mat-slide-toggle>
				</div>
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>{{ getSearchPlaceholder() }}</mat-label>
					<input
						matInput
						(keyup.enter)="askQuestion(searchKeyword)"
						[(ngModel)]="searchKeyword"
						(ngModelChange)="filterProtocols()"
						spellcheck="false" />
					<mat-icon matIconPrefix>search</mat-icon>
					<mat-icon
						matIconSuffix
						(click)="clearSearchKeyword()"
						*ngIf="searchKeyword.trim() !== '' && !isJanusLoading"
						class="cursor-pointer"
						>cancel
					</mat-icon>
					<mat-hint
						*ngIf="isJanusMode"
						style="color: #2196f4"
						>{{ "janus.legal.notice" | translate }}
					</mat-hint>
					<mat-spinner
						*ngIf="isJanusLoading"
						matIconSuffix
						diameter="30"
						class="m-2"></mat-spinner>
				</mat-form-field>
				<div
					class="flex flex-row justify-between"
					*ngIf="!isJanusMode">
					<app-tag-filter
						placeholder="{{ 'PROTOCOLS_SEARCH_TAG' | translate }}"
						[(tags)]="tags"
						(tagsChange)="filterProtocols()"
						style="margin-right: 8px"
						class="flex-1"></app-tag-filter>
					<app-institute-filter
						placeholder="{{ 'PROTOCOLS_SEARCH_ESTABLISHMENNT' | translate }}"
						[(institutes)]="institutes"
						(institutesChange)="filterProtocols()"
						[setInstitutesAll]="institutes_all"
						[defaultInstitute]="true"
						style="margin-left: 8px"
						class="flex-1"></app-institute-filter>
				</div>
				<div
					*ngIf="isJanusMode"
					class="m-4"
					[innerHTML]="janusAnswer | safeHtml"></div>
			</div>
		</mat-card-content>
	</mat-card>
	<div class="flex w-full flex-col">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort>
			<ng-container matColumnDef="share">
				<th
					mat-header-cell
					*matHeaderCellDef></th>
				<td
					mat-cell
					*matCellDef="let element">
					<mat-icon [matTooltip]="element.institute.label">{{ getShareIcon(element) }}</mat-icon>
				</td>
			</ng-container>
			<ng-container matColumnDef="label">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "PROTOCOLS_PROTOCOL" | translate }}
				</th>
				<td
					mat-cell
					class="cursor-pointer"
					*matCellDef="let element"
					(click)="view(element)">
					<div class="flex flex-col">
						<div class="text-blue-800">{{ element.label }}</div>
						<div class="text-xs italic">{{ element.tags | tagsString }}</div>
					</div>
				</td>
			</ng-container>
			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<mat-paginator
			[pageSizeOptions]="[12, 30, 100]"
			showFirstLastButtons></mat-paginator>
	</div>
</div>
