import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
} from "@angular/material/dialog";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { ToastrService } from "ngx-toastr";
import { Institute } from "../../../../interfaces/institute";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { ProtocolShareSearchResultsComponent } from "../protocol-share-search-results/protocol-share-search-results.component";
import { OrderByPipe, UniquePipe } from "ngx-pipes";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ESharingMode } from "src/app/interfaces/protocol/isharing";
import { MatRadioButton, MatRadioChange, MatRadioGroup } from "@angular/material/radio";
import { IZone } from "../../../../interfaces/protocol/izone";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatCard, MatCardTitle } from "@angular/material/card";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgFor, NgIf } from "@angular/common";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { ProtocolService } from "../../../../services/protocol.service";

@Component({
	selector: "app-protocol-share",
	templateUrl: "./protocol-share.component.html",
	styleUrls: ["./protocol-share.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		NgIf,
		MatProgressSpinner,
		MatSlideToggle,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		MatFormField,
		MatLabel,
		MatIcon,
		MatPrefix,
		MatInput,
		MatSuffix,
		MatCard,
		MatCardTitle,
		NgFor,
		MatIconButton,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class ProtocolShareComponent {
	searchKeyword: string;
	isLoading = false;
	isLoading2 = false;
	@ViewChildren(MatFormField) formFields: QueryList<MatFormField>;
	ESharingMode = ESharingMode;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private protocolService: ProtocolService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private uniquePipe: UniquePipe,
		private orderByPipe: OrderByPipe
	) {}

	removeShareInstitute(institute: Institute) {
		this.protocolService.removeInstituteFromProtocolShare(this.protocol.id, institute.id).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(
					this.translate.instant("protocol.share.institute.remove.success", { institute: institute.label })
				);
				const index = this.protocol.sharing.institutes.map((e) => e.id).indexOf(institute.id);
				if (index > -1) {
					this.protocol.sharing.institutes.splice(index, 1);
				}
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}

	search(keyword: string): void {
		if (keyword.trim() === "") {
			this.toastr.warning(this.translate.instant("protocol.share.search.input.warning"));
			return;
		}
		this.isLoading = true;
		this.protocolService.searchProtocolSharingRecipients(keyword).subscribe({
			next: (e) => {
				if (e.institutes.length == 0 && e.zones.length === 0) {
					this.toastr.info(this.translate.instant("core.message.no-result"));
				} else {
					this.dialog
						.open(ProtocolShareSearchResultsComponent, {
							minWidth: "600px",
							data: { protocol: this.protocol, result: e },
							disableClose: true,
							autoFocus: false,
						})
						.afterClosed()
						.subscribe((result) => {
							this.searchKeyword = "";

							const mergedArraysI = [...this.protocol.sharing.institutes, ...result.institutes];
							this.protocol.sharing.institutes = this.uniquePipe.transform(mergedArraysI, "id");
							this.protocol.sharing.institutes = this.orderByPipe.transform(
								this.protocol.sharing.institutes,
								"label"
							);

							const mergedArraysZ = [...this.protocol.sharing.zones, ...result.zones];
							this.protocol.sharing.zones = this.uniquePipe.transform(mergedArraysZ, "id");
							this.protocol.sharing.zones = this.orderByPipe.transform(
								this.protocol.sharing.zones,
								"label"
							);
						});
				}
				this.isLoading = false;
			},
			error: () => (this.isLoading = false),
		});
	}

	enableSharing($event: MatSlideToggleChange): void {
		this.isLoading = true;
		this.protocolService.enableSharing(this.protocol.id, $event.checked).subscribe({
			next: (e) => {
				if (e.status === 204) {
					this.protocol.sharing.enabled = $event.checked;
					this.toastr.success("");
				} else {
					this.toastr.error("");
				}
				this.isLoading = false;
			},
			error: () => (this.isLoading = false),
		});
	}

	changeMode($event: MatRadioChange): void {
		this.protocolService.editSharingMode(this.protocol.id, $event.value).subscribe((e) => {
			if (e.status === 204) {
				this.toastr.success(this.translate.instant("protocol.share.settings.update.success"));
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}

	removeShareZone(zone: IZone) {
		this.protocolService.removeShareProtocolWithZone(this.protocol.id, zone.id).subscribe((e) => {
			if (e.status === 204) {
				const index = this.protocol.sharing.zones.map((e) => e.id).indexOf(zone.id);
				if (index > -1) {
					this.protocol.sharing.zones.splice(index, 1);
				}
				this.toastr.success(this.translate.instant("protocol.share.settings.update.success"));
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}
}
