<div
	mat-dialog-content
	class="flex flex-col">
	<p>
		{{ "PROTOCOL-INTERACTIVE-OPTIONS_INTEGRATION_DOCUMENTS_PROTOCOL" | translate }}
		<br />{{ "PROTOCOL-INTERACTIVE-OPTIONS_PLEASE" | translate }}
	</p>
	<ul class="list-inside list-disc">
		<li>
			{{ "PROTOCOL-INTERACTIVE-OPTIONS_NAME_BUTTON_INTEGRATION" | translate }}
		</li>
		<li>{{ "PROTOCOL-INTERACTIVE-OPTIONS_DOCUMENT" | translate }}</li>
	</ul>
	<p></p>
	<mat-form-field
		appearance="outline"
		class="w-full">
		<mat-label>{{ "PROTOCOL-INTERACTIVE-OPTIONS_TITLE_BUTTON" | translate }}</mat-label>
		<input
			matInput
			[(ngModel)]="label" />
		<mat-icon matSuffix>label</mat-icon>
	</mat-form-field>
	<file-pond
		#myPond
		[options]="pondOptions"></file-pond>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		*ngIf="!isLoading"
		(click)="add()"
		[disabled]="isDisabled()">
		{{ "core.create" | translate }}
	</button>
</div>
