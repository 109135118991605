import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UpToDateAutocompleteResponse } from "../entities/uptodate/UpToDateAutocompleteResponse";
import { Observable } from "rxjs";
import { UpToDateSearchResponse } from "../entities/uptodate/UpToDateSearchResponse";
import { MITPService } from "./mitp.service";

@Injectable({
	providedIn: "root",
})
export class UptodateService {
	constructor(
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	autocomplete(keyword: string): Observable<UpToDateAutocompleteResponse> {
		return this.httpClient.get<UpToDateAutocompleteResponse>(
			this.mitp.Guidelines() + `/uptodate/autocomplete?keyword=${keyword}`
		);
	}

	search(keyword: string): Observable<UpToDateSearchResponse> {
		return this.httpClient.get<UpToDateSearchResponse>(
			this.mitp.Guidelines() + `/uptodate/search?keyword=${keyword}`
		);
	}
}
