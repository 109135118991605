import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { saveAs } from "file-saver";
import DomToImage from "dom-to-image";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton, MatButton } from "@angular/material/button";
import { DiagramService } from "../../../services/diagram.service";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-diagram",
	templateUrl: "./protocol-diagram.component.html",
	styleUrls: ["./protocol-diagram.component.scss"],
	standalone: true,
	imports: [
		MatIconButton,
		MatIcon,
		MatDialogContent,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		MatButton,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		MatTooltip,
		MatFormField,
		MatLabel,
		MatInput,
		CdkTextareaAutosize,
		MatDialogActions,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class ProtocolDiagramComponent {
	@ViewChild("mermaid", { static: true }) mermaid: ElementRef;
	markdown: string = "";
	type: string = "TD";
	errorMarkdown: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { markdown: string; type: string },
		private dialogRef: MatDialogRef<ProtocolDiagramComponent>,
		public diagramService: DiagramService
	) {
		if (data.markdown) {
			this.markdown = data.markdown;
			this.type = data.type;
			setTimeout(() => {
				this.updateDiagram();
			}, 150);
		}
	}

	async updateDiagram(): Promise<void> {
		const svg = await this.diagramService.generateSvg(this.markdown, this.type);
		if (svg) {
			this.mermaid.nativeElement.innerHTML = svg;
			this.errorMarkdown = false;
		} else {
			this.errorMarkdown = true;
		}
	}

	async create(): Promise<void> {
		const doc = await this.diagramService.createHtmlElement(this.markdown, this.type);
		this.dialogRef.close(doc);
	}

	help(): void {
		window.open("https://help.medinthepocket.com/index.php/knowledge-base/ajouter-un-diagramme/", "_blank");
	}

	downloadImage() {
		DomToImage.toBlob(this.mermaid.nativeElement).then((blob) => {
			saveAs(blob, "diagram.png");
		});
	}
}
