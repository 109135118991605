<div class="flex flex-col">
	<p *ngIf="activeSubscription === null">Vous n'avez pas d'abonnement actif.</p>
	<div *ngIf="activeSubscription !== null">
		<p>Abonnement actif :</p>
		<ul class="list-inside list-disc">
			<li>{{ activeSubscription.package.label }}</li>
			<li>Durée : {{ activeSubscription.duration }} mois</li>
			<li>Fin : {{ activeSubscription.deadline | date: "d MMMM y" }}</li>
		</ul>
	</div>
	<p></p>
	<button
		mat-raised-button
		color="primary"
		(click)="subscribe()"
		*ngIf="activeSubscription === null"
		class="w-full">
		M'abonner
	</button>
	<button
		mat-button
		color="primary"
		(click)="openPortal()"
		class="w-full">
		Portail de paiement
	</button>
</div>
