import { Component, Inject, ViewChild } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
} from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { FilePondModule } from "ngx-filepond";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { FilePond, FilePondOptions } from "filepond";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { ProtocolAttachment } from "../../../../entities/protocol-attachment";
import { ToastrService } from "ngx-toastr";
import { ProtocolService } from "../../../../services/protocol.service";
import { finalize, first } from "rxjs";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-replace-attachment",
	standalone: true,
	imports: [
		MatDialogContent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		FilePondModule,
		MatProgressSpinner,
		NgIf,
		NgxTolgeeModule,
	],
	templateUrl: "./replace-attachment.component.html",
	styleUrl: "./replace-attachment.component.scss",
})
export class ReplaceAttachmentComponent {
	isLoading = false;
	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.embed.document.placeholder"),
		acceptedFileTypes: [
			"image/png",
			"image/jpeg",
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
			"application/zip",
		],
		maxFileSize: "2MB",
		labelMaxFileSizeExceeded: this.translate.instant("protocol.tools.embed.document.error.size-exceeded"),
		labelMaxFileSize: this.translate.instant("protocol.tools.embed.document.size-max") + " {filesize}",
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.embed.document.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.embed.document.authorized-document") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: null,
		credits: false,
	};
	@ViewChild("myPond") myPond!: FilePond;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: { protocol: Protocol; attachment: ProtocolAttachment },
		private protocolService: ProtocolService,
		private translate: TranslateService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<ReplaceAttachmentComponent>
	) {}

	upload(): void {
		const file = this.myPond?.getFile()?.file;
		if (file == null) {
			this.toastr.error(this.translate.instant("core.message.error.select-file"));
			return;
		}
		this.isLoading = true;
		const formData = new FormData();
		formData.append("file", file);
		formData.append("extension", this.myPond?.getFile()?.fileExtension);
		this.protocolService
			.replaceAttachment(this.data.protocol.id, this.data.attachment.id, formData)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe({
				next: (response) => {
					if (response.status === 201) {
						this.toastr.success(this.translate.instant("protocol.attachments.replace.success"));
						this.dialogRef.close();
					} else {
						this.toastr.error(this.translate.instant("protocol.attachments.replace.failed"));
					}
				},
			});
	}

	isDisabled(): boolean {
		return this.myPond?.getFile()?.file == null;
	}
}
