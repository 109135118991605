<div class="flex flex-col gap-y-2">
	<mat-card
		appearance="outlined"
		*ngFor="let item of data.result['institutes']">
		<mat-card-content class="flex flex-row">
			<div class="flex flex-row items-center gap-x-4">
				<mat-icon>local_hospital</mat-icon>
				<div class="w-full">{{ item.label }}</div>
				<button
					*ngIf="item['added'] !== true"
					mat-icon-button
					(click)="shareWithInstitute(item)">
					<mat-icon>add</mat-icon>
				</button>
				<mat-icon
					*ngIf="item['added'] === true"
					style="color: green"
					>done</mat-icon
				>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		appearance="outlined"
		*ngFor="let item of data.result['zones']">
		<mat-card-content class="flex flex-row">
			<div class="flex flex-row items-center gap-x-4">
				<mat-icon>place</mat-icon>
				<div class="w-full">{{ item.label }}</div>
				<button
					*ngIf="item['added'] !== true"
					mat-icon-button
					(click)="shareWithZone(item)">
					<mat-icon>add</mat-icon>
				</button>
				<mat-icon
					*ngIf="item['added'] === true"
					style="color: green"
					>done</mat-icon
				>
			</div>
		</mat-card-content>
	</mat-card>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<mat-spinner
		color="primary"
		diameter="40"
		*ngIf="isLoading"></mat-spinner>
	<button
		mat-button
		(click)="close()"
		*ngIf="!isLoading">
		{{ "core.close" | translate }}
	</button>
</div>
