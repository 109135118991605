<div
	mat-dialog-content
	class="flex flex-col">
	<mat-tab-group
		mat-align-tabs="center"
		(selectedTabChange)="loadErrors($event)"
		backgroundColor="primary"
		class="w-full">
		<mat-tab
			label="{{ 'PROTOCOL-ERRORS_ERRORS_REPORTED' | translate }}"
			class="w-full">
			<ng-template mat-tab-label>
				{{ "PROTOCOL-ERRORS_ERRORS" | translate }}
				<mat-icon matSuffix>error</mat-icon>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				{{ "PROTOCOL-ERRORS_HISTORY" | translate }}
				<mat-icon matSuffix>history</mat-icon>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
	<div class="flex flex-col">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort
			class="w-full">
			<ng-container matColumnDef="error">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "PROTOCOL-ERRORS_ERROR" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.error }}
				</td>
			</ng-container>
			<ng-container matColumnDef="notes">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "PROTOCOL-ERRORS_NOTES" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.notes }}
				</td>
			</ng-container>
			<ng-container matColumnDef="byUser">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "PROTOCOL-ERRORS_REPORTED_BY" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.user.firstname }} {{ element.user.lastname }}
				</td>
			</ng-container>
			<ng-container matColumnDef="date">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "PROTOCOL-ERRORS_DATES" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.created | date: "shortDate" }}
				</td>
			</ng-container>
			<ng-container matColumnDef="actions">
				<th
					mat-header-cell
					*matHeaderCellDef></th>
				<td
					mat-cell
					*matCellDef="let element">
					<button
						mat-button
						(click)="processError(element)"
						*ngIf="lastIndex === 0">
						<mat-icon>check</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<mat-paginator
			[pageSizeOptions]="[12, 30, 100]"
			showFirstLastButtons></mat-paginator>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
