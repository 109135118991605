<div mat-dialog-title>Médicaments à migrer</div>
<div mat-dialog-content>
	<div
		class="flex flex-col gap-y-4"
		*ngIf="data.equivalents.length > 0; else isEmpty">
		@for (equivalent of data.equivalents; track equivalent.id) {
			<mat-checkbox
				(change)="selectEquivalent($event, equivalent)"
				*ngIf="equivalent.new">
				<div class="flex flex-col">
					<div class="whitespace-nowrap overflow-ellipsis overflow-hidden w-[450px]">
						Nouveau médicament : <span class="text-blue-500">{{ equivalent.new.label }}</span>
					</div>
					<div class="italic text-xs whitespace-nowrap overflow-ellipsis overflow-hidden w-[450px]">
						Ancien médicament : {{ equivalent.old.label }}
					</div>
				</div>
			</mat-checkbox>
		}
	</div>
	<ng-template #isEmpty>
		<div class="flex flex-col items-center justify-center">
			<img
				src="../../../../assets/ng_robot_yoga.png"
				alt="not_found"
				class="w-[240px]" />
			<div class="m-4">Pas de médicaments à migrer.</div>
		</div>
	</ng-template>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<button
		mat-raised-button
		[disabled]="selectedEquivalents.length === 0"
		color="primary"
		(click)="replace()">
		{{ "core.apply" | translate }}
	</button>
</div>
