<div mat-dialog-content>
	<p
		class="text-red-600 italic"
		[hidden]="isOfflineAvailable()">
		{{ "PROTOCOL-TAGS_INDICATION_PROTOCOL_POSSESSION_TAG_ACCESSIBILITY_OFFLINE" | translate }}<br />
		{{ "PROTOCOL-TAGS_INDICATION_TAG_SPECIALITY" | translate }}
	</p>
	<app-tag-filter
		placeholder="{{ 'tags.title.label' | translate }}"
		enabledTagCreation="true"
		[(tags)]="protocol.tags"
		[protocolId]="protocol.id"></app-tag-filter>
	<div *ngIf="items.length > 0">
		<hr />
		<mat-accordion>
			<mat-expansion-panel hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-title> {{ "institute.navigation-specialties.title" | translate }} </mat-panel-title>
				</mat-expansion-panel-header>
				<div>
					<div class="relative overflow-x-auto">
						<table class="w-full text-left rtl:text-right">
							<thead class="bold text-blue-400 uppercase">
								<tr>
									<th
										scope="col"
										class="px-6 py-3">
										{{ "core.section" | translate }}
									</th>
									<th
										scope="col"
										class="px-6 py-3">
										{{ "tags.title.label" | translate }}
									</th>
									<th
										scope="col"
										class="px-6 py-3">
										{{ "core.mode" | translate }}
									</th>
								</tr>
							</thead>
							<tbody>
								@for (item of items; track item.id) {
									<tr class="border-b">
										<th
											scope="row"
											class="px-6 py-4">
											{{ item.label }}
										</th>
										<td class="px-6 py-4">{{ item.tags | tagsString }}</td>
										<td class="px-6 py-4">{{ getMode(item.tags_selection_mode) }}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
