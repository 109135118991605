<div mat-dialog-content>
	<mat-form-field
		appearance="outline"
		class="w-full">
		<mat-label>{{ "PROTOCOL-LABEL_TITLE_PROTOCOL" | translate }}</mat-label>
		<input
			matInput
			[(ngModel)]="protocol.label"
			maxlength="255"
			minlength="4"
			[disabled]="!isProtocolManager()" />
		<mat-hint align="end">{{ protocol.label.length }} / 255</mat-hint>
		<mat-icon matIconPrefix>label</mat-icon>
	</mat-form-field>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()"
		*ngIf="!isLoading && isProtocolManager()"
		[disabled]="protocol.label.length < 4 || protocol.label.length > 255">
		{{ "core.edit" | translate }}
	</button>
</div>
