<div mat-dialog-title>Recommandations</div>
<div
	mat-dialog-content
	class="flex flex-col"
	style="padding: 32px">
	<mat-accordion class="w-full">
		<mat-expansion-panel
			*ngFor="let item of guidelines"
			(afterExpand)="checkSubscription(item)">
			<mat-expansion-panel-header>
				<mat-panel-title class="flex flex-row items-center justify-start gap-x-4">
					<mat-icon>feed</mat-icon>
					<label>{{ item.title }}</label>
				</mat-panel-title>
				<mat-panel-description class="flex flex-1 flex-row justify-end">{{
					item.provider
				}}</mat-panel-description>
			</mat-expansion-panel-header>
			<div class="flex flex-row gap-x-16">
				<div class="flex-1">
					<ul class="list-inside list-disc">
						<li>
							{{ "guidelines.guidelines.language.label" | translate }}
							: <span class="fi fi-{{ item.lang }}"></span>
						</li>
						<li>
							{{ "guidelines.guidelines.last-update.label" | translate }}
							: {{ item.last_update | date: "d/MM/yyyy" }}
						</li>
						<li>
							{{ "guidelines.guidelines.description.label" | translate }}
							: {{ item.description }}
						</li>
						<li *ngIf="item.is_subscribed !== null">
							{{ "guidelines.guidelines.status.label" | translate }}
							:
							{{ item.is_subscribed ? "Inscrit" : "Non inscrit" }}
						</li>
					</ul>
				</div>
				<img
					alt="logo"
					[src]="item.icon"
					class="provider-logo" />
			</div>
			<p
				class="flex flex-row justify-end gap-x-4"
				*ngIf="item.is_subscribed !== null">
				<button
					mat-icon-button
					matTooltip="S'abonner"
					(click)="subscribe(item)">
					<mat-icon [style.color]="item.is_subscribed ? 'black' : 'green'">person_add</mat-icon>
				</button>
				<button
					mat-icon-button
					matTooltip="Se désabonner"
					(click)="unsubscribe(item)">
					<mat-icon [style.color]="item.is_subscribed ? 'red' : 'black'">person_off</mat-icon>
				</button>
			</p>
		</mat-expansion-panel>
	</mat-accordion>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
