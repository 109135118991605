import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { ProtocolError } from "../../../interfaces/protocols/protocol-error";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MatTab, MatTabChangeEvent, MatTabGroup, MatTabLabel } from "@angular/material/tabs";
import { ModalContent } from "../../../interfaces/modal-content";
import { DeleteModalComponent } from "../../modals/delete-modal/delete-modal.component";
import { MatButton } from "@angular/material/button";
import { DatePipe, NgIf } from "@angular/common";
import { MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { ProtocolService } from "../../../services/protocol.service";

@Component({
	selector: "app-protocol-errors",
	templateUrl: "./protocol-errors.component.html",
	styleUrls: ["./protocol-errors.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatTabGroup,
		MatTab,
		MatTabLabel,
		MatIcon,
		MatSuffix,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatSortHeader,
		NgIf,
		MatButton,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		MatDialogActions,
		MatDialogClose,
		DatePipe,
		NgxTolgeeModule,
	],
})
export class ProtocolErrorsComponent implements OnInit {
	displayedColumns: string[] = ["error", "notes", "byUser", "date", "actions"];
	dataSource = new MatTableDataSource<ProtocolError>();
	lastIndex: number = 0;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private protocolService: ProtocolService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.loadErrorsByIndex();
	}

	loadErrors($event: MatTabChangeEvent) {
		this.lastIndex = $event.index;
		this.loadErrorsByIndex();
	}

	processError(element: ProtocolError) {
		const dialogContent: ModalContent = {
			message: this.translate.instant("protocol.error.archive.confirm"),
			positiveButton: this.translate.instant("core.archive"),
			title: this.translate.instant("core.title"),
		};
		this.dialog
			.open(DeleteModalComponent, { data: dialogContent })
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.protocolService.updateProtocolError(this.protocol.id, element.id).subscribe((response) => {
						if (response.status == 204) {
							element.status = 1;
							this.loadErrorsByIndex();
							this.toastr.success(this.translate.instant("protocol.error.archive.success"));
						} else {
							this.toastr.error(this.translate.instant("core.message.error.unknown"));
						}
					});
				} else {
					this.toastr.info(this.translate.instant("protocol.error.archive.cancel"));
				}
			});
	}

	private loadErrorsByIndex() {
		this.dataSource.data = this.protocol.errors.filter((e) => e.status == this.lastIndex);

		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
