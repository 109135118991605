<div
	class="cursor-pointer block p-4 mb-4 rounded-xl hover:bg-gray-600 shadow-md transition duration-200"
	(click)="navigateTo()"
	[ngClass]="{ 'bg-gray-600': themeService.isDarkTheme(), 'bg-white': !themeService.isDarkTheme() }">
	<div class="font-semibold text-lg leading-6 mb-1">{{ item?.label }}</div>
	<div class="flex items-center mb-2">
		<div
			class="h-2 w-2 mr-1 rounded-full"
			[ngClass]="{
				'bg-green-500': !isError(),
				'bg-red-500': isError(),
				'animate-ping': isError(),
			}"></div>
		<div
			class="text-xs font-medium"
			[ngClass]="{ 'text-green-500': !isError(), 'text-red-500': isError() }">
			{{ getErrorCount() }}
			{{ (getErrorCount() > 1 ? "protocol.errors.label" : "protocol.error.label") | translate }}
		</div>
	</div>
	<p class="text-xs leading-normal mb-10 italic">{{ item.tags | tagsString }}</p>
	<div class="border-t border-gray-500">
		<div class="flex flex-wrap items-center justify-between -m-2">
			<div class="w-auto p-2">
				<div
					class="flex items-center p-2 rounded-md"
					[ngClass]="{
						'bg-gray-500': themeService.isDarkTheme(),
						'bg-gray-200': !themeService.isDarkTheme(),
					}">
					<mat-icon>event</mat-icon>
					<span class="ml-2 text-xs font-medium">
						{{ (getLastUpdate() | date: "MMM yy") ?? "-" }}
					</span>
				</div>
			</div>
			<div class="w-auto p-2">
				<div class="flex h-full items-center">
					{{ getVersion() }}
				</div>
			</div>
		</div>
	</div>
</div>
