import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { distinctUntilChanged, finalize, first, switchMap, tap } from "rxjs";
import { UptodateService } from "../../../services/uptodate.service";
import { debounceTime, filter } from "rxjs/operators";
import { UpToDateSearchResult } from "../../../entities/uptodate/UpToDateSearchResponse";
import { MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MatTooltip } from "@angular/material/tooltip";
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
	MatExpansionPanelActionRow,
} from "@angular/material/expansion";
import { MatOption } from "@angular/material/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf, NgFor } from "@angular/common";
import { MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-uptodate",
	templateUrl: "./uptodate.component.html",
	styleUrls: ["./uptodate.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatIconButton,
		MatDialogClose,
		MatIcon,
		MatDialogContent,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatAutocompleteTrigger,
		MatPrefix,
		NgIf,
		MatProgressSpinner,
		MatSuffix,
		MatAutocomplete,
		NgFor,
		MatOption,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatExpansionPanelActionRow,
		MatTooltip,
		NgxTolgeeModule,
	],
})
export class UptodateComponent {
	utdSearchFormControl = new FormControl();
	utdSearchGroup: FormGroup;
	suggestions: string[] = [];
	searchResults: UpToDateSearchResult[] = [];
	isLoading = false;

	constructor(
		private uptodate: UptodateService,
		formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<UptodateComponent>,
		private toastr: ToastrService,
		private translate: TranslateService
	) {
		this.utdSearchGroup = formBuilder.group({
			searchKeyword: ["", []],
		});
		this.utdSearchFormControl.valueChanges
			.pipe(
				filter((value) => value != null && value !== ""),
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.isLoading = true)),
				switchMap((value) => this.uptodate.autocomplete(value).pipe(finalize(() => (this.isLoading = false))))
			)
			.subscribe({
				next: (response) => {
					this.suggestions = response.data.searchTerms;
				},
				error: () => {
					this.suggestions = [];
					this.toastr.error(
						this.translate.instant("protocols.protocol.edit.toolbar.sources.uptodate.error.autocomplete")
					);
				},
			});
	}

	searchUptodate(value: string): void {
		this.uptodate
			.search(value)
			.pipe(first())
			.subscribe({
				next: (response) => {
					this.searchResults = response.data.searchResults;
				},
				error: () => {
					this.toastr.error(
						this.translate.instant("protocols.protocol.edit.toolbar.sources.uptodate.error.autocomplete")
					);
				},
			});
	}

	openArticle(result: UpToDateSearchResult): void {
		window.open(`https://www.uptodate.com${result.url}`, "_blank");
	}

	addReference(result: UpToDateSearchResult): void {
		this.dialogRef.close(result);
	}
}
