<div mat-dialog-content>
	<div class="flex flex-col gap-y-2">
		<mat-radio-group
			class="flex flex-col"
			[(ngModel)]="outputType">
			<mat-radio-button value="text">
				{{ "protocol.tools.import.pdf.output.text" | translate }}
			</mat-radio-button>
			<mat-radio-button value="image">
				{{ "protocol.tools.import.pdf.output.image" | translate }}
			</mat-radio-button>
		</mat-radio-group>
		<file-pond
			#myPond
			[options]="pondOptions"></file-pond>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		diameter="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		*ngIf="!isLoading"
		(click)="import()"
		[disabled]="isDisabled()">
		{{ "core.import" | translate }}
	</button>
</div>
