import { Component, Inject } from "@angular/core";
import { finalize, first } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DrugsService } from "../../../services/drugs.service";
import { Drug } from "../../../entities/drug";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogTitle,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { limit } from "@angular/fire/firestore";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatPrefix } from "@angular/material/form-field";
import { NgIf } from "@angular/common";
import { DrugSearchComponent } from "../../pharmacy/drug-search/drug-search.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-drugs",
	templateUrl: "./protocol-drugs.component.html",
	styleUrls: ["./protocol-drugs.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatCheckbox,
		ReactiveFormsModule,
		FormsModule,
		DrugSearchComponent,
		NgIf,
		MatFormField,
		MatInput,
		MatIcon,
		MatPrefix,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class ProtocolDrugsComponent {
	isLoading = false;
	selectedDrug: Drug = null;
	showText = "";
	limitToBooklet = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: { reference: string; text: string },
		private toastr: ToastrService,
		private translate: TranslateService,
		private drugsService: DrugsService,
		private dialogRef: MatDialogRef<ProtocolDrugsComponent>
	) {
		if (data != null) {
			this.isLoading = true;
			this.showText = data.text;
			this.drugsService
				.getDrugByUcd(data.reference)
				.pipe(
					first(),
					finalize(() => (this.isLoading = false))
				)
				.subscribe({
					next: (drug) => {
						this.selectedDrug = drug;
					},
					error: () => {
						this.toastr.error(this.translate.instant("drugs.drug.search.error"));
					},
				});
		}
	}

	updateDrug(drug: Drug): void {
		this.selectedDrug = drug;
		this.showText = drug.label;
	}

	insert(): void {
		this.dialogRef.close({ ...this.selectedDrug, text: this.showText });
	}

	protected readonly limit = limit;
}
