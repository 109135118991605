import { Component, OnInit, ViewChild } from "@angular/core";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { Protocol, ProtocolStatus } from "../../../interfaces/protocols/protocol";
import { Tag } from "../../../interfaces/tag";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { OrderByPipe, PercentagePipe, UniquePipe } from "ngx-pipes";
import * as fs from "file-saver";
import { MatDialog } from "@angular/material/dialog";
import { CreateProtocolComponent } from "../create-protocol/create-protocol.component";
import { ShareMyProtocolsComponent } from "../share/share-my-protocols/share-my-protocols.component";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../services/user.service";
import { GuidelinesComponent } from "../../knowledge/guidelines/guidelines.component";
import * as fuzz from "fuzzball";
import { Router, RouterLink } from "@angular/router";
import { InfoModalConfig } from "../../../entities/core/info-modal-config";
import { ProtocolViewerService } from "../../../services/protocol-viewer.service";
import { MatBadge } from "@angular/material/badge";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatTooltip } from "@angular/material/tooltip";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatCheckbox } from "@angular/material/checkbox";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatButton } from "@angular/material/button";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { MatIcon } from "@angular/material/icon";
import { NgFor, NgIf } from "@angular/common";
import { ProtocolService } from "../../../services/protocol.service";
import { ProtocolsKanbanComponent } from "../protocols-kanban/protocols-kanban.component";
import { EAuthorProtocolsView } from "../../../enums/protocols/eauthor-protocols-view";
import { LoadingModalComponent } from "../../modals/loading-modal/loading-modal.component";
import { first } from "rxjs";
import { NavigationSpecialtiesModalComponent } from "../../institutes/navigation-specialties/navigation-specialties-modal/navigation-specialties-modal.component";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { TagsStringPipe } from "../../../pipes/tags-string.pipe";

declare const ExcelJS: any;

@Component({
	selector: "app-my-protocols",
	templateUrl: "./my-protocols.component.html",
	styleUrls: ["./my-protocols.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		MatIcon,
		MatCard,
		MatCardTitle,
		MatCardContent,
		MatButton,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatPrefix,
		MatSuffix,
		TagFilterComponent,
		MatCheckbox,
		MatRadioGroup,
		MatRadioButton,
		NgFor,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatTooltip,
		MatSortHeader,
		MatMenuTrigger,
		MatBadge,
		MatMenu,
		MatMenuItem,
		RouterLink,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		ProtocolsKanbanComponent,
		LoadingModalComponent,
		NgxTolgeeModule,
		TagsStringPipe,
	],
})
export class MyProtocolsComponent implements OnInit {
	isArchives = false;
	filterValues = {
		writer: true,
		approver: true,
		manager: true,
		status: -1,
		update: -1,
	};
	displayedColumns: string[] = ["share", "status", "label", "institute"];
	dataSource = new MatTableDataSource<Protocol>();
	protocols_all: Protocol[] = [];
	protocols: Protocol[] = [];
	pendingRequestsProtocols: Protocol[] = [];
	searchKeyword: string = "";
	tags: Tag[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild("auto") matAutocomplete: MatAutocomplete;
	statistics: any = [];
	ProtocolStatus = ProtocolStatus;
	currentView: EAuthorProtocolsView = EAuthorProtocolsView.PENDING;

	constructor(
		public manager: MitpManagerService,
		private userService: UserService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private uPipe: UniquePipe,
		private oPipe: OrderByPipe,
		private pPipe: PercentagePipe,
		private router: Router,
		private protocolViewer: ProtocolViewerService,
		private protocolService: ProtocolService
	) {
		this.currentView = userService.getAuthorView();
	}

	ngOnInit(): void {
		this.manager.showLoading(true);
		this.protocolService.getMyProtocols().subscribe((protocols) => {
			this.manager.showLoading(false);
			this.protocols_all = protocols;
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.filterProtocols();
			this.calculateStatistics();
		});

		this.protocolService
			.getPendingApprovalRequets()
			.pipe(first())
			.subscribe((requests) => {
				this.pendingRequestsProtocols = requests;
			});
	}

	calculateStatistics() {
		const protocolArr = this.uPipe.transform(this.protocols_all, "id");
		this.statistics.push({
			key: this.translate.instant("protocol.statistics.dashboard.protocol.number"),
			value: protocolArr.length,
		});
		if (protocolArr.length == 0) {
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.up-to-date"),
				value: `-`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.archived"),
				value: `-`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.manager"),
				value: `0`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.writer"),
				value: `0`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.approver"),
				value: `0`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.errors.reported"),
				value: `0`,
			});
		} else {
			let expiredProtocols = 0;
			let onlineProtocols = 0;
			let archivedProtocols = 0;
			this.protocols_all.forEach((protocol) => {
				if (protocol.status == 1) {
					onlineProtocols++;
					if (this.isExpired(protocol)) {
						expiredProtocols++;
					}
				} else if (protocol.status == 2) {
					archivedProtocols++;
				}
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.up-to-date"),
				value: `${100 - this.pPipe.transform(expiredProtocols, onlineProtocols, true)}%`,
			});
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.archived"),
				value: `${this.pPipe.transform(archivedProtocols, protocolArr.length, true)}%`,
			});

			let count = protocolArr.filter(
				function (protocol) {
					return protocol.managers.map((e) => e.id).includes(this.userService.getUserId());
				}.bind(this)
			).length;
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.manager"),
				value: `${count}`,
			});

			count = protocolArr.filter(
				function (protocol) {
					return protocol.approvers.map((e) => e.id).includes(this.userService.getUserId());
				}.bind(this)
			).length;
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.approver"),
				value: `${count}`,
			});

			count = protocolArr.filter(
				function (protocol) {
					return protocol.authors.map((e) => e.id).includes(this.userService.getUserId());
				}.bind(this)
			).length;
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.authors.writer"),
				value: `${count}`,
			});

			count = protocolArr
				.filter((e) => e.errors.length > 0)
				.map((e) => e.errors)
				.reduce((prev, next) => prev.concat(next), [])
				.filter((e) => e.status == 0).length;
			this.statistics.push({
				key: this.translate.instant("protocol.statistics.dashboard.protocol.errors.reported"),
				value: `${count}`,
			});
		}
	}

	filterProtocols() {
		let filterProtocols = this.isArchives
			? this.protocols_all.filter((e) => e.status == 2)
			: this.protocols_all.filter((e) => e.status != 2);
		let tmp = [];
		if (this.tags.length > 0) {
			filterProtocols.forEach((protocol) => {
				if (
					this.tags.every((tag) => {
						return protocol.tags.map((e) => e.id).includes(tag.id);
					})
				) {
					tmp.push(protocol);
				}
			});
			filterProtocols = tmp;
			tmp = [];
		}
		if (this.searchKeyword != "") {
			tmp = filterProtocols.filter((protocol) => {
				return fuzz.partial_ratio(protocol.label.toLowerCase(), this.searchKeyword.trim().toLowerCase()) > 60;
			});
			filterProtocols = tmp;
			tmp = [];
		}
		if (this.filterValues.writer) {
			tmp = tmp.concat(
				filterProtocols.filter(
					function (protocol) {
						return protocol.authors.map((e) => e.id).includes(this.userService.getUserId());
					}.bind(this)
				)
			);
		}
		if (this.filterValues.approver) {
			tmp = tmp.concat(
				filterProtocols.filter(
					function (protocol) {
						return protocol.approvers.map((e) => e.id).includes(this.userService.getUserId());
					}.bind(this)
				)
			);
		}
		if (this.filterValues.manager) {
			tmp = tmp.concat(
				filterProtocols.filter(
					function (protocol) {
						return protocol.managers.map((e) => e.id).includes(this.userService.getUserId());
					}.bind(this)
				)
			);
		}
		if (this.filterValues.status > -1) {
			tmp = tmp.filter((e) => e.status == this.filterValues.status);
		}
		if (this.filterValues.update > -1) {
			tmp = tmp.filter(
				function (protocol) {
					if (this.filterValues.update == 1) {
						//protocoles à jour
						return !this.isExpired(protocol);
					} else {
						return this.isExpired(protocol);
					}
				}.bind(this)
			);
		}
		tmp = this.uPipe.transform(tmp, "id");
		tmp = this.oPipe.transform(tmp, "label");
		this.dataSource.data = tmp;
		this.protocols = tmp;
	}

	isExpired(protocol: Protocol): boolean {
		if (protocol.updates.length > 0) {
			const lastUpdate = protocol.updates[protocol.updates.length - 1];
			let diff = new Date().getTime() - new Date(lastUpdate.updated).getTime();
			diff /= 1000 * 60 * 60 * 24 * 365;
			if (diff > 3) {
				return true;
			}
		}
		return false;
	}

	createProtocol() {
		this.dialog
			.open(CreateProtocolComponent, { disableClose: true })
			.afterClosed()
			.subscribe((protocol) => {
				if (protocol) {
					this.protocols_all.push(protocol);
					this.filterProtocols();
					const config: InfoModalConfig = {
						positiveButton: "core.open",
					};
					this.manager
						.openInfoDialog("Protocole créé avec succès. Souhaitez-vous l'ouvrir en mode édition ?", config)
						.afterClosed()
						.subscribe((open) => {
							if (open) {
								this.router.navigate(["/protocols", protocol.id, "edit"]);
							}
						});
				} else {
					this.toastr.info(this.translate.instant("protocol.create.canceled"));
				}
			});
	}

	getStatusIcon(element: Protocol): string {
		switch (element.status) {
			case 1:
				return "assets/circle-green.png";
			case 0:
				return "assets/circle-red.png";
			case 2:
				return "assets/circle-blue.png";
			default:
				return "";
		}
	}

	view(element: Protocol, published: boolean): void {
		if (published) {
			this.protocolService.getPublishedProtocol(element.id).subscribe((data) => {
				this.protocolViewer.viewProtocol(element, data);
			});
		} else {
			this.protocolService.getDraftProtocol(element.id).subscribe((data) => {
				this.protocolViewer.viewProtocol(element, data);
			});
		}
	}

	loadArchives(b: boolean) {
		this.isArchives = b;
		this.filterProtocols();
	}

	isSameInstitute(element: Protocol) {
		return element.institute.id == this.userService.getInstituteId();
	}

	notebooks() {
		this.manager.openInfoDialog(this.translate.instant("core.coming-soon"));
	}

	exportExcel() {
		const workbook = new ExcelJS.Workbook();
		workbook.created = new Date();
		workbook.modified = new Date();
		const worksheet = workbook.addWorksheet("Protocoles");
		worksheet.properties.defaultColWidth = 30;
		const header = [
			"Service",
			"Titre",
			"Date de première publication",
			"Dernière mise à jour",
			"Gestionnaires",
			"Rédacteurs",
			"Approbateurs",
			"Mots-Clés",
		];
		const headerRow = worksheet.addRow(header);
		headerRow.fill = {
			type: "pattern",
			pattern: "solid",
			fgColor: { argb: "3498DB" },
		};
		headerRow.font = {
			bold: true,
			color: { argb: "FFFFFF" },
		};
		headerRow.alignment = { horizontal: "center" };
		this.protocols_all.forEach(
			function (protocol) {
				const data = [
					"",
					protocol.label,
					protocol.updates.length === 0
						? ""
						: this.dPipe.transform(protocol.updates[0].updated, "longDate", "fr"),
					protocol.updates.length === 0
						? ""
						: this.dPipe.transform(protocol.updates[protocol.updates.length - 1].updated, "longDate", "fr"),
					protocol.managers.map((e) => `${e.firstname} ${e.lastname}`).join(", "),
					protocol.authors.map((e) => `${e.firstname} ${e.lastname}`).join(", "),
					protocol.approvers.map((e) => `${e.firstname} ${e.lastname}`).join(", "),
					protocol.tags.map((e) => e.label).join(", "),
				];
				const row = worksheet.addRow(data);
				row.alignment = { wrapText: true };

				const lastUpdate = row.getCell(4);
				const lastUpdateDate = new Date(lastUpdate.value);
				if (!isNaN(lastUpdateDate.getTime())) {
					const diff = (new Date().getTime() - lastUpdateDate.getTime()) / (60 * 60 * 24 * 365);
					if (diff > 3) {
						lastUpdate.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: { argb: "FF9999" },
						};
					}
				}
			}.bind(this)
		);
		workbook.xlsx.writeBuffer().then((xls64) => {
			const data = new Blob([xls64], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			fs.saveAs(data, "Protocoles.xlsx");
		});
	}

	shareMyProtocols() {
		this.dialog.open(ShareMyProtocolsComponent, {
			autoFocus: false,
			data: { protocols: this.protocols_all },
		});
	}

	clearFilters() {
		this.searchKeyword = "";
		this.tags = [];
		this.filterValues = {
			writer: true,
			approver: true,
			manager: true,
			status: -1,
			update: -1,
		};
		this.filterProtocols();
	}

	getErrorsCount(protocol: Protocol): number {
		return protocol?.errors.filter((e) => e.status == 0).length;
	}

	guidelines(): void {
		this.dialog.open(GuidelinesComponent, {
			disableClose: true,
			width: "600px",
			height: "600px",
			autoFocus: false,
		});
	}

	unarchive(protocol: Protocol): void {
		this.protocolService.unarchiveProtocol(protocol.id).subscribe((response) => {
			if (response.status === 204) {
				protocol.status = ProtocolStatus.draft;
				this.toastr.success(this.translate.instant("protocol.edit.toolbar.management.dearchive.success"));
				this.loadArchives(true);
			} else {
				this.toastr.error(this.translate.instant("protocol.edit.toolbar.management.dearchive.error"));
			}
		});
	}

	clearSearchKeyword(): void {
		this.searchKeyword = "";
		this.filterProtocols();
	}

	getOfflineProtocols(): Protocol[] {
		const offlineProtocols = this.protocols.filter((e) => e.status == ProtocolStatus.draft);
		return offlineProtocols.filter(
			(e) =>
				!this.getWaitingApprovalProtocols()
					.map((e) => e.id)
					.includes(e.id)
		);
	}

	getOnlineProtocols(): Protocol[] {
		const publishedProtocols = this.protocols.filter((e) => e.status == ProtocolStatus.published);
		return publishedProtocols.filter(
			(e) =>
				!this.getWaitingApprovalProtocols()
					.map((e) => e.id)
					.includes(e.id)
		);
	}

	getArchivedProtocols(): Protocol[] {
		return this.protocols.filter((e) => e.status == ProtocolStatus.archived);
	}

	getWaitingApprovalProtocols(): Protocol[] {
		const protocols = [
			...this.pendingRequestsProtocols,
			...this.protocols_all.filter((e) => e.errors.filter((j) => j.status == 0).length > 0),
		];
		return protocols.filter((e, i) => protocols.findIndex((j) => j.id === e.id) === i);
	}

	setCurrentView(view: EAuthorProtocolsView): void {
		this.currentView = view;
		this.userService.updateAuthorView(view).subscribe();
	}

	openNavigationSpecialties(): void {
		this.dialog.open(NavigationSpecialtiesModalComponent, {
			disableClose: true,
			autoFocus: false,
		});
	}

	protected readonly EAuthorProtocolsView = EAuthorProtocolsView;
}
