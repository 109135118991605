<div>
	<section class="py-3">
		<div class="container px-4 mx-auto">
			<div class="flex -mx-3 -mb-8 overflow-x-scroll">
				<app-kanban-list
					[title]="'protocols.kanban.to-validate.label' | translate"
					colorClass="bg-purple-500"
					[items]="waitingApprovalProtocols"></app-kanban-list>
				<app-kanban-list
					[title]="'protocols.kanban.draft.label' | translate"
					colorClass="bg-red-500"
					[items]="offlineProtocols"></app-kanban-list>
				<app-kanban-list
					[title]="'protocols.kanban.published.label' | translate"
					colorClass="bg-green-500"
					[items]="onlineProtocols"></app-kanban-list>
				<app-kanban-list
					[title]="'protocols.kanban.archived.label' | translate"
					colorClass="bg-blue-500"
					[items]="archivedProtocols"></app-kanban-list>
			</div>
		</div>
	</section>
</div>
