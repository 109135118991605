<div mat-dialog-content>
	<p>
		{{ "PROTOCOL-APPROVERS-NOTIFY_NOTIFY_APPROVERS_VALIDATE_PROTOCOL" | translate }}
	</p>
	<p [hidden]="!isApprover()">
		<i
			>{{ "PROTOCOL-APPROVERS-NOTIFY_APPROVER_PUBLISH_PROTOCOL" | translate }} <br />{{
				"PROTOCOL-APPROVERS-NOTIFY_APPOINTMENT_IN" | translate
			}}
			<b
				>{{ "PROTOCOL-APPROVERS-NOTIFY_ADMINISTRATION" | translate }}
				&#8594;
				{{ "PROTOCOL-APPROVERS-NOTIFY_VALIDATE_PUBLISH" | translate }}</b
			></i
		>
	</p>
	<p class="flex flex-col">
		<mat-checkbox
			*ngFor="let user of protocol.approvers"
			[(ngModel)]="user['send']"
			class="flex flex-row items-end">
			<mat-icon *ngIf="user.firstname === '__GROUP__'">groups</mat-icon>
			<span *ngIf="user.firstname !== '__GROUP__'">{{ user.firstname }}</span>
			{{ user.lastname }}
		</mat-checkbox>
	</p>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()"
		*ngIf="!isLoading"
		[disabled]="!enableSend()">
		{{ "PROTOCOL-APPROVERS-NOTIFY_NOTIFY" | translate }}
	</button>
</div>
