<div mat-dialog-content>
	<div
		#editor
		style="height: 600px"></div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()">
		{{ "core.apply" | translate }}
	</button>
</div>
