import { Component, ViewChild } from "@angular/core";
import { FilePond, FilePondOptions } from "filepond";
import { ToastrService } from "ngx-toastr";
import { ProtocolService } from "../../../services/protocol.service";
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { FilePondModule } from "ngx-filepond";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-import-pdf",
	templateUrl: "./protocol-import-pdf.component.html",
	styleUrls: ["./protocol-import-pdf.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		FilePondModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolImportPdfComponent {
	isLoading = false;
	fileTypes: string[] = ["application/pdf"];
	outputType = "text";
	@ViewChild("myPond") myPond!: FilePond;

	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.import.pdf.placeholder"),
		acceptedFileTypes: this.fileTypes,
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.import.pdf.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.import.pdf.authorized-files") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: {
			"application/pdf": "PDF",
		},
		credits: false,
	};

	constructor(
		private translate: TranslateService,
		private toastr: ToastrService,
		private protocolService: ProtocolService,
		private dialogRef: MatDialogRef<ProtocolImportPdfComponent>
	) {}

	import(): void {
		this.isLoading = true;
		const formData = new FormData();
		formData.append("file", this.myPond.getFile().file);
		this.protocolService.importPdf(formData, this.outputType).subscribe({
			next: (result) => {
				this.isLoading = false;
				this.dialogRef.close(result);
			},
			error: () => {
				this.isLoading = false;
				this.toastr.error(this.translate.instant("protocol.tools.import.pdf.error.import"));
			},
		});
	}

	isDisabled(): boolean {
		return this.myPond?.getFile()?.fileType !== "application/pdf";
	}
}
