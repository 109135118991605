import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { Tag } from "../../../interfaces/tag";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { OrderByPipe, UniquePipe } from "ngx-pipes";
import { Institute } from "../../../interfaces/institute";
import { UserService } from "../../../services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as fuzz from "fuzzball";
import { JanusService } from "../../../services/janus.service";
import { ToastrService } from "ngx-toastr";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ETagsSelectionMode } from "../../../enums/etags-selection-mode";
import { Subscription } from "rxjs";
import { ProtocolViewerService } from "../../../services/protocol-viewer.service";
import { SafeHtmlPipe } from "../../../pipes/safe-html.pipe";
import { MatTooltip } from "@angular/material/tooltip";
import { InstituteFilterComponent } from "../../institutes/institute-filter/institute-filter.component";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatHint, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { ProtocolService } from "../../../services/protocol.service";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { TagsStringPipe } from "../../../pipes/tags-string.pipe";

@Component({
	selector: "app-protocols",
	templateUrl: "./protocols.component.html",
	styleUrls: ["./protocols.component.scss"],
	standalone: true,
	imports: [
		MatCard,
		MatCardTitle,
		MatCardContent,
		MatSlideToggle,
		ReactiveFormsModule,
		FormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatIcon,
		MatPrefix,
		NgIf,
		MatSuffix,
		MatHint,
		MatProgressSpinner,
		TagFilterComponent,
		InstituteFilterComponent,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatTooltip,
		MatSortHeader,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		SafeHtmlPipe,
		NgxTolgeeModule,
		TagsStringPipe,
	],
})
export class ProtocolsComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = ["share", "label"];
	dataSource = new MatTableDataSource<Protocol>();
	protocols_all: Protocol[] = [];
	searchKeyword: string = "";
	janusAnswer = "";
	isJanusLoading = false;
	isJanusMode = false;
	tags: Tag[] = [];
	institutes: Institute[] = [];
	institutes_all: Institute[] = [];
	paramsSubscription: Subscription;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild("auto") matAutocomplete: MatAutocomplete;

	constructor(
		public manager: MitpManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private janus: JanusService,
		private userService: UserService,
		private oPipe: OrderByPipe,
		private uPipe: UniquePipe,
		private toastr: ToastrService,
		private translate: TranslateService,
		private protocolViewer: ProtocolViewerService,
		private protocolService: ProtocolService
	) {
		if (!this.router.url.startsWith("/protocols/subitems/")) {
			// this.tags = userService.getUserSectionTags();
		}
	}

	public getSearchPlaceholder(): string {
		return this.isJanusMode
			? this.translate.instant("protocols.search.janus.placeholder")
			: this.translate.instant("protocols.search.search.placeholder");
	}

	ngOnInit(): void {
		this.get();
		if (!this.router.url.startsWith("/protocols/subitems/")) {
			const protocolId = this.route.snapshot.params["id"];
			if (protocolId != null) {
				this.viewById(protocolId);
			}
		}
		this.paramsSubscription = this.route.params.subscribe(() => {
			this.filterProtocols();
		});
	}

	ngOnDestroy(): void {
		this.paramsSubscription.unsubscribe();
	}

	get(): void {
		this.manager.showLoading(true);
		this.protocolService.getProtocols().subscribe((protocols) => {
			this.protocols_all = protocols;
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.institutes_all = this.uPipe.transform(
				this.protocols_all.map((protocol) => protocol.institute),
				"id"
			);
			this.filterProtocols();
			this.manager.showLoading(false);
		});
	}

	view(protocol: Protocol): void {
		this.protocolService.getPublishedProtocol(protocol.id).subscribe((data) => {
			this.protocolViewer.viewProtocol(protocol, data);
		});
	}

	viewById(protocolId: number): void {
		this.protocolService.getProtocolById(protocolId).subscribe((protocol) => {
			this.view(protocol);
		});
	}

	filterProtocols() {
		if (this.isJanusMode) {
			return;
		}
		let filterProtocols = [];
		if (this.router.url.startsWith("/protocols/subitems/")) {
			const id = this.route.snapshot.params["id"];
			const navItems = JSON.parse(this.manager.readFromTemp("ProtocolNavigationItems"));
			const i = navItems.map((e) => e.id).indexOf(+id);
			if (i > -1) {
				const navItem = navItems[i];
				if (navItem.tags_selection_mode == ETagsSelectionMode.AND) {
					this.protocols_all.forEach((protocol) => {
						if (
							navItem.tags.every((tag) => {
								return protocol.tags.map((e) => e.id).includes(tag.id);
							})
						) {
							filterProtocols.push(protocol);
						}
					});
				} else if (navItem.tags_selection_mode == ETagsSelectionMode.OR) {
					this.protocols_all.forEach((protocol) => {
						if (
							navItem.tags.some((tag) => {
								return protocol.tags.map((e) => e.id).includes(tag.id);
							})
						) {
							filterProtocols.push(protocol);
						}
					});
				}
			}
		} else {
			filterProtocols = this.protocols_all;
		}
		let tmp = [];
		if (this.tags.length > 0) {
			filterProtocols.forEach((protocol) => {
				if (
					this.tags.every((tag) => {
						return protocol.tags.map((e) => e.id).includes(tag.id);
					})
				) {
					tmp.push(protocol);
				}
			});
			filterProtocols = tmp;
			tmp = [];
		}
		if (this.searchKeyword != "") {
			tmp = filterProtocols.filter((protocol) => {
				return fuzz.partial_ratio(protocol.label.toLowerCase(), this.searchKeyword.trim().toLowerCase()) > 60;
			});
			filterProtocols = tmp;
		}
		if (this.institutes.length > 0) {
			filterProtocols = filterProtocols.filter((protocol) =>
				this.institutes.map((institute) => institute.id).includes(protocol.institute.id)
			);
		}
		this.dataSource.data = this.oPipe.transform(filterProtocols, "label");
	}

	getShareIcon(element: Protocol): string {
		if (element.institute.id != this.userService.getInstituteId()) {
			return "share";
		}
		return "";
	}

	askQuestion(searchKeyword: string): void {
		if (!this.isJanusMode) {
			return;
		}
		this.isJanusLoading = true;
		this.dataSource.data = [];
		this.janusAnswer = "";
		this.janus.askQuestion(searchKeyword).subscribe({
			next: (data) => {
				this.janusAnswer = data.answer;
				const protocols: Protocol[] = [];
				data.sources.forEach((e) => {
					const i = this.protocols_all.map((p) => p.id).indexOf(+e);
					if (i >= 0) {
						protocols.push(this.protocols_all[i]);
					}
				});
				this.dataSource.data = protocols;
				this.isJanusLoading = false;
			},
			error: () => {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
				this.isJanusLoading = false;
			},
		});
	}

	clearSearchKeyword(): void {
		this.searchKeyword = "";
		this.filterProtocols();
	}

	onJanusToggle($event: MatSlideToggleChange): void {
		if ($event.checked) {
			this.dataSource.data = [];
			this.searchKeyword = "";
		} else {
			this.searchKeyword = "";
			this.janusAnswer = "";
			this.filterProtocols();
		}
	}
}
