<div class="flex h-[94vh] flex-col">
	<nav data-role="ribbonmenu">
		<ul class="tabs-holder">
			<li
				class="static"
				(click)="save()"
				style="cursor: pointer">
				<a>{{ "EDIT-PROTOCOL_SAVE" | translate }}</a>
			</li>
			<li>
				<a href="#section-general">{{ "EDIT-PROTOCOL_GENERAL" | translate }}</a>
			</li>
			<li>
				<a href="#section-tools">{{ "EDIT-PROTOCOL_TOOLS" | translate }}</a>
			</li>
			<li>
				<a href="#section-sources">{{ "protocols.protocol.edit.toolbar.sections.sources" | translate }}</a>
			</li>
			<li>
				<a href="#section-validation">{{ "EDIT-PROTOCOL_ADMINISTRATION" | translate }}</a>
			</li>
		</ul>
		<div class="content-holder">
			<div
				class="section"
				id="section-general">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="save()"
						[disabled]="!isAuthor() || isLocked"
						matTooltip="{{ 'EDIT-PROTOCOL_SAVE_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon [style.color]="!isAuthor() || isLocked ? 'gray' : 'black'">save</mat-icon>
						</span>
						<span
							class="caption"
							[style.color]="!isAuthor() || isLocked ? 'gray' : 'black'"
							>{{ "EDIT-PROTOCOL_SAVE" | translate }}</span
						>
					</button>
					<button
						class="ribbon-button"
						(click)="notifyApprovers()"
						[disabled]="protocol?.approvers?.length === 0"
						matTooltip="{{ 'EDIT-PROTOCOL_REQUEST_APPROVER_VALIDATION_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon>how_to_reg</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_NOTIFY" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="openJanus()"
						[matTooltip]="'janus.tooltip' | translate">
						<span class="icon">
							<mat-icon>assistant</mat-icon>
						</span>
						<span class="caption">{{ "janus.label" | translate }}</span>
					</button>

					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							(click)="editLabel()"
							matTooltip="{{ 'EDIT-PROTOCOL_EDIT_TITLE_PROTOCOL' | translate }}">
							<span class="icon">
								<mat-icon>dns</mat-icon>
							</span>
							<span class="caption">{{ "EDIT-PROTOCOL_TITLE" | translate }}</span>
						</button>

						<button
							class="ribbon-icon-button"
							(click)="editTags()"
							matTooltip="{{ 'EDIT-PROTOCOL_EDIT_KEYWORDS_PROROCOL' | translate }}">
							<span class="icon">
								<mat-icon>style</mat-icon>
							</span>
							<span class="caption">{{ "tags.title.label" | translate }}</span>
						</button>

						<button
							class="ribbon-icon-button"
							(click)="openChat()">
							<span class="icon">
								<mat-icon>question_answer</mat-icon>
							</span>
							<span class="caption">Messagerie</span>
						</button>

						<button
							class="ribbon-icon-button"
							(click)="unlock()"
							*ngIf="isOnlyManager() && isLocked"
							[matTooltip]="'protocol.edit.toolbar.unlock.tooltip' | translate">
							<span class="icon"><mat-icon style="color: red">lock</mat-icon></span>
							<span class="caption">{{ "protocol.edit.toolbar.unlock.button" | translate }}</span>
						</button>
					</div>

					<span class="title">{{ "EDIT-PROTOCOL_EDITION" | translate }}</span>
				</div>

				<div class="group">
					<button
						class="ribbon-button"
						(click)="assignAuthors()"
						matTooltip="{{ 'EDIT-PROTOCOL_EDIT_ROLES_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon
								[style.color]="
									protocol?.approvers?.length === 0 || protocol?.authors?.length === 0
										? 'red'
										: 'black'
								"
								>supervisor_account</mat-icon
							>
						</span>
						<span class="caption">{{ "protocol.edit.toolbar.authors.button" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="searchAuthors()"
						matTooltip="{{ 'EDIT-PROTOCOL_SEARCH_USER' | translate }}">
						<span class="icon">
							<mat-icon>search</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_SEARCH" | translate }}</span>
					</button>
					<span class="title">{{ "EDIT-PROTOCOL_SHARING_1" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="showErrors()"
						matTooltip="{{ 'EDIT-PROTOCOL_CONSULT_ERRORS_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon [style.color]="isError() ? 'red' : 'black'">warning</mat-icon>
						</span>
						<span class="caption">{{
							(isError() ? "EDIT-PROTOCOL_CONSULT_ERRORS" : "EDIT-PROTOCOL_NO_ERRORS") | translate
						}}</span>
					</button>
					<span class="title">{{ "EDIT-PROTOCOL_ERRORS" | translate }}</span>
				</div>

				<div class="group">
					<button
						class="ribbon-button"
						(click)="clearScript()"
						[matTooltip]="'protocol.edit.toolbar.scripts.clear.tooltip' | translate">
						<span class="icon">
							<mat-icon>layers_clear</mat-icon>
						</span>
						<span class="caption">{{ "protocol.edit.toolbar.scripts.clear.button" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="loadScriptEditor()"
						matTooltip="{{ 'EDIT-PROTOCOL_EDITOR_SCRIPT' | translate }}"
						*ngIf="manager.isAdmin() || manager.isCoder()">
						<span class="icon">
							<mat-icon>code</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_CODE_JS" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="validateJS()"
						matTooltip="{{ 'EDIT-PROTOCOL_VALIDATE_SCRIPT' | translate }}"
						*ngIf="manager.isAdmin()">
						<span class="icon">
							<mat-icon>verified_user</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_VALIDATE" | translate }}</span>
					</button>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							(click)="testProtocol()"
							matTooltip="{{ 'EDIT-PROTOCOL_TEST_PROTOCOL' | translate }}">
							<span class="icon">
								<mat-icon>launch</mat-icon>
							</span>
							<span class="caption">{{ "EDIT-PROTOCOL_TEST_PROTOCOL" | translate }}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="requestSecurityApproval()"
							matTooltip="{{ 'EDIT-PROTOCOL_REQUEST_APPROVING_PROTOCOL_ADMIN' | translate }}">
							<span class="icon">
								<mat-icon>forward_to_inbox</mat-icon>
							</span>
							<span class="caption">{{ "EDIT-PROTOCOL_REQUEST_APPROVING" | translate }}</span>
						</button>
					</div>
					<span class="title">{{ "EDIT-PROTOCOL_PART_INTERACTIVE" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>

			<div
				class="section"
				id="section-tools">
				<!-- Diagram Tool -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="loadDiagram()"
						[disabled]="isLocked">
						<span class="icon">
							<mat-icon>account_tree</mat-icon>
						</span>
						<span class="caption">{{ "protocol.tools.diagram.chart.label" | translate }}</span>
					</button>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							[matTooltip]="'protocol.tools.diagram.chart.delete.tooltip' | translate"
							(click)="deleteDiagram()"
							[disabled]="isLocked">
							<span class="icon">
								<mat-icon>delete</mat-icon>
							</span>
							<span class="caption">{{ "core.delete" | translate }}</span>
						</button>
					</div>
					<span class="title">{{ "protocol.tools.diagram.chart.label" | translate }}</span>
				</div>
				<!-- End Diagram Tool -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="loadSelectiveAlgos()"
						[matTooltip]="'protocol.edit.toolbar.diagram.selective.tooltip' | translate"
						[disabled]="isLocked">
						<span class="icon">
							<mat-icon>checklist</mat-icon>
						</span>
						<span class="caption">{{ "protocol.edit.toolbar.diagram.selective.button" | translate }}</span>
					</button>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							[matTooltip]="'protocol.edit.toolbar.diagram.selective.delete.tooltip' | translate"
							(click)="deleteSelectiveAlgos()"
							[disabled]="isLocked">
							<span class="icon">
								<mat-icon>delete</mat-icon>
							</span>
							<span class="caption">{{ "core.delete" | translate }}</span>
						</button>
					</div>
					<span class="title">{{ "protocol.edit.toolbar.diagram.selective.label" | translate }}</span>
				</div>
				<!-- End Selective Algo Tool -->
				<!-- Tools section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="loadDrugs()">
						<span class="icon">
							<mat-icon>medication</mat-icon>
						</span>
						<span class="caption">{{ "core.search" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="loadDrugEquivalents()">
						<span class="icon">
							<mat-icon [style.color]="drugEquivalents.length > 0 ? 'red' : 'black'">
								rebase_edit
							</mat-icon>
						</span>
						<span class="caption">Migration</span>
					</button>
					<span class="title">{{ "drugs.navigation-menu.title" | translate }}</span>
				</div>
				<!-- End Tools section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="openInteractiveOptions()"
						matTooltip="{{ 'EDIT-PROTOCOL_ADD_BUTTON_INTEGRATION_DOCUMENT' | translate }}"
						[disabled]="isLocked">
						<span class="icon">
							<mat-icon>smart_button</mat-icon>
						</span>
						<span class="caption">{{
							"protocol.edit.toolbar.integration.document.button" | translate
						}}</span>
					</button>
					<div class="ribbon-split-button">
						<button
							class="ribbon-main"
							(click)="openAttachments()"
							[matTooltip]="'protocols.attachments.tooltip' | translate">
							<span class="icon">
								<mat-icon>attach_file</mat-icon>
							</span>
						</button>
						<span
							class="ribbon-split dropdown-toggle"
							[matMenuTriggerFor]="menu"
							>{{ "protocols.attachments.label" | translate }}</span
						>
						<mat-menu
							#menu="matMenu"
							class="w-[350px]">
							<div
								mat-menu-item
								*ngIf="protocol.attachments.length === 0">
								{{ "protocol.attachments.empty.shortcut" | translate }}
							</div>
							<button
								mat-menu-item
								[matMenuTriggerFor]="actionsMenu"
								*ngFor="let item of protocol.attachments">
								<div class="flex flex-row items-center gap-x-2">
									<img
										[ngSrc]="fileService.getExtensionIcon(item.extension)"
										alt="extension logo"
										height="32"
										width="32" />
									<span class="text-sm">{{ item.label }}</span>
								</div>
								<mat-menu #actionsMenu="matMenu">
									<button
										mat-menu-item
										(click)="downloadAttachment(item)">
										<mat-icon>download</mat-icon>
										{{ "core.download" | translate }}
									</button>
									<button
										mat-menu-item
										(click)="toggleSharing(item)">
										<mat-icon>{{ item.enable_sharing ? "lock_outline" : "lock_open" }}</mat-icon>
										{{
											(item.enable_sharing
												? "protocol.share.disable.label"
												: "protocol.share.enable.label"
											) | translate
										}}
									</button>
									<button
										mat-menu-item
										(click)="replaceAttachment(item)">
										<mat-icon matIconPrefix>upload_file</mat-icon>
										{{ "core.replace" | translate }}
									</button>
									<mat-divider></mat-divider>
									<button
										mat-menu-item
										(click)="deleteAttachment(item)">
										<mat-icon
											matIconPrefix
											color="warn"
											>delete
										</mat-icon>
										<span class="text-red-600">{{ "core.delete" | translate }}</span>
									</button>
								</mat-menu>
							</button>
						</mat-menu>
					</div>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							(click)="addEmbeddedProtocol()"
							[matTooltip]="'protocol.edit.toolbar.integration.protocol.tooltip' | translate"
							[disabled]="isLocked">
							<span class="icon">
								<mat-icon>expand</mat-icon>
							</span>
							<span class="caption">{{
								"protocol.edit.toolbar.integration.protocol.button" | translate
							}}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="insertVideos()"
							[matTooltip]="'protocol.edit.tools.integration.videos.tooltip' | translate"
							[disabled]="isLocked">
							<span class="icon">
								<mat-icon>videocam</mat-icon>
							</span>
							<span class="caption">{{
								"protocol.edit.tools.integration.videos.label" | translate
							}}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="insertAppendix()"
							[matTooltip]="'protocols.attachments.tooltip' | translate"
							[disabled]="isLocked">
							<span class="icon">
								<mat-icon>attach_file</mat-icon>
							</span>
							<span class="caption">{{ "protocols.attachments.label" | translate }}</span>
						</button>
					</div>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							(click)="importWord()"
							[matTooltip]="'protocol.edit.toolbar.integration.word.tooltip' | translate">
							<span class="icon">
								<mat-icon>text_snippet</mat-icon>
							</span>
							<span class="caption">{{
								"protocol.edit.toolbar.integration.word.button" | translate
							}}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="importPDF()"
							[matTooltip]="'protocol.edit.toolbar.integration.pdf.tooltip' | translate">
							<span class="icon">
								<mat-icon>picture_as_pdf</mat-icon>
							</span>
							<span class="caption">{{
								"protocol.edit.toolbar.integration.pdf.button" | translate
							}}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="extractFromPdf()"
							[matTooltip]="'protocol.edit.tools.extract-pdf.tooltip' | translate">
							<span class="icon">
								<mat-icon>colorize</mat-icon>
							</span>
							<span class="caption">{{ "protocol.edit.tools.extract-pdf.label" | translate }}</span>
						</button>
					</div>
					<div class="flex flex-col">
						<button
							class="ribbon-icon-button"
							(click)="toChecklist()"
							[matTooltip]="'protocol.edit.tools.todolist.tooltip' | translate">
							<span class="icon">
								<mat-icon>checklist</mat-icon>
							</span>
							<span class="caption">{{ "protocol.edit.tools.todolist.label" | translate }}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="convertToTel()"
							[matTooltip]="'protocol.edit.tools.integration.convert-tel.tooltip' | translate">
							<span class="icon">
								<mat-icon>phone_enabled</mat-icon>
							</span>
							<span class="caption">{{
								"protocol.edit.tools.integration.convert-tel.label" | translate
							}}</span>
						</button>
						<button
							class="ribbon-icon-button"
							(click)="createGlassesProtocol()">
							<span class="icon">
								<mat-icon svgIcon="glasses"></mat-icon>
							</span>
							<span class="caption">Lunettes</span>
						</button>
					</div>
					<span class="title">{{ "protocol.edit.toolbar.integration.label" | translate }}</span>
				</div>
				<!-- End Integration Tool -->
				<div class="group">
					<!--          <button class="ribbon-button" (click)="formatBlanks()" [matTooltip]="'protocol.edit.toolbar.format.clean.tooltip' | translate" [disabled]="isLocked">-->
					<!--            <span class="icon">-->
					<!--              <mat-icon>format_paint</mat-icon>-->
					<!--            </span>-->
					<!--            <span class="caption">{{'protocol.edit.toolbar.format.clean.button' | translate}}</span>-->
					<!--          </button>-->
					<button
						class="ribbon-button"
						(click)="formatTables()"
						[matTooltip]="'protocol.edit.toolbar.format.table.tooltip' | translate"
						[disabled]="isLocked">
						<span class="icon">
							<mat-icon>border_all</mat-icon>
						</span>
						<span class="caption">{{ "protocol.edit.toolbar.format.table.button" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="newLine()"
						[matTooltip]="'protocol.edit.toolbar.format.new-line.tooltip' | translate"
						[disabled]="isLocked">
						<span class="icon">
							<mat-icon>keyboard_return</mat-icon>
						</span>
						<span class="caption">{{ "protocol.edit.toolbar.format.new-line.button" | translate }}</span>
					</button>
					<span class="title">{{ "EDIT-PROTOCOL_FORMATTING" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>
			<!-- Sources tool -->
			<div
				class="section"
				id="section-sources">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="openAthena()"
						matTooltip="{{ 'protocols.protocol.edit.toolbar.sources.athena.tooltip' | translate }}">
						<span class="icon">
							<img
								src="assets/images/journal.png"
								alt="athena" />
						</span>
						<span class="caption">{{ "athena.label" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="uptodate()"
						matTooltip="{{ 'protocols.protocol.edit.toolbar.sources.uptodate.tooltip' | translate }}">
						<span class="icon">
							<img
								src="assets/images/utd-icon.png"
								alt="uptodate" />
						</span>
						<span class="caption">{{
							"protocols.protocol.edit.toolbar.sources.uptodate.label" | translate
						}}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="vidalRecos()"
						matTooltip="{{ 'protocols.protocol.edit.toolbar.sources.vidal-recos.tooltip' | translate }}">
						<span class="icon">
							<img
								src="assets/images/vidal-logo.png"
								alt="vidal" />
						</span>
						<span class="caption">{{
							"protocols.protocol.edit.toolbar.sources.vidal-recos.label" | translate
						}}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="openSwissPedDose()"
						matTooltip="{{ 'protocols.protocol.edit.toolbar.sources.swisspeddose.tooltip' | translate }}">
						<span class="icon">
							<img
								src="assets/images/logo/swisspeddose.png"
								alt="swisspeddose" />
						</span>
						<span class="caption">{{
							"protocols.protocol.edit.toolbar.sources.swisspeddose.label" | translate
						}}</span>
					</button>
					<span class="title">{{ "protocols.protocol.edit.toolbar.sources.guidelines" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>
			<div
				class="section"
				id="section-validation">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="deleteProtocol()"
						[disabled]="!isManager()"
						*ngIf="!isInitiated()"
						matTooltip="{{ 'EDIT-PROTOCOL_DELETE_PROTOCOL_DEFINITELY' | translate }}">
						<span class="icon">
							<mat-icon>delete</mat-icon>
						</span>
						<span class="caption">{{ "core.delete" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="initProtocol()"
						*ngIf="protocol?.updates.length === 0"
						[disabled]="isInitiated() || !isManager()"
						matTooltip="{{ 'EDIT-PROTOCOL_PUBLISH_PROTOCOL_DATE' | translate }}">
						<span class="icon">
							<mat-icon>360</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_INITIALIZE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="archiveProtocol()"
						[disabled]="!isManager() || protocol?.status === -1 || protocol?.updates.length === 0"
						matTooltip="{{ 'EDIT-PROTOCOL_WITHDRAW_ARCHIVE_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon>archive</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_ARCHIVE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="disableProtocol()"
						[disabled]="!isManager() || protocol?.status === 0"
						matTooltip="{{ 'EDIT-PROTOCOL_WITHDRAW_FROM_CIRCULATION' | translate }}">
						<span class="icon">
							<mat-icon>block</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_DEACTIVATE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="publishProtocol()"
						matTooltip="{{ 'EDIT-PROTOCOL_PUBLISH_PROTOCOL' | translate }}"
						[disabled]="!isApprover() && !(isInitiated() && isManager())">
						<span class="icon">
							<mat-icon>check</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_VALIDATE_PUBLISH" | translate }}</span>
					</button>

					<span class="title">{{ "EDIT-PROTOCOL_VALIDATION" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="shareProtocol()"
						[disabled]="!isManager()"
						matTooltip="{{ 'EDIT-PROTOCOL_EDIT_SETTINGS_SHARING_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon>share</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_SHARE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="shareByLink()"
						matTooltip="{{ 'EDIT-PROTOCOL_SHARE_PROTOCOL_PUBLIC_LINK' | translate }}">
						<span class="icon">
							<mat-icon>link</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_SHARE_BY_LINK" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="downloadProtocol()"
						[disabled]="!isManager()"
						matTooltip="{{ 'EDIT-PROTOCOL_DOWNLOAD_PROTOCOL_PDF_WORD' | translate }}">
						<span class="icon">
							<mat-icon>cloud_download</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_DOWNLOAD" | translate }}</span>
					</button>

					<span class="title">{{ "EDIT-PROTOCOL_SHARING_2" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="setCodification()"
						matTooltip="{{ 'EDIT-PROTOCOL_EDIT_CODIFICATION_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon>layers</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_CODIFICATION" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="showHistory()"
						matTooltip="{{ 'EDIT-PROTOCOL_CONSULT_HISTORY_CHANGE_PROTOCOL' | translate }}">
						<span class="icon">
							<mat-icon>history</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_HISTORY" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						*ngIf="false">
						<span class="icon">
							<mat-icon>timeline</mat-icon>
						</span>
						<span class="caption">{{ "EDIT-PROTOCOL_TIME_LINE" | translate }}</span>
					</button>

					<span class="title">{{ "EDIT-PROTOCOL_QUALITY" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>
		</div>
	</nav>
	<editor
		class="h-full"
		[(ngModel)]="protocolContent.content"
		[init]="{
			base_url: '/tinymce',
			suffix: '.min',
			setup: onEditorSetup,
			height: '100%',
			language: 'fr_FR',
			promotion: false,
			branding: false,
			license_key: 'gpl',
			language_url: './assets/tinymce/fr_FR.js',
			plugins: [
				'advlist',
				'autolink',
				'lists',
				'link',
				'image',
				'charmap',
				'anchor',
				'searchreplace',
				'visualblocks',
				'visualchars',
				'code',
				'nonbreaking',
				'table',
				'directionality',
				'codesample',
				'emoticons',
			],
			toolbar1:
				'styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
			toolbar2:
				'insert link charmap hr image emoticons | style-p style-div style-h1 style-h2 style-h3 style-h4 | searchreplace forecolor backcolor',
			extended_valid_elements:
				'svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*],mitp-algo[id],mitp-embedded[type|legend|color],mitp-drug[reference|dci|atc]',
			custom_elements: 'mitp-algo,mitp-drug',
			image_advtab: true,
			automatic_uploads: true,
			browser_spellcheck: true,
			quickbars_selection_toolbar: 'bold italic underline | blocks | bullist numlist | blockquote quicklink',
			content_css: 'https://api.medinthepocket.com/public/resources/protocols/css',
			table_default_styles: {
				'border-color': '#000000',
				width: '100%',
				'margin-left': 'auto',
				'margin-right': 'auto',
			},
			table_default_attributes: { border: 1, cellspacing: 0 },
			formats: {
				h1: { block: 'h1', styles: { color: '#0D47A1' } },
				h2: {
					block: 'h2',
					styles: { color: '#1565C0', margin: '0 0 0 8px' },
				},
				h3: {
					block: 'h3',
					styles: { color: '#2196F3', margin: '0 0 0 16px' },
				},
				h4: {
					block: 'h4',
					styles: { color: '#64B5F6', margin: '0 0 0 24px' },
				},
				p: { block: 'p', styles: { color: 'black' } },
				div: { block: 'div', styles: { color: 'black' } },
			},
			file_picker_types: 'image',
			file_picker_callback: uploadImage,
			nonbreaking_force_tab: false,
		}">
	</editor>
</div>
