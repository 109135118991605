import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { MatButton } from "@angular/material/button";
import { AuthorsFilterComponent } from "../../users/authors-filter/authors-filter.component";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-authors",
	templateUrl: "./protocol-authors.component.html",
	styleUrls: ["./protocol-authors.component.scss"],
	standalone: true,
	imports: [MatDialogContent, AuthorsFilterComponent, MatDialogActions, MatButton, MatDialogClose, NgxTolgeeModule],
})
export class ProtocolAuthorsComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public protocol: Protocol) {}
}
