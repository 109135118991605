<div class="w-80 px-3 mb-8">
	<div class="max-w-sm mx-auto h-full px-4 pt-6 pb-24 rounded-xl">
		<div class="flex items-center justify-between mb-3">
			<div class="flex flex-row items-center justify-between w-full">
				<div class="text-lg font-semibold mr-2">
					{{ title }}
				</div>
				<div
					class="inline-flex items-center justify-center w-6 h-7 rounded-full bg-gray-600 text-xs font-medium text-white">
					{{ items.length }}
				</div>
			</div>
		</div>
		<div
			class="h-1 w-full mb-4 rounded-full"
			[ngClass]="colorClass"></div>
		@for (item of items; track item.id) {
			<app-kanban-row [item]="item"></app-kanban-row>
		}
	</div>
</div>
