import { Component, ViewChild } from "@angular/core";
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { FilePond, FilePondOptions } from "filepond";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { FilePondModule } from "ngx-filepond";
import { MatIcon } from "@angular/material/icon";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-interactive-options",
	templateUrl: "./protocol-interactive-options.component.html",
	styleUrls: ["./protocol-interactive-options.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatIcon,
		MatSuffix,
		FilePondModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolInteractiveOptionsComponent {
	label = "";
	isLoading = false;
	@ViewChild("myPond") myPond!: FilePond;

	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.embed.document.placeholder"),
		acceptedFileTypes: ["application/pdf"],
		maxFileSize: "600KB",
		labelMaxFileSizeExceeded: this.translate.instant("protocol.tools.embed.document.error.size-exceeded"),
		labelMaxFileSize: this.translate.instant("protocol.tools.embed.document.size-max") + " {filesize}",
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.embed.document.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.embed.document.authorized-document") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: { "application/pdf": "PDF" },
		credits: false,
	};

	constructor(
		private dialogRef: MatDialogRef<ProtocolInteractiveOptionsComponent>,
		private translate: TranslateService
	) {}

	add(): void {
		this.isLoading = true;
		const id = Date.now().toString(36);
		const btn = `<button id="${id}" style="font-size: 20px; color: white; background: #2893ED; padding: 16px; cursor: pointer; min-width: 150px">${this.label}</button>`;
		const reader = new FileReader();
		reader.readAsDataURL(this.myPond.getFile().file);
		reader.onload = () => {
			this.isLoading = false;
			const script = `document.getElementById("${id}").onclick = function(){
                        const downloadLink = document.createElement("a");
                        downloadLink.href = "${reader.result}";
                        downloadLink.download = 'formulaire.pdf';
                        downloadLink.click();
                    };`;
			this.dialogRef.close({ html: btn, script: script });
		};
	}

	isDisabled(): boolean {
		return this.label.trim().length == 0 || this.myPond.getFile()?.fileType !== "application/pdf";
	}
}
