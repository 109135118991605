import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import * as ace from "ace-builds";
import { MatButton } from "@angular/material/button";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-script-editor",
	templateUrl: "./protocol-script-editor.component.html",
	styleUrls: ["./protocol-script-editor.component.scss"],
	standalone: true,
	imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, NgxTolgeeModule],
})
export class ProtocolScriptEditorComponent implements AfterViewInit {
	private aceEditor = null;
	@ViewChild("editor") editor: ElementRef<HTMLElement>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public code: string,
		private dialogRef: MatDialogRef<ProtocolScriptEditorComponent>
	) {}

	ngAfterViewInit() {
		//ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		//ace.config.set('basePath', '/assets/ui/');
		//ace.config.set('modePath', '');
		//ace.config.set('themePath', '');
		this.aceEditor = ace.edit(this.editor.nativeElement);
		this.aceEditor.session.setValue(this.code);
		this.aceEditor.setTheme("ace/theme/cobalt");
		this.aceEditor.session.setMode("ace/mode/javascript");
	}

	apply() {
		this.dialogRef.close(this.aceEditor.getValue());
	}
}
