import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogContent } from "@angular/material/dialog";
import { SafeHtmlPipe } from "../../../pipes/safe-html.pipe";
import { LegalService } from "../../../services/legal.service";
import { first } from "rxjs";

@Component({
	selector: "app-view-legal-document",
	templateUrl: "./view-legal-document.component.html",
	styleUrls: ["./view-legal-document.component.scss"],
	standalone: true,
	imports: [MatDialogContent, SafeHtmlPipe],
})
export class ViewLegalDocumentComponent {
	static CGU = "cgu";
	static CGV = "cgv";
	static PP = "pp";
	content = "";

	constructor(
		@Inject(MAT_DIALOG_DATA) data: string,
		private legalService: LegalService
	) {
		this.legalService
			.getPublicLegalDoc(data)
			.pipe(first())
			.subscribe((response) => {
				this.content = response;
			});
	}
}
