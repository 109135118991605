<div
	mat-dialog-content
	class="flex flex-col">
	<div class="flex flex-col">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort
			class="w-full">
			<ng-container matColumnDef="created">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "PROTOCOL-SHARE-BY-LINK_CREATED_AT" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.created | date: "dd/MM/yyyy" }}
				</td>
			</ng-container>
			<ng-container matColumnDef="byUser">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "PROTOCOL-SHARE-BY-LINK_CREATED_BY" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.user.firstname }} {{ element.user.lastname }}
				</td>
			</ng-container>
			<ng-container matColumnDef="actions">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "PROTOCOL-SHARE-BY-LINK_ACTIONS" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					<mat-icon
						style="margin-right: 8px"
						[cdkCopyToClipboard]="getPublicLink(element)"
						*ngIf="!isExpired(element)"
						>file_copy
					</mat-icon>
					<mat-icon
						style="margin-left: 8px"
						(click)="createEmail(element)"
						*ngIf="!isExpired(element)"
						>mail
					</mat-icon>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<mat-paginator
			[pageSizeOptions]="[12, 30, 100]"
			showFirstLastButtons></mat-paginator>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="generateToken()"
		*ngIf="!isLoading"
		[disabled]="false">
		{{ "core.create" | translate }}
	</button>
</div>
