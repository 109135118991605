<div mat-dialog-content>
	<app-authors-filter
		placeholder="{{ 'PROTOCOL-AUTHORS_AUTHORS' | translate }}"
		[protocolId]="protocol.id"
		[(authors)]="protocol.authors"
		[(approvers)]="protocol.approvers"
		[(managers)]="protocol.managers"></app-authors-filter>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
