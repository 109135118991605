<div mat-dialog-title>
	<div class="flex flex-row items-center justify-between">
		<img
			src="../../../../assets/images/logo-UptoDate.png"
			alt="UpToDate"
			class="w-48" />
		<button
			mat-icon-button
			mat-dialog-close>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>
<div mat-dialog-content>
	<div
		class="flex flex-col py-2"
		[formGroup]="utdSearchGroup">
		<mat-form-field appearance="outline">
			<mat-label>{{ "protocols.protocol.edit.toolbar.sources.uptodate.search" | translate }}</mat-label>
			<input
				matInput
				formControlName="searchKeyword"
				[formControl]="utdSearchFormControl"
				[matAutocomplete]="autoSearch" />
			<mat-icon matPrefix>search</mat-icon>
			<mat-spinner
				*ngIf="isLoading"
				matIconSuffix
				diameter="30"
				class="m-2"></mat-spinner>
			<mat-autocomplete
				#autoSearch
				(optionSelected)="searchUptodate($event.option.value)">
				<mat-option
					*ngFor="let suggestion of suggestions"
					value="{{ suggestion }}"
					>{{ suggestion }}</mat-option
				>
			</mat-autocomplete>
		</mat-form-field>
		<div class="flex flex-col gap-y-6">
			<mat-accordion>
				<mat-expansion-panel *ngFor="let result of searchResults">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ result.title }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div [innerHtml]="result.snippet"></div>
					<mat-action-row>
						<button
							mat-icon-button
							[matTooltip]="'protocols.protocol.edit.toolbar.sources.add-reference' | translate"
							(click)="addReference(result)">
							<mat-icon>format_quote</mat-icon>
						</button>
						<button
							mat-icon-button
							[matTooltip]="'protocols.protocol.edit.toolbar.sources.open-article' | translate"
							(click)="openArticle(result)">
							<mat-icon>open_in_new</mat-icon>
						</button>
					</mat-action-row>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>
