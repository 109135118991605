<div class="flex flex-col">
	<p style="margin-top: 16px">
		<mat-radio-group
			class="flex flex-row justify-between"
			[(ngModel)]="category"
			(ngModelChange)="loadAuthors()">
			<mat-radio-button [value]="2">{{ "AUTHORS-FILTER_EDITORS" | translate }}</mat-radio-button>
			<mat-radio-button [value]="3">{{ "AUTHORS-FILTER_APPROVERS" | translate }}</mat-radio-button>
			<mat-radio-button [value]="1">{{ "AUTHORS-FILTER_ADMINS" | translate }}</mat-radio-button>
		</mat-radio-group>
	</p>
	<div
		class="flex flex-row items-start justify-around"
		*ngIf="isManager()">
		<mat-form-field
			class="w-full"
			appearance="{{ isOutline ? 'outline' : 'fill' }}"
			style="margin-right: 16px">
			<mat-label>{{ placeholder }}</mat-label>
			<input
				type="text"
				aria-label=""
				matInput
				[formControl]="userCtrl"
				[matAutocomplete]="auto" />
			<mat-autocomplete
				#auto="matAutocomplete"
				(optionSelected)="selected($event)"
				class="flex flex-col">
				@for (user of filteredUsers | async; track user.id; let isLast = $last) {
					<mat-option
						class="flex flex-row items-center justify-start"
						[value]="user"
						[disabled]="isDisabled(user)">
						<div class="flex flex-row">
							<mat-icon *ngIf="user.firstname === '__GROUP__'">groups</mat-icon>
							<mat-icon *ngIf="user.firstname !== '__GROUP__'"></mat-icon>
							<div class="flex flex-col">
								<div>
									<span *ngIf="user.firstname !== '__GROUP__'">{{ user.firstname }} </span
									><span>{{ user.lastname }}</span>
								</div>
								<div *ngIf="isAuthor(user) || isApprover(user) || isProtocolManager(user)">
									<mat-icon class="scale-50 w-4 h-4">{{ isAuthor(user) ? "edit" : "" }}</mat-icon>
									<mat-icon class="scale-50 w-4 h-4"
										>{{ isApprover(user) ? "done_all" : "" }}
									</mat-icon>
									<mat-icon class="scale-50 w-4 h-4"
										>{{ isProtocolManager(user) ? "folder_copy" : "" }}
									</mat-icon>
								</div>
							</div>
						</div>
					</mat-option>
					<mat-divider *ngIf="!isLast"></mat-divider>
				}
			</mat-autocomplete>
			<mat-icon matIconPrefix>people</mat-icon>
		</mat-form-field>
		<button
			mat-mini-fab
			color="primary"
			style="margin-top: 8px"
			(click)="searchUser()">
			<mat-icon>search</mat-icon>
		</button>
	</div>
	<div
		*ngFor="let item of users"
		style="margin-bottom: 16px"
		class="flex flex-row items-center">
		<mat-icon
			(click)="deleteUser(item)"
			style="margin-right: 8px; cursor: pointer"
			*ngIf="!isMeManager(item) && isManager()"
			>delete
		</mat-icon>
		<label class="flex flex-1">
			<span *ngIf="item.firstname !== '__GROUP__'">{{ item.firstname }} </span>{{ item.lastname }}
		</label>
	</div>
</div>
