import { Injectable } from "@angular/core";
import mermaid from "mermaid";

@Injectable({
	providedIn: "root",
})
export class DiagramService {
	constructor() {
		mermaid.initialize({
			securityLevel: "loose",
			startOnLoad: false,
			flowchart: {
				htmlLabels: false,
				useMaxWidth: false,
			},
			deterministicIds: false,
		});
	}

	async generateSvg(markdown: string, type: string): Promise<string | null> {
		if (markdown === "") {
			return null;
		}
		const code = `graph ${type}\n` + markdown;
		try {
			const result = await mermaid.render("mermaid", code);
			return result.svg;
		} catch {
			return null;
		}
	}

	async createHtmlElement(markdown: string, type: string): Promise<Document | null> {
		const svg = await this.generateSvg(markdown, type);
		if (svg === null) {
			return null;
		}
		const parser = new DOMParser();
		const doc = parser.parseFromString(svg, "image/svg+xml");
		doc.documentElement.dataset.markdown = markdown;
		doc.documentElement.dataset.type = type;
		return doc;
	}
}
