<button
	mat-icon-button
	[matMenuTriggerFor]="menu"
	#menuTrigger="matMenuTrigger">
	<mat-icon
		*ngIf="isEnabled"
		[matBadge]="getBadgeNumber()"
		[matBadgeHidden]="getBadgeNumber() === 0"
		matBadgeColor="warn">
		notifications
	</mat-icon>
	<mat-icon *ngIf="!isEnabled">notifications_off</mat-icon>
</button>
<mat-menu #menu="matMenu">
	<button
		mat-menu-item
		*ngFor="let item of messages"
		(click)="OnClickItem($event, item)">
		<div class="flex flex-row items-center gap-x-4">
			<span
				class="text-4xl text-blue-300"
				*ngIf="!item.is_read">
				&#8226;
			</span>
			<span>{{ item.subject }}</span>
		</div>
	</button>
	<mat-divider></mat-divider>
	<button
		mat-menu-item
		(click)="showHistory()">
		<mat-icon>history</mat-icon>
		{{ "notification.history" | translate }}
	</button>
</mat-menu>
