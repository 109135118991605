<div mat-dialog-title>
	<div class="flex flex-row items-center justify-between">
		<img
			src="../../../../../assets/images/vidal-recos-logo.png"
			alt="UpToDate"
			class="w-48" />
		<div class="flex flex-row gap-x-2 items-center">
			<button
				mat-button
				color="primary"
				(click)="getAll()">
				Tout afficher
			</button>
			<button
				mat-icon-button
				mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</div>
<div mat-dialog-content>
	<div
		class="flex flex-col py-2"
		[formGroup]="searchGroup">
		<mat-form-field appearance="outline">
			<mat-label>{{ "protocols.protocol.edit.toolbar.sources.vidal-recos.search" | translate }}</mat-label>
			<input
				matInput
				formControlName="searchKeyword"
				(keyup.enter)="search(searchGroup.get('searchKeyword').value)" />
			<mat-icon matPrefix>search</mat-icon>
			<mat-spinner
				*ngIf="isLoading"
				matIconSuffix
				diameter="30"
				class="mx-2"></mat-spinner>
		</mat-form-field>
		<div class="flex flex-col gap-y-6">
			<table
				mat-table
				[dataSource]="dataSource"
				matSort>
				<ng-container matColumnDef="title">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header>
						{{ "protocols.protocol.edit.toolbar.sources.vidal-recos.title" | translate }}
					</th>
					<td
						mat-cell
						*matCellDef="let element"
						class="cursor-pointer"
						(click)="openArticle(element.id)">
						{{ element.title }}
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th
						mat-header-cell
						*matHeaderCellDef></th>
					<td
						mat-cell
						*matCellDef="let element">
						<button
							mat-icon-button
							[matTooltip]="'protocols.protocol.edit.toolbar.sources.add-reference' | translate"
							(click)="addReference(element)">
							<mat-icon>format_quote</mat-icon>
						</button>
					</td>
				</ng-container>
				<tr
					mat-header-row
					*matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"></tr>
			</table>
			<mat-paginator
				[pageSizeOptions]="[10]"
				showFirstLastButtons></mat-paginator>
		</div>
	</div>
</div>
