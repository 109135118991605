import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { TagsStringPipe } from "../../../../pipes/tags-string.pipe";
import { MatIcon } from "@angular/material/icon";
import { OrderByPipe } from "ngx-pipes";
import { DatePipe, NgClass, NgIf } from "@angular/common";
import { ThemeService } from "../../../../services/theme.service";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-kanban-row",
	standalone: true,
	imports: [TagsStringPipe, MatIcon, DatePipe, NgIf, NgClass, NgxTolgeeModule],
	templateUrl: "./kanban-row.component.html",
	styleUrl: "./kanban-row.component.scss",
})
export class KanbanRowComponent {
	@Input() item: Protocol;

	constructor(
		private router: Router,
		private orderBy: OrderByPipe,
		public themeService: ThemeService
	) {}

	navigateTo(): void {
		this.router.navigate(["protocols", this.item.id, "edit"]);
	}

	getStatus(): string {
		return "";
	}

	getLastUpdate(): string {
		const updates = this.orderBy.transform(this.item.updates, "-updated");
		return updates[0]?.updated;
	}

	getVersion(): string {
		if (this.item.updates.length === 0) {
			return "v0.0";
		}
		const updates = this.orderBy.transform(this.item.updates, ["-updated", "-major_version", "-minor_version"]);
		return "v" + updates[0]?.major_version + "." + updates[0]?.minor_version;
	}

	isError(): boolean {
		return this.item.errors.filter((e) => e.status === 0).length > 0;
	}

	getErrorCount(): number {
		return this.item.errors.filter((e) => e.status === 0).length;
	}
}
