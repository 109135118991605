import { Component, Inject, ViewChild } from "@angular/core";
import { FilePond, FilePondOptions } from "filepond";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";

import { convertToHtml } from "mammoth/mammoth.browser";
import { ToastrService } from "ngx-toastr";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { FilePondModule } from "ngx-filepond";
import { NgIf } from "@angular/common";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-import",
	templateUrl: "./protocol-import.component.html",
	styleUrls: ["./protocol-import.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		NgIf,
		FilePondModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolImportComponent {
	label = "";
	isLoading = false;
	fileTypes: string[] = [
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/msword",
	];
	@ViewChild("myPond") myPond!: FilePond;

	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.import.word.placeholder"),
		acceptedFileTypes: this.fileTypes,
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.import.word.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.import.word.authorized-files") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: {
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Word",
			"application/msword": "Word",
		},
		credits: false,
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<ProtocolImportComponent>,
		private translate: TranslateService
	) {}

	isDisabled(): boolean {
		return (
			this.myPond?.getFile()?.fileType !==
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
			this.myPond?.getFile()?.fileType != "application/msword"
		);
	}

	import(): void {
		const file = this.myPond.getFile().file;
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => {
			convertToHtml({ arrayBuffer: reader.result })
				.then(
					(result) => {
						this.dialogRef.close(result);
					},
					() => {
						this.toastr.error(this.translate.instant("protocol.tools.import.word.error.import"));
					}
				)
				.done();
		};
	}
}
