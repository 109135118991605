import { Component, Inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import _moment from "moment";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
} from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { ToastrService } from "ngx-toastr";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton, MatMiniFabButton } from "@angular/material/button";
import { DatePipe, NgFor, NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { ProtocolService } from "../../../services/protocol.service";

const moment = _moment;

@Component({
	selector: "app-protocol-init",
	templateUrl: "./protocol-init.component.html",
	styleUrls: ["./protocol-init.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		MatDatepickerInput,
		ReactiveFormsModule,
		FormsModule,
		MatDatepickerToggle,
		MatSuffix,
		MatDatepicker,
		MatIcon,
		MatPrefix,
		NgIf,
		MatMiniFabButton,
		NgFor,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatProgressSpinner,
		DatePipe,
		NgxTolgeeModule,
	],
})
export class ProtocolInitComponent {
	isLoading: boolean = false;
	date = new UntypedFormControl(moment());
	maxDate = new Date();
	currentVersion = { date: null, version: null };
	versions = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private dialogRef: MatDialogRef<ProtocolInitComponent>,
		private toastr: ToastrService,
		private protocolService: ProtocolService,
		private translate: TranslateService
	) {}

	initProtocol() {
		this.protocolService.initiateProtocol(this.protocol.id, this.versions).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translate.instant("protocol.init.success"));
				this.dialogRef.close(true);
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}

	addVersion() {
		this.versions.push(this.currentVersion);
		this.currentVersion = { date: null, version: null };
	}

	deleteVersion(item: any) {
		const index = this.versions.map((e) => e.version).indexOf(item.version);
		if (index > -1) {
			this.versions.splice(index, 1);
		}
	}
}
