import { ENotificationType } from "../enums/enotification-type";
import { INotification } from "../interfaces/inotification";

export class PushNotification implements INotification {
	isRead: boolean;
	type = ENotificationType.READ;
	title: string;
	body: string;
	createdAt?: Date;

	getTitle(): string {
		return this.title;
	}

	getBody(): string {
		return this.body;
	}
}
