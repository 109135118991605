import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import * as fs from "file-saver";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { MatButton } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { finalize, first } from "rxjs";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { ProtocolService } from "../../../services/protocol.service";

@Component({
	selector: "app-protocol-download",
	templateUrl: "./protocol-download.component.html",
	styleUrls: ["./protocol-download.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		MatRadioButton,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class ProtocolDownloadComponent {
	selectedVersion = "published";

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private protocolService: ProtocolService,
		private manager: MitpManagerService
	) {}

	download(format: string) {
		this.manager.showLoading(true);
		this.protocolService
			.downloadProtocol(this.protocol.id, this.selectedVersion, format)
			.pipe(
				first(),
				finalize(() => this.manager.showLoading(false))
			)
			.subscribe((response) => {
				this.manager.showLoading(false);
				const file = new Blob([response.body], {
					type: `application/${format}`,
				});
				fs.saveAs(file, `${this.protocol.label}.${format == "word" ? "docx" : format}`);
			});
	}
}
