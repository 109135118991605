import { Component, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MatDialogTitle,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { GuidelineFeed } from "../../../interfaces/guidelines/guideline-feed";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { OrderByPipe } from "ngx-pipes";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { NgFor, NgIf, DatePipe } from "@angular/common";
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
	MatExpansionPanelDescription,
} from "@angular/material/expansion";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { AthenaService } from "../../../services/athena.service";

@Component({
	selector: "app-guidelines",
	templateUrl: "./guidelines.component.html",
	styleUrls: ["./guidelines.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatAccordion,
		NgFor,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatIcon,
		MatExpansionPanelDescription,
		NgIf,
		MatIconButton,
		MatTooltip,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		DatePipe,
		NgxTolgeeModule,
	],
})
export class GuidelinesComponent implements OnInit {
	guidelines: GuidelineFeed[];

	constructor(
		private translate: TranslateService,
		private toastr: ToastrService,
		private athena: AthenaService,
		private oPipe: OrderByPipe,
		private dialogRef: MatDialogRef<GuidelinesComponent>,
		public manager: MitpManagerService
	) {}

	ngOnInit(): void {
		this.get();
	}

	get(): void {
		this.athena.getGuidelines().subscribe((guidelines) => {
			this.guidelines = this.oPipe.transform(guidelines, "provider");
			this.dialogRef.updateSize();
		});
	}

	subscribe(guideline: GuidelineFeed): void {
		this.athena.subscribeGuideline(guideline).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translate.instant("guidelines.guidelines.subscription.success.message"));
				guideline.is_subscribed = true;
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}

	unsubscribe(guideline: GuidelineFeed): void {
		this.athena.unsubscribeGuideline(guideline).subscribe((response) => {
			if (response.status === 204) {
				this.toastr.success(this.translate.instant("guidelines.guidelines.unsubscription.success.message"));
				guideline.is_subscribed = false;
			} else {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
			}
		});
	}

	checkSubscription(guideline: GuidelineFeed): void {
		this.athena.isSubscribedToGuideline(guideline).subscribe((e) => (guideline.is_subscribed = e.result));
	}
}
