<button
	mat-icon-button
	[matMenuTriggerFor]="menu">
	<mat-icon>account_circle</mat-icon>
</button>
<mat-menu #menu="matMenu">
	<button
		mat-menu-item
		(click)="navigateToPreferences()">
		<mat-icon>person</mat-icon>
		{{ "APP_SETTINGS_USER" | translate }}
	</button>
	<button
		mat-menu-item
		(click)="navigateToAbout()">
		<mat-icon>info</mat-icon>
		{{ "APP_ABOUT" | translate }}
	</button>
	<mat-divider></mat-divider>
	<button
		mat-menu-item
		(click)="authService.logout()">
		<mat-icon>logout</mat-icon>
		{{ "APP_DISCONNECTION" | translate }}
	</button>
</mat-menu>
