<div
	mat-dialog-content
	class="flex flex-col">
	<p>{{ "PROTOCOL-DOWNLOAD_CHOOSE_VERSION_FORMAT_PROTOCOL" | translate }}</p>
	<mat-radio-group
		[(ngModel)]="selectedVersion"
		class="flex flex-col">
		<mat-radio-button value="draft"
			>{{ "PROTOCOL-DOWNLOAD_DRAFT" | translate }}
			<i>{{ "PROTOCOL-DOWNLOAD_NON_PUBLISHED" | translate }}</i></mat-radio-button
		>
		<mat-radio-button value="published"
			>{{ "PROTOCOL-DOWNLOAD_ONLINE" | translate }}
			<i>{{ "PROTOCOL-DOWNLOAD_PUBLISHED" | translate }}</i></mat-radio-button
		>
	</mat-radio-group>
	<div class="mt-8 flex flex-row justify-around">
		<img
			src="assets/images/pdf.png"
			alt="{{ 'PROTOCOL-DOWNLOAD_IMG_PDF_FORMAT' | translate }}"
			(click)="download('pdf')" />
		<img
			src="assets/images/word.png"
			alt="{{ 'PROTOCOL-DOWNLOAD_IMG_WORD_FORMAT' | translate }}"
			(click)="download('word')" />
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
