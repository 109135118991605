import { NgModule } from "@angular/core";
import { registerPlugin } from "ngx-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";

registerPlugin(
	FilePondPluginFileValidateType,
	FilePondPluginFileValidateSize,
	FilePondPluginImagePreview,
	FilePondPluginImageResize,
	FilePondPluginMediaPreview
);

export function tokenGetter() {
	return sessionStorage.getItem("access_token");
}

@NgModule()
export class AppModule {}
