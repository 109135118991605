<div mat-dialog-title>{{ "protocol.edit.tools.integration.videos.label" | translate }}</div>
<div mat-dialog-content>
	<div class="flex flex-col">
		<p>{{ "protocol.edit.tools.integration.videos.instructions" | translate }}</p>
		<mat-form-field appearance="outline">
			<mat-label>{{ "core.search" | translate }}</mat-label>
			<input
				type="text"
				matInput
				[formControl]="m1Control"
				[matAutocomplete]="auto" />
			<mat-autocomplete
				#auto="matAutocomplete"
				[displayWith]="displayFn">
				@for (item of filteredVideos | async; track item.id; let isLast = $last) {
					<mat-option
						[value]="item"
						(onSelectionChange)="selectVideo($event)">
						<div class="flex flex-col">
							<span>{{ item.label }}</span>
							<span
								class="text-xs italic"
								*ngIf="userService.getInstituteId() !== item.institute.id"
								>{{ item.institute.label }}</span
							>
						</div>
					</mat-option>
					<mat-divider *ngIf="!isLast"></mat-divider>
				}
			</mat-autocomplete>
			<mat-icon matIconPrefix>videocam</mat-icon>
		</mat-form-field>
	</div>
</div>
<div
	mat-dialog-actions
	align="end">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="insertVideo()">
		{{ "core.insert" | translate }}
	</button>
</div>
