<div class="flex flex-col gap-y-4 p-4">
	<p class="text-3xl text-blue-500 py-2">{{ "core.hello" | translate }} {{ userService.getFirstName() }},</p>
	<div class="flex flex-row gap-x-4">
		<mat-card>
			<mat-card-title class="text-center">
				<img
					ngSrc="assets/images/medical-team.png"
					[matTooltip]="'home.your-managers.label' | translate"
					alt="managers"
					height="44"
					width="44" />
			</mat-card-title>
			<mat-card-content class="flex flex-col text-center">
				@for (manager of managers; track manager) {
					<div
						class="cursor-pointer"
						(click)="triggerEmail(manager)">
						{{ manager | name }}
					</div>
				}
			</mat-card-content>
		</mat-card>
		<mat-card class="flex flex-1">
			<mat-card-title class="text-center">
				<img
					ngSrc="assets/images/online-library.png"
					alt="managers"
					height="44"
					width="44" />
			</mat-card-title>
			<mat-card-content>
				<div class="flex flex-row justify-around">
					<img
						class="cursor-pointer"
						ngSrc="assets/images/robot/chatbot.png"
						(click)="openJanus()"
						height="64"
						width="64"
						alt="janus" />
					<img
						class="cursor-pointer"
						ngSrc="assets/images/utd-icon.png"
						(click)="openUtD()"
						height="64"
						width="64"
						alt="uptodate" />
					<img
						class="cursor-pointer"
						ngSrc="assets/images/vidal-logo.png"
						(click)="openVidalRecos()"
						height="64"
						width="64"
						alt="vidal" />
					<img
						class="cursor-pointer"
						ngSrc="assets/images/logo/swisspeddose.png"
						(click)="openSPD()"
						height="64"
						width="64"
						alt="swisspeddose" />
					<img
						class="cursor-pointer"
						ngSrc="assets/images/journal.png"
						(click)="openResearch()"
						height="64"
						width="64"
						alt="research" />
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="flex flex-row gap-x-4">
		<div class="flex flex-col flex-1 gap-y-4">
			<!--			<mat-card>-->
			<!--				<mat-card-title>{{ "content.label" | translate }}</mat-card-title>-->
			<!--				<mat-card-content>-->
			<!--					<mat-tab-group>-->
			<!--						<mat-tab>-->
			<!--							<ng-template mat-tab-label>-->
			<!--								<mat-icon>schedule</mat-icon>-->
			<!--								<div class="px-2">{{ "protocols.recent.label" | translate }}</div>-->
			<!--							</ng-template>-->
			<!--							<div>Recent</div>-->
			<!--						</mat-tab>-->
			<!--						<mat-tab>-->
			<!--							<ng-template mat-tab-label>-->
			<!--								<mat-icon>favorite</mat-icon>-->
			<!--								<div class="px-2">{{ "protocols.favorites.label" | translate }}</div>-->
			<!--							</ng-template>-->
			<!--							<div>Favorite</div>-->
			<!--						</mat-tab>-->
			<!--					</mat-tab-group>-->
			<!--				</mat-card-content>-->
			<!--			</mat-card>-->
			<mat-card>
				<mat-card-title>{{ "tools.label" | translate }}</mat-card-title>
				<mat-card-content>
					<app-tools></app-tools>
				</mat-card-content>
			</mat-card>
		</div>
		<mat-card class="flex flex-1 flex-col">
			<mat-card-title [matTooltip]="'content.last.tooltip' | translate">
				{{ "content.last.label" | translate }}
			</mat-card-title>
			<mat-card-content>
				<div class="flex flex-row justify-end">
					<mat-checkbox
						[(ngModel)]="limitToDepartment"
						(change)="refreshRecentContent($event)">
						Contenu limité à mon service
					</mat-checkbox>
				</div>
				<table
					mat-table
					[dataSource]="dataSource"
					matSort>
					<ng-container matColumnDef="type">
						<th
							mat-header-cell
							*matHeaderCellDef></th>
						<td
							mat-cell
							*matCellDef="let element">
							<div class="flex flex-row gap-x-2">
								<mat-icon>{{ getIcon(element) }}</mat-icon>
								<mat-icon
									*ngIf="isNewContent(element)"
									class="text-green-600">
									new_releases
								</mat-icon>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="label">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header>
							{{ "EDIT-PROTOCOL_TITLE" | translate }}
						</th>
						<td
							mat-cell
							class="cursor-pointer"
							*matCellDef="let element"
							(click)="openContent(element)">
							<div class="flex flex-col">
								<div class="text-blue-800">{{ element.label }}</div>
								<div class="text-xs italic">{{ element.tags | tagsString }}</div>
							</div>
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="displayedColumns"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedColumns"></tr>
				</table>
				<mat-paginator pageSize="10"></mat-paginator>
			</mat-card-content>
		</mat-card>
	</div>
</div>
