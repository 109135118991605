<div mat-dialog-title>{{ "drugs.booklet.search" | translate }}</div>
<div mat-dialog-content>
	<div class="flex flex-col gap-y-2">
		<mat-checkbox
			class="py-2"
			[(ngModel)]="limitToBooklet"
			>{{ "drugs.search.limit-to-booklet" | translate }}
		</mat-checkbox>
		<app-drug-search
			[limitToBooklet]="limitToBooklet"
			(selectedDrug)="updateDrug($event)"></app-drug-search>
		<mat-form-field
			*ngIf="selectedDrug"
			appearance="outline">
			<input
				matInput
				[(ngModel)]="showText" />
			<mat-icon matIconPrefix>label</mat-icon>
		</mat-form-field>
		<div class="flex flex-row items-center">
			<div
				class="grid grid-cols-2 gap-2 w-[80%]"
				*ngIf="selectedDrug">
				<div class="text-end font-bold text-sm">Médicament :</div>
				<div class="text-sm">{{ selectedDrug.label }}</div>
				<div class="text-end font-bold text-sm">Laboratoire :</div>
				<div class="text-sm">{{ selectedDrug.laboratory.label }}</div>
				<div class="text-end font-bold text-sm">DCI :</div>
				<div class="text-sm">{{ selectedDrug.dci }}</div>
				<div class="text-end font-bold text-sm">ATC :</div>
				<div class="text-sm">{{ selectedDrug.atc }}</div>
				<div class="text-end font-bold text-sm">UCD :</div>
				<div class="text-sm">{{ selectedDrug.identifiers["ucd"] }}</div>
			</div>
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		(click)="insert()"
		mat-raised-button
		[disabled]="!selectedDrug"
		color="primary">
		{{ "core.insert" | translate }}
	</button>
</div>
