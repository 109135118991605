import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef } from "@angular/material/dialog";
import { Institute } from "../../../../interfaces/institute";
import { IZone } from "../../../../interfaces/protocol/izone";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatCard, MatCardContent } from "@angular/material/card";
import { NgFor, NgIf } from "@angular/common";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { ProtocolService } from "../../../../services/protocol.service";

@Component({
	selector: "app-protocol-share-search-results",
	templateUrl: "./protocol-share-search-results.component.html",
	styleUrls: ["./protocol-share-search-results.component.scss"],
	standalone: true,
	imports: [
		NgFor,
		MatCard,
		MatCardContent,
		MatIcon,
		NgIf,
		MatIconButton,
		MatDialogActions,
		MatProgressSpinner,
		MatButton,
		NgxTolgeeModule,
	],
})
export class ProtocolShareSearchResultsComponent {
	isLoading = false;
	output = { institutes: [], zones: [] };

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private protocolService: ProtocolService,
		private dialogRef: MatDialogRef<ProtocolShareSearchResultsComponent>
	) {}

	shareWithInstitute(item: Institute) {
		if (this.data.protocol === null) {
			this.output["institutes"].push(item);
			item["added"] = true;
		} else {
			this.isLoading = true;
			this.protocolService.addInstituteToProtocolShare(this.data.protocol.id, item.id).subscribe({
				next: (e) => {
					item["added"] = e.status === 204;
					this.isLoading = false;
				},
				error: () => (this.isLoading = false),
			});
		}
	}

	shareWithZone(zone: IZone) {
		if (this.data.protocol === null) {
			this.output["zones"].push(zone);
			zone["added"] = true;
		} else {
			this.isLoading = true;
			this.protocolService.addShareProtocolWithZone(this.data.protocol.id, zone.id).subscribe({
				next: (e) => {
					zone["added"] = e.status === 204;
					this.isLoading = false;
				},
				error: () => (this.isLoading = false),
			});
		}
	}

	close() {
		if (this.data.protocol === null) {
			this.output.institutes.forEach((e) => delete e["added"]);
			this.output.zones.forEach((e) => delete e["added"]);
			this.dialogRef.close(this.output);
		} else {
			const result = this.data.result.institutes.filter((e) => e["added"]);
			result.forEach((e) => delete e["added"]);
			const zoneResult = this.data.result.zones.filter((e) => e["added"]);
			zoneResult.forEach((e) => delete e["added"]);
			this.dialogRef.close({ institutes: result, zones: zoneResult });
		}
	}
}
