<div mat-dialog-content>
	<div class="flex flex-col gap-y-2">
		<file-pond
			class="flex-1"
			style="z-index: 15"
			#myPond
			[options]="pondOptions"
			(onupdatefiles)="loadPdf()"></file-pond>
		<div
			class="flex flex-col items-center"
			#parent>
			<pdf-viewer
				[src]="pdfSrc"
				[render-text]="false"
				[original-size]="false"
				[show-all]="false"
				[(page)]="currentPage"
				(after-load-complete)="onLoadComplete($event)"
				style="width: 500px; height: 800px"></pdf-viewer>
			<canvas
				#selectionCanvas
				class="selection-canvas"
				(mousedown)="startSelection($event)"
				(mousemove)="drawSelection($event)"
				(mouseup)="endSelection()"></canvas>
		</div>
	</div>
</div>
<div mat-dialog-actions>
	<div>
		<button
			mat-button
			(click)="previousPage()"
			[disabled]="!isPreviousPage()">
			<<
		</button>
		<button
			mat-button
			(click)="nextPage()"
			[disabled]="!isNextPage()">
			>>
		</button>
	</div>
	<div class="flex-1"></div>
	<div>
		<button
			mat-button
			mat-dialog-close>
			{{ "core.close" | translate }}
		</button>
		<mat-spinner
			color="primary"
			diameter="40"
			*ngIf="isLoading"></mat-spinner>
		<button
			*ngIf="!isLoading"
			mat-raised-button
			color="primary"
			(click)="extract()">
			{{ "core.extract" | translate }}
		</button>
	</div>
</div>
