import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Language } from "../../../interfaces/language";
import { environment } from "../../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ViewLegalDocumentComponent } from "../../legal/view-legal-document/view-legal-document.component";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { ELanguage } from "../../../enums/ELanguage";
import { SignupComponent } from "../signup/signup.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgForOf, NgIf } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { SafeHtmlPipe } from "../../../pipes/safe-html.pipe";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "app-auth",
	standalone: true,
	templateUrl: "./auth.component.html",
	styleUrls: ["./auth.component.scss"],
	imports: [
		NgIf,
		MatButtonModule,
		MatSelectModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatIconModule,
		NgForOf,
		FormsModule,
		MatInput,
		MatProgressSpinner,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		SafeHtmlPipe,
		NgxTolgeeModule,
	],
})
export class AuthComponent implements OnInit {
	authForm: FormGroup;
	appVersion = environment.appVersion;
	hide = true;
	returnUrl: string;
	isLoading = false;
	langs: Language[] = [
		{
			id: 1,
			language: "Français",
			iso: "fr",
			i18n: ELanguage.FRENCH,
		},
		{
			id: 2,
			language: "English",
			iso: "us",
			i18n: ELanguage.ENGLISH,
		},
		{
			id: 3,
			language: "Tiếng Việt",
			iso: "vn",
			i18n: ELanguage.VIETNAMESE,
		},
	];
	lang: Language;

	constructor(
		private authService: AuthService,
		private userService: UserService,
		private router: Router,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		public translate: TranslateService,
		private dialog: MatDialog,
		private manager: MitpManagerService,
		private fb: FormBuilder
	) {
		this.lang = this.langs[0];
		this.authForm = this.fb.group({
			emailAddress: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required]],
		});
		document.onclick = (e) => {
			const event = e || window.event;
			const element = event.target || event.srcElement;
			if (element instanceof HTMLElement) {
				if (element.tagName === "A") {
					const link = element.getAttribute("href");
					if (link.startsWith("mitp://")) {
						this.handleLink(link);
						return false;
					}
					return true;
				} else {
					return true;
				}
			} else {
				return true;
			}
		};
	}

	handleLink(value: string): void {
		const href = value.substring("mitp://".length, value.length);
		if (href.startsWith("legal/")) {
			const doc = href.substring("legal/".length, href.length);
			this.dialog.open(ViewLegalDocumentComponent, {
				data: doc,
				maxWidth: "70%",
				maxHeight: "70%",
				autoFocus: false,
			});
		}
	}

	ngOnInit(): void {
		this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
		if (this.route.snapshot.queryParams["token"] != undefined) {
			this.authService.loginByToken(this.route.snapshot.queryParams["token"]).subscribe(() => {
				this.isLoading = false;
				this.router.navigateByUrl(this.returnUrl);
			});
		}
		if (
			this.route.snapshot.queryParams["email"] != undefined &&
			this.route.snapshot.queryParams["password"] != undefined
		) {
			this.isLoading = true;
			const emailAddress = this.route.snapshot.queryParams["email"];
			const password = this.route.snapshot.queryParams["password"];
			this.authService.login(emailAddress, password).add(() => {
				this.isLoading = false;
				this.router.navigateByUrl(this.returnUrl);
			});
		} else if (this.route.snapshot.queryParams["email"] != undefined) {
			const emailAddress = this.route.snapshot.queryParams["email"];
			this.authForm.get("emailAddress").setValue(emailAddress);
		}

		if (this.route.snapshot.queryParams["reset-password"] != undefined) {
			const token = this.route.snapshot.queryParams["reset-password"];
			this.dialog
				.open(ResetPasswordComponent, {
					minWidth: "600px",
					disableClose: true,
					data: token,
				})
				.afterClosed()
				.subscribe(() => {
					const params = { ...this.route.snapshot.queryParams };
					delete params["reset-password"];
					this.router.navigate([], { queryParams: params });
				});
		}

		if (this.route.snapshot.queryParams["psc"] != undefined) {
			const code = this.route.snapshot.queryParams["code"];
			const state = this.route.snapshot.queryParams["state"];
			const session_state = this.route.snapshot.queryParams["session_state"];

			this.isLoading = true;
			this.authService
				.authorizePSC(
					code,
					state,
					session_state,
					this.manager.readFromTemp("session"),
					this.manager.readFromTemp("nonce")
				)
				.subscribe({
					next: (e) => {
						this.isLoading = false;
						this.authService.loginByToken(e["token"]).subscribe(() => {
							this.router.navigateByUrl(this.returnUrl);
						});
						this.manager.removeFromTemp("session");
						this.manager.removeFromTemp("nonce");
					},
					error: () => {
						this.isLoading = false;
						this.manager.removeFromTemp("session");
						this.manager.removeFromTemp("nonce");
					},
				});
		}
	}

	login() {
		if (this.authForm.invalid) {
			this.toastr.error("Merci de renseigner vos identifiants pour vous connecter !");
			return;
		}
		this.isLoading = true;
		const emailAddress = this.authForm.get("emailAddress").value;
		const password = this.authForm.get("password").value;
		this.authService.login(emailAddress, password).add(() => {
			this.isLoading = false;
			this.router.navigateByUrl(this.returnUrl);
		});
	}

	resetPwd() {
		const emailAddress = this.authForm.get("emailAddress").value;
		if (emailAddress == null || emailAddress.trim() == "") {
			this.toastr.error("Merci de renseigner votre adresse mail pour renouveler votre mot de passe !");
			return;
		}
		this.userService.resetPasswordByEmail(emailAddress).subscribe(() => {
			this.toastr.info(
				"Si vous possédez un compte, vous allez recevoir un mail contenant un lien pour modifier votre mot de passe."
			);
			this.authForm.get("emailAddress").setValue("");
		});
	}

	compareLangs(l1: Language, l2: Language): boolean {
		return l1.id === l2.id;
	}

	connectPSC(): void {
		this.isLoading = true;
		this.authService.authenticatePSC().subscribe({
			next: (e) => {
				this.manager.saveToTemp("session", e["session"]);
				this.manager.saveToTemp("nonce", e["nonce"]);
				window.location.href = e["url"];
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	signup(): void {
		this.dialog.open(SignupComponent, {
			disableClose: true,
			minWidth: "600px",
		});
	}

	connectByIp(): void {
		this.manager.openInfoDialog(this.translate.instant("core.message.function-subscription-required"));
	}

	isDesktop(): boolean {
		return window.screen.width >= 1024;
	}
}
