import { Institute } from "../../interfaces/institute";
import { IZone } from "./izone";

export interface ISharing {
	id: number;
	enabled: boolean;
	mode: number;
	institutes: Institute[];
	zones: IZone[];
}

export enum ESharingMode {
	DISABLED = 0,
	ALL = 1,
	CUSTOM = 2,
}

export enum ERequestType {
	ADD = 0,
	REPLACE = 1,
}

export enum ESharingManagerMode {
	ALL_PROTOCOLS = 0,
	CUSTOM_ALL_TAGS = 1,
	CUSTOM_MIN_TAG = 2,
}
