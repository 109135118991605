<h2 mat-dialog-title>{{ "protocol.share.manager.title" | translate }}</h2>
<div mat-dialog-content>
	<p class="flex flex-row items-center justify-start gap-x-2">
		<mat-icon style="color: #f1c40f">warning</mat-icon>
		<i>{{ "protocol.share.manager.sharing-warning" | translate }}</i>
	</p>
	<mat-horizontal-stepper linear>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "protocol.share.manager.settings.label" | translate }}</ng-template>
				<div class="flex flex-col gap-y-4">
					<mat-checkbox [(ngModel)]="share.enable">{{
						"protocol.share.enable.label" | translate
					}}</mat-checkbox>
					<div class="flex flex-col gap-y-4">
						<div class="flex flex-row gap-x-4">
							<span>{{ "protocol.share.request-type.label" | translate }}</span>
							<mat-radio-group
								class="flex flex-col gap-y-1"
								[(ngModel)]="share.request">
								<mat-radio-button [value]="ERequestType.ADD">{{
									"protocol.share.request-type.add" | translate
								}}</mat-radio-button>
								<mat-radio-button [value]="ERequestType.REPLACE">{{
									"protocol.share.request-type.replace" | translate
								}}</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="flex flex-row gap-x-4">
							<span>{{ "protocol.share.mode.label" | translate }}</span>
							<mat-radio-group
								class="flex flex-col gap-y-1"
								[(ngModel)]="share.mode">
								<mat-radio-button [value]="ESharingMode.DISABLED">{{
									"protocol.share.mode.disabled" | translate
								}}</mat-radio-button>
								<mat-radio-button [value]="ESharingMode.ALL">{{
									"protocol.share.mode.all" | translate
								}}</mat-radio-button>
								<mat-radio-button [value]="ESharingMode.CUSTOM">{{
									"protocol.share.mode.custom" | translate
								}}</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
					<div class="flex flex-row justify-end">
						<button
							mat-button
							mat-dialog-close>
							{{ "core.cancel" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							color="primary">
							{{ "core.next" | translate }}
						</button>
					</div>
				</div>
			</div>
		</mat-step>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "protocols.title.label" | translate }}</ng-template>
				<div class="flex flex-col gap-y-4">
					<div class="flex w-full flex-row gap-x-2">
						<span>{{ "protocols.selected.label" | translate }}</span>
						<mat-radio-group
							class="flex flex-col gap-y-0.5"
							[(ngModel)]="share.protocols">
							<mat-radio-button [value]="ESharingManagerMode.ALL_PROTOCOLS">{{
								"protocols.share.selection.all" | translate
							}}</mat-radio-button>
							<mat-radio-button [value]="ESharingManagerMode.CUSTOM_ALL_TAGS">{{
								"protocols.share.selection.all-tags" | translate
							}}</mat-radio-button>
							<mat-radio-button [value]="ESharingManagerMode.CUSTOM_MIN_TAG">{{
								"protocols.share.selection.some-tags" | translate
							}}</mat-radio-button>
						</mat-radio-group>
					</div>
					<app-tag-filter
						*ngIf="share.protocols !== ESharingManagerMode.ALL_PROTOCOLS"
						placeholder="Tags"
						[(tags)]="share.tags"
						enabledTagCreation="false"></app-tag-filter>
					<div class="flex flex-row justify-end">
						<button
							mat-button
							mat-dialog-close>
							{{ "core.cancel" | translate }}
						</button>
						<button
							mat-button
							matStepperPrevious>
							{{ "core.previous" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							color="primary">
							{{ "core.next" | translate }}
						</button>
					</div>
				</div>
			</div>
		</mat-step>
		<mat-step *ngIf="share.mode === ESharingMode.CUSTOM">
			<div>
				<ng-template matStepLabel>{{ "protocols.share.recipient.label" | translate }}</ng-template>
				<div class="flex w-full flex-col gap-y-4">
					<mat-form-field
						class="w-full"
						appearance="outline">
						<mat-label>{{ "protocols.share.recipient.placeholder" | translate }}</mat-label>
						<mat-icon
							class="mat-icon-span-prefix"
							matPrefix
							>share</mat-icon
						>
						<input
							matInput
							[(ngModel)]="searchKeyword"
							(keyup.enter)="search(searchKeyword)" />
						<mat-icon
							matSuffix
							color="primary"
							(click)="search(searchKeyword)"
							style="cursor: pointer"
							*ngIf="!isLoading2"
							>search</mat-icon
						>
						<mat-spinner
							color="primary"
							diameter="24"
							*ngIf="isLoading2"></mat-spinner>
					</mat-form-field>
					<div class="flex flex-row gap-x-4">
						<mat-card
							appearance="outlined"
							class="flex flex-1 flex-col">
							<mat-card-title class="flex flex-row justify-center">
								<div class="flex flex-row justify-center">
									<mat-icon>local_hospital</mat-icon>
								</div>
							</mat-card-title>
							<div
								*ngFor="let item of share.institutes"
								class="flex flex-row items-center gap-x-2">
								<label class="flex-1">{{ item.label }}</label>
								<button
									mat-icon-button
									(click)="removeShareInstitute(item)">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</mat-card>
						<mat-card
							appearance="outlined"
							class="flex flex-1 flex-col">
							<mat-card-title class="flex flex-row justify-center">
								<div class="flex flex-row justify-center">
									<mat-icon>place</mat-icon>
								</div>
							</mat-card-title>
							<div
								*ngFor="let item of share.zones"
								class="flex flex-row items-center gap-x-2">
								<label class="flex-1">{{ item.label }}</label>
								<button
									mat-icon-button
									(click)="removeShareZone(item)">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</mat-card>
					</div>
					<div class="flex flex-row justify-end">
						<button
							mat-button
							mat-dialog-close>
							{{ "core.cancel" | translate }}
						</button>
						<button
							mat-button
							matStepperPrevious>
							{{ "core.previous" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							color="primary">
							{{ "core.next" | translate }}
						</button>
					</div>
				</div>
			</div>
		</mat-step>
		<mat-step class="w-full">
			<ng-template matStepLabel>{{ "core.resume" | translate }}</ng-template>
			<div class="flex flex-col">
				<div class="flex flex-row items-center gap-x-2">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ getSharingEnableLabel() }}</b></span
					>
				</div>
				<div class="flex flex-row items-center gap-x-2">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "protocol.share.mode.label" | translate }} </b>{{ getModeLabel() }}</span
					>
				</div>
				<div class="flex flex-row items-center gap-x-2">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "protocol.share.request-type.label" | translate }} </b>{{ getRequestTypeLabel() }}</span
					>
				</div>
				<div class="flex flex-row items-center gap-x-2">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "protocols.selected.label" | translate }} </b>{{ getSharedProtocolsCount() }}/{{
							data.protocols.length
						}}</span
					>
				</div>
				<div
					class="flex flex-row items-center gap-x-2"
					*ngIf="share.protocols !== ESharingManagerMode.ALL_PROTOCOLS">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "tags.title.label" | translate }} : </b>{{ getArrayJoinedLabel(share.tags) }}</span
					>
				</div>
				<div
					class="flex flex-row items-center gap-x-2"
					*ngIf="share.mode === ESharingMode.CUSTOM">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "institutes.title.label" | translate }} : </b
						>{{ getArrayJoinedLabel(share.institutes) }}</span
					>
				</div>
				<div
					class="flex flex-row items-center gap-x-2"
					*ngIf="share.mode === ESharingMode.CUSTOM">
					<mat-icon style="color: green">check</mat-icon>
					<span
						><b>{{ "zones.title.label" | translate }} : </b>{{ getArrayJoinedLabel(share.zones) }}</span
					>
				</div>
			</div>
			<div class="flex w-full flex-row justify-end">
				<button
					mat-button
					mat-dialog-close>
					{{ "core.cancel" | translate }}
				</button>
				<button
					mat-button
					matStepperPrevious>
					{{ "core.previous" | translate }}
				</button>
				<mat-spinner
					*ngIf="isLoading"
					color="primary"
					[diameter]="40"></mat-spinner>
				<button
					mat-raised-button
					color="primary"
					*ngIf="!isLoading"
					(click)="apply()">
					{{ "core.apply" | translate }}
				</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>
