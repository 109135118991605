<div mat-dialog-title>{{ "protocols.attachments.title" | translate }}</div>
<div
	mat-dialog-content
	class="flex flex-col"
	[formGroup]="formGroup">
	<file-pond
		class="flex-1"
		#myPond
		(onaddfile)="onAddFile($event)"
		[options]="pondOptions"></file-pond>
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "protocols.attachments.create-dialog.label" | translate }}</mat-label>
		<input
			matInput
			required
			formControlName="label"
			maxlength="255"
			minlength="1" />
		<mat-hint align="end">{{ formGroup.get("label").value.length }} / 255</mat-hint>
		<mat-icon matIconPrefix>label</mat-icon>
	</mat-form-field>
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "protocols.attachments.create-dialog.notes" | translate }}</mat-label>
		<input
			matInput
			formControlName="notes" />
		<mat-icon matIconPrefix>notes</mat-icon>
	</mat-form-field>
	<mat-checkbox formControlName="enableSharing">{{
		"protocols.attachments.create-dialog.enable-sharing" | translate
	}}</mat-checkbox>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()"
		[disabled]="!formGroup.valid || !isFileSelected()">
		{{ "core.add" | translate }}
	</button>
</div>
