<div
	class="flex h-full w-full flex-row items-center justify-start"
	id="authRootDiv">
	<div
		class="flotter-container flex-2/3 h-full"
		*ngIf="isDesktop()">
		<img
			class="floatter h-full w-full object-contain"
			src="../../../../assets/images/telemedicine.webp"
			alt="hospital" />
	</div>
	<div
		class="lg:flex-1/3 flex h-full flex-col justify-between px-2"
		style="padding: 0 16px 0 16px">
		<div
			class="flex flex-row items-center justify-between"
			style="margin-top: 16px">
			<div>
				<button
					mat-raised-button
					color="primary"
					(click)="signup()">
					{{ "register.label" | translate }}
				</button>
			</div>
			<div class="flex w-1/3">
				<mat-select
					[(ngModel)]="lang"
					[compareWith]="compareLangs"
					(ngModelChange)="translate.changeLanguage(lang.i18n)">
					<mat-select-trigger>
						<span
							class="fi fi-{{ lang.iso }}"
							style="margin-right: 16px"></span
						>{{ lang.language }}
					</mat-select-trigger>
					<mat-option
						*ngFor="let lang of langs"
						[value]="lang">
						<div class="flex flex-row">
							<div
								class="fi fi-{{ lang.iso }}"
								style="margin-right: 16px"></div>
							<div>{{ lang.language }}</div>
						</div>
					</mat-option>
				</mat-select>
			</div>
		</div>
		<div class="flex flex-col items-center justify-center">
			<img
				src="../../../../assets/images/mitp.webp"
				class="mitp-logo"
				alt="MedInThePocket logo" />
			<label style="text-align: center; font-size: 2em; margin-top: 8px">MedInThePocket</label>
		</div>
		<div
			class="flex flex-col"
			[formGroup]="authForm">
			<mat-form-field appearance="outline">
				<mat-label>{{ "AUTH_EMAIL" | translate }}</mat-label>
				<input
					matInput
					formControlName="emailAddress" />
				<mat-icon matIconPrefix>alternate_email</mat-icon>
				<mat-error *ngIf="authForm.get('emailAddress').errors?.email">
					{{ "auth.username.error.email" | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>{{ "AUTH_PASSWORD" | translate }}</mat-label>
				<input
					matInput
					[type]="hide ? 'password' : 'text'"
					formControlName="password"
					(keyup.enter)="login()" />
				<mat-icon matIconPrefix>vpn_key</mat-icon>
				<button
					mat-icon-button
					matSuffix
					(click)="hide = !hide"
					[attr.aria-label]="'Hide password'"
					[attr.aria-pressed]="hide">
					<mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
				</button>
			</mat-form-field>
			<div class="flex flex-col justify-center gap-y-4">
				<p
					style="font-size: 0.8em; text-align: center"
					[innerHTML]="'login.legalmessage' | translate | safeHtml"></p>
				<div class="flex flex-row justify-center">
					<mat-spinner
						*ngIf="isLoading"
						color="primary"
						[diameter]="40"></mat-spinner>
				</div>
				<div class="flex flex-col items-center gap-y-8">
					<div
						class="flex w-full"
						*ngIf="!isLoading">
						<button
							mat-raised-button
							color="primary"
							(click)="login()"
							class="split-button-left flex flex-1">
							<mat-icon>login</mat-icon>
						</button>
						<button
							mat-raised-button
							color="primary"
							class="split-button-right"
							[matMenuTriggerFor]="menu">
							<mat-icon>expand_more</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button
								(click)="connectByIp()"
								mat-menu-item>
								{{ "auth.connect.by-ip.label" | translate }}
							</button>
						</mat-menu>
					</div>
					<div
						class="separator w-full"
						*ngIf="!isLoading">
						ou
					</div>
					<div>
						<button
							mat-button
							*ngIf="!isLoading"
							color="primary"
							(click)="connectPSC()">
							<img
								src="../../../../assets/PSC_Button.webp"
								alt="connect with Pro Santé Connect"
								class="psc-button" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div style="text-align: center">
			<div
				style="cursor: pointer"
				(click)="resetPwd()">
				{{ "AUTH_FORGOTTEN_PASSWORD" | translate }}
			</div>
		</div>
		<div style="text-align: center">v{{ appVersion }}</div>
	</div>
</div>
