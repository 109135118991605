import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { NavigationSpecialtyItem } from "../../../interfaces/navigation-specialty-item";
import { ETagsSelectionMode } from "../../../enums/etags-selection-mode";
import { MatButton } from "@angular/material/button";
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { NgIf } from "@angular/common";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { NavigationSpecialtyService } from "../../../services/navigation-specialty.service";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { TagsStringPipe } from "../../../pipes/tags-string.pipe";

@Component({
	selector: "app-protocol-tags",
	templateUrl: "./protocol-tags.component.html",
	styleUrls: ["./protocol-tags.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		TagFilterComponent,
		NgIf,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
		TagsStringPipe,
	],
})
export class ProtocolTagsComponent implements OnInit {
	isLoading: boolean = false;
	items: NavigationSpecialtyItem[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private navigationSpecialtyService: NavigationSpecialtyService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.navigationSpecialtyService.getMyNavigationItems().subscribe((items) => {
			this.items = items;
		});
	}

	isOfflineAvailable(): boolean {
		return this.protocol.tags.filter((e) => e.section).length > 0;
	}

	getMode(value: number): string {
		switch (value) {
			case ETagsSelectionMode.AND:
				return this.translate.instant("protocols.tags.selection-mode.and");
			case ETagsSelectionMode.OR:
				return this.translate.instant("protocols.tags.selection-mode.or");
			default:
				return this.translate.instant("protocols.tags.selection-mode.none");
		}
	}
}
