<div>
	<div class="flex flex-row items-center justify-between m-4">
		<h1>{{ "protocol.tools.diagram.chart.label" | translate }}</h1>
		<button
			mat-icon-button
			(click)="help()">
			<mat-icon>help_center</mat-icon>
		</button>
	</div>
</div>
<p></p>
<div mat-dialog-content>
	<div class="flex flex-col">
		<div class="flex flex-row justify-between">
			<mat-radio-group
				[(ngModel)]="type"
				class="flex flex-row"
				(ngModelChange)="updateDiagram()">
				<mat-radio-button value="TD"
					>{{ "protocol.tools.diagram.chart.direction.vertical" | translate }}
				</mat-radio-button>
				<mat-radio-button value="LR"
					>{{ "protocol.tools.diagram.chart.direction.horizontal" | translate }}
				</mat-radio-button>
			</mat-radio-group>
			<div class="flex flex-row items-center gap-x-2">
				<button
					[disabled]="errorMarkdown"
					mat-stroked-button
					[matMenuTriggerFor]="menu">
					{{ "core.export" | translate }}
					<mat-icon>download</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="downloadImage()">
						PNG
					</button>
				</mat-menu>
				<button
					mat-icon-button
					[matTooltip]="
						(errorMarkdown
							? 'protocol.tools.diagram.chart.generated.error'
							: 'protocol.tools.diagram.chart.generated.success'
						) | translate
					">
					<mat-icon [style.color]="errorMarkdown ? 'red' : 'green'"
						>{{ errorMarkdown ? "error" : "check_circle" }}
					</mat-icon>
				</button>
			</div>
		</div>
		<p></p>
		<div class="flex flex-row gap-x-2 h-[60ch]">
			<div class="flex flex-1">
				<mat-form-field
					class="w-full"
					appearance="outline">
					<mat-label>{{ "protocol.tools.diagram.chart.label" | translate }}</mat-label>
					<textarea
						matInput
						cdkTextareaAutosize
						[(ngModel)]="markdown"
						(input)="updateDiagram()"></textarea>
				</mat-form-field>
			</div>
			<div class="flex flex-1 overflow-auto">
				<div
					class="flex w-full justify-center"
					#mermaid></div>
			</div>
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="create()">
		{{ (data.markdown ? "core.save" : "core.create") | translate }}
	</button>
</div>
