import { Component, OnInit } from "@angular/core";
import {
	AbstractControlOptions,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { PublicUser } from "../../../interfaces/users/public-user";
import { Job } from "../../../interfaces/job";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatInput } from "@angular/material/input";
import { NgForOf, NgIf } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { UserService } from "../../../services/user.service";
import { JobService } from "../../../services/job.service";

@Component({
	selector: "app-signup",
	standalone: true,
	templateUrl: "./signup.component.html",
	styleUrls: ["./signup.component.scss"],
	imports: [
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatIconModule,
		MatSelectModule,
		MatInput,
		NgForOf,
		MatCheckbox,
		MatButtonModule,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class SignupComponent implements OnInit {
	isLoading = false;
	userFormGroup: UntypedFormGroup;
	jobs: Job[] = [];
	user: PublicUser = {
		departments: [],
		email: "",
		firstname: "",
		job: undefined,
		lastname: "",
		password: "",
		passwordConfirm: "",
		rpps: "",
		code: "",
	};

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private userService: UserService,
		private jobService: JobService,
		private dialogRef: MatDialogRef<SignupComponent>
	) {
		this.userFormGroup = this._formBuilder.group(
			{
				firstname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
				lastname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
				email: ["", [Validators.required, Validators.email]],
				rpps: ["", [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
				job: ["", [Validators.required]],
				code: ["", []],
				password: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
				passwordConfirm: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
				isCGV: [false, [Validators.requiredTrue]],
			},
			{ validator: this.validatePwdAndPwd2 } as AbstractControlOptions
		);
	}

	ngOnInit(): void {
		this.jobService.getJobs().subscribe((jobs) => {
			this.jobs = jobs;
		});
	}

	signup(): void {
		this.isLoading = true;
		const user = this.userFormGroup.getRawValue();
		this.userService.signup(user).subscribe({
			next: (user) => {
				this.isLoading = false;
				this.dialogRef.close(user);
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	compareJobs(job1: Job, job2: Job): boolean {
		return job1.id === job2.id;
	}

	validatePwdAndPwd2(group: UntypedFormGroup) {
		const pwd = group.get("password").value;
		const pwd2 = group.get("passwordConfirm").value;
		return pwd == pwd2 ? null : { notSame: true };
	}
}
