<div></div>
<div mat-dialog-content>
	<div class="flex flex-col gap-y-8">
		<button
			mat-raised-button
			color="primary"
			(click)="newSpecialty()">
			<span>{{ "core.new" | translate }}</span>
			<mat-icon>add</mat-icon>
		</button>
		<div class="grid grid-cols-2 gap-4">
			@for (item of specialties; track item.id) {
				<mat-card
					[matMenuTriggerFor]="menu"
					style="border-left: thick solid {{ item.color }}">
					<div class="flex flex-row items-center gap-x-2 w-96">
						<img
							[src]="item.icon"
							alt="icon"
							class="w-10 h-10" />
						<div class="flex flex-col">
							<span class="font-bold">{{ item.label }}</span>
							<span class="italic text-xs">
								{{ "tags.title.label" | translate }} : {{ item.tags | tagsString }}
							</span>
							<span class="italic text-xs">
								{{ "departments.title" | translate }} : {{ getDepartments(item) }}
							</span>
						</div>
					</div>
				</mat-card>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						[disabled]="item.departments.length === 0"
						(click)="deleteSpecialty(item)">
						<span class="text-red-600">{{ "core.delete" | translate }}</span>
						<mat-icon color="warn">delete</mat-icon>
					</button>
				</mat-menu>
			}
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
