import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
	providedIn: "root",
})
export class AssetsService {
	constructor(
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer
	) {}

	init(): void {
		this.iconRegistry.addSvgIcon(
			"glasses",
			this.sanitizer.bypassSecurityTrustResourceUrl("assets/svg/smart-glasses.svg")
		);
		this.iconRegistry.addSvgIcon(
			"wifi-devices",
			this.sanitizer.bypassSecurityTrustResourceUrl("assets/svg/wifi-devices.svg")
		);
	}
}
