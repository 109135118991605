import { Component, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { AuthorsFilterComponent } from "../../users/authors-filter/authors-filter.component";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatHint, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious } from "@angular/material/stepper";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { ProtocolService } from "../../../services/protocol.service";

@Component({
	selector: "app-create-protocol",
	templateUrl: "./create-protocol.component.html",
	styleUrls: ["./create-protocol.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatStepper,
		MatStep,
		ReactiveFormsModule,
		MatStepLabel,
		MatFormField,
		MatLabel,
		MatInput,
		MatHint,
		MatIcon,
		MatPrefix,
		MatButton,
		MatDialogClose,
		MatStepperNext,
		TagFilterComponent,
		MatStepperPrevious,
		AuthorsFilterComponent,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class CreateProtocolComponent implements OnInit {
	firstFormGroup: UntypedFormGroup;
	protocol = { tags: [], authors: [], approvers: [], managers: [] };
	doneLabel: boolean = false;
	doneTags = false;
	doneAuthors = false;
	doneApprovers: boolean = false;
	doneManagers: boolean = false;
	isLoading: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private protocolService: ProtocolService,
		private dialogRef: MatDialogRef<CreateProtocolComponent>
	) {}

	ngOnInit(): void {
		this.firstFormGroup = this._formBuilder.group({
			firstCtrl: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
		});
	}

	listTags(): string {
		return this.protocol.tags.map((e) => e.label).join(", ");
	}

	listWriters(): string {
		return this.protocol.authors
			.map((e) => `${e.firstname !== "__GROUP__" ? e.firstname : ""} ${e.lastname}`)
			.join(", ");
	}

	listApprovers(): string {
		return this.protocol.approvers
			.map((e) => `${e.firstname !== "__GROUP__" ? e.firstname : ""} ${e.lastname}`)
			.join(", ");
	}

	listManagers(): string {
		return this.protocol.managers
			.map((e) => `${e.firstname !== "__GROUP__" ? e.firstname : ""} ${e.lastname}`)
			.join(", ");
	}

	createProtocol() {
		this.isLoading = true;
		const data = { label: this.firstFormGroup.get("firstCtrl").value };
		if (this.protocol.tags.length > 0) {
			data["tags"] = this.protocol.tags.map((e) => e.id);
		}
		if (this.protocol.authors.length > 0) {
			data["authors"] = this.protocol.authors.map((e) => e.id);
		}
		if (this.protocol.approvers.length > 0) {
			data["approvers"] = this.protocol.approvers.map((e) => e.id);
		}
		if (this.protocol.managers.length > 0) {
			data["managers"] = this.protocol.managers.map((e) => e.id);
		}
		this.protocolService.createProtocol(data).subscribe({
			next: (response) => {
				this.isLoading = false;
				if (response.status == 201) {
					this.dialogRef.close(response.body);
				}
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	isOfflineAvailable(): boolean {
		return this.protocol.tags.filter((e) => e.section).length > 0;
	}
}
