<div mat-dialog-content>
	<p></p>
	<form
		class="flex flex-col"
		[formGroup]="pwdGroup">
		<mat-form-field
			appearance="outline"
			class="w-full">
			<mat-label>{{ "SETTINGS-USER_PASSWORD_NEW" | translate }}</mat-label>
			<input
				matInput
				required
				minlength="8"
				maxlength="64"
				formControlName="newPwd"
				[type]="hide ? 'password' : 'text'" />
			<mat-icon
				style="cursor: pointer"
				matSuffix
				(click)="hide = !hide"
				>{{ hide ? "visibility_off" : "visibility" }}</mat-icon
			>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="w-full">
			<mat-label>{{ "SETTINGS-USER_CONFIRMATION_PASSWORD" | translate }}</mat-label>
			<input
				matInput
				required
				minlength="8"
				maxlength="64"
				formControlName="confirmPwd"
				[type]="hide ? 'password' : 'text'" />
			<mat-icon
				style="cursor: pointer"
				matSuffix
				(click)="hide = !hide"
				>{{ hide ? "visibility_off" : "visibility" }}</mat-icon
			>
		</mat-form-field>
	</form>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close="false">
		Annuler
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="save()"
		*ngIf="!isLoading"
		[disabled]="pwdGroup.invalid">
		Enregistrer
	</button>
</div>
