import { Component } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-subscription-failed",
	templateUrl: "./subscription-failed.component.html",
	styleUrls: ["./subscription-failed.component.scss"],
	standalone: true,
	imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, NgxTolgeeModule],
})
export class SubscriptionFailedComponent {}
