import { Component } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-subscription-successed",
	templateUrl: "./subscription-successed.component.html",
	styleUrls: ["./subscription-successed.component.scss"],
	standalone: true,
	imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, NgxTolgeeModule],
})
export class SubscriptionSuccessedComponent {}
