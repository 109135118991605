<h2 mat-dialog-title>
	{{ "protocol.tools.diagram.selective.title" | translate }}
</h2>
<div
	mat-dialog-content
	class="flex flex-col">
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "protocol.tools.diagram.selective.input.main-question.label" | translate }}</mat-label>
		<input
			matInput
			[(ngModel)]="selectiveAlgoContainer.title"
			maxlength="255"
			minlength="1" />
		<mat-hint align="end">{{ selectiveAlgoContainer.title?.length }} / 255</mat-hint>
		<mat-icon matSuffix>help</mat-icon>
	</mat-form-field>
	<mat-divider style="margin-top: 8px; margin-bottom: 16px"></mat-divider>
	<div class="flex flex-row">
		<div class="flex-1">
			<mat-tree
				[dataSource]="dataSource"
				[treeControl]="treeControl">
				<mat-radio-group [(ngModel)]="selectedItem">
					<mat-tree-node
						*matTreeNodeDef="let node"
						matTreeNodePadding>
						<button
							mat-icon-button
							disabled></button>
						<mat-radio-button [value]="node"></mat-radio-button>
						<div>
							<div>{{ node.node.label }}</div>
							<span class="node-description">{{ node.node.question }}</span>
						</div>
					</mat-tree-node>

					<mat-tree-node
						*matTreeNodeDef="let node; when: hasChild"
						matTreeNodePadding>
						<button
							mat-icon-button
							[attr.aria-label]="'Toggle ' + node.name">
							<mat-icon class="mat-icon-rtl-mirror">
								{{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
							</mat-icon>
						</button>
						<mat-radio-button [value]="node"></mat-radio-button>
						<div>
							<div>{{ node.node.label }}</div>
							<span class="node-description">{{ node.node.question }}</span>
						</div>
					</mat-tree-node>
				</mat-radio-group>
			</mat-tree>
		</div>
		<div class="flex-1">
			<div class="flex flex-col">
				<mat-form-field
					class="w-full"
					appearance="outline"
					*ngIf="selectiveAlgoContainer.algo.length > 0">
					<mat-label>{{ "protocol.tools.diagram.selective.input.text.label" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="item.label"
						maxlength="255"
						minlength="1" />
					<mat-hint align="end">{{ item.label.length }} / 255</mat-hint>
					<mat-icon matSuffix>label</mat-icon>
				</mat-form-field>
				<mat-form-field
					class="w-full"
					appearance="outline"
					*ngIf="selectiveAlgoContainer.algo.length > 0">
					<mat-label>{{ "protocol.tools.diagram.selective.input.description.label" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="item.description"
						maxlength="255"
						minlength="1" />
					<mat-hint align="end">{{ item.description.length }} / 255</mat-hint>
					<mat-icon matSuffix>description</mat-icon>
				</mat-form-field>
				<mat-form-field
					class="w-full"
					appearance="outline">
					<mat-label>{{ "protocol.tools.diagram.selective.input.question.label" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="item.question"
						maxlength="255"
						minlength="1" />
					<mat-hint align="end">{{ item.question.length }} / 255</mat-hint>
					<mat-icon matSuffix>quiz</mat-icon>
				</mat-form-field>
			</div>
			<div class="flex flex-col">
				<button
					mat-raised-button
					color="primary"
					(click)="addRoot()"
					class="w-full"
					*ngIf="selectiveAlgoContainer.algo.length === 0">
					{{ "protocol.tools.diagram.selective.add.root" | translate }}
				</button>
				<div
					class="flex flex-row justify-between"
					style="margin-bottom: 8px"
					*ngIf="selectiveAlgoContainer.algo.length > 0">
					<button
						mat-raised-button
						color="primary"
						(click)="addAsChild()"
						class="flex-1"
						style="margin-right: 8px">
						{{ "protocol.tools.diagram.selective.add.child" | translate }}
					</button>
					<button
						mat-raised-button
						color="primary"
						(click)="addAsBrother()"
						class="flex-1"
						style="margin-left: 8px">
						{{ "protocol.tools.diagram.selective.add.sibling" | translate }}
					</button>
				</div>
				<div
					class="flex flex-row justify-between"
					style="margin-bottom: 8px">
					<button
						mat-button
						color="primary"
						(click)="editNode()"
						class="flex-1"
						style="margin-bottom: 8px">
						{{ "protocol.tools.diagram.selective.edit.button" | translate }}
					</button>
					<button
						mat-button
						color="primary"
						(click)="newNode()"
						class="flex-1"
						style="margin-bottom: 8px">
						{{ "protocol.tools.diagram.selective.add.new" | translate }}
					</button>
				</div>
				<button
					mat-button
					color="warn"
					(click)="deleteNode()"
					class="w-full">
					{{ "protocol.tools.diagram.selective.delete.button" | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	class="flex flex-row justify-between">
	<button
		mat-button
		(click)="restoreAlgo()"
		matTooltip="{{ 'protocol.tools.diagram.selective.get.restore.tooltip' | translate }}">
		<mat-icon>youtube_searched_for</mat-icon>
	</button>
	<div class="flex-1"></div>
	<div class="flex flex-row">
		<button
			mat-button
			mat-dialog-close>
			{{ "core.cancel" | translate }}
		</button>
		<button
			mat-raised-button
			color="primary"
			(click)="apply()"
			*ngIf="data === null">
			{{ "core.add" | translate }}
		</button>
		<button
			mat-raised-button
			color="primary"
			(click)="apply()"
			*ngIf="data !== null">
			{{ "core.edit" | translate }}
		</button>
	</div>
</div>
