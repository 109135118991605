import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { DrugEquivalent } from "../../../entities/pharmacy/drug-equivalent";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-drugs-equivalents",
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatCheckbox,
		FormsModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		NgxTolgeeModule,
	],
	templateUrl: "./protocol-drugs-equivalents.component.html",
	styleUrl: "./protocol-drugs-equivalents.component.scss",
})
export class ProtocolDrugsEquivalentsComponent {
	selectedEquivalents: number[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { equivalents: DrugEquivalent[] },
		private dialogRef: MatDialogRef<ProtocolDrugsEquivalentsComponent>
	) {}

	replace(): void {
		const selectedEquivalents = this.data.equivalents.filter((equivalent) =>
			this.selectedEquivalents.includes(equivalent.id)
		);
		this.dialogRef.close(selectedEquivalents);
	}

	selectEquivalent($event: MatCheckboxChange, equivalent: DrugEquivalent): void {
		if ($event.checked) {
			this.selectedEquivalents.push(equivalent.id);
		} else {
			this.selectedEquivalents = this.selectedEquivalents.filter((id) => id !== equivalent.id);
		}
	}
}
