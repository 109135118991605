<div mat-dialog-title>{{ "signup.title" | translate }}</div>
<form
	[formGroup]="userFormGroup"
	mat-dialog-content
	class="flex-col">
	<p></p>
	<div class="flex w-full gap-x-4">
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.firstname.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="firstname" />
			<mat-icon matIconPrefix>badge</mat-icon>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.lastname.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="lastname" />
			<mat-icon matIconPrefix>badge</mat-icon>
		</mat-form-field>
	</div>
	<div class="flex w-full gap-x-4">
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.rpps.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="rpps" />
			<mat-icon matIconPrefix>filter_8</mat-icon>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.email.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="email" />
			<mat-icon matIconPrefix>alternate_email</mat-icon>
		</mat-form-field>
	</div>
	<div class="flex w-full gap-x-4">
		<mat-form-field
			class="flex-1"
			appearance="outline">
			<mat-label>{{ "job.title" | translate }}</mat-label>
			<mat-select
				formControlName="job"
				[compareWith]="compareJobs"
				required>
				<mat-option
					*ngFor="let job of jobs"
					[value]="job"
					>{{ job.label }}</mat-option
				>
			</mat-select>
			<mat-icon matIconPrefix>work</mat-icon>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.code.label" | translate }}</mat-label>
			<input
				matInput
				formControlName="code" />
			<mat-icon matIconPrefix>dialpad</mat-icon>
		</mat-form-field>
	</div>
	<div class="flex w-full gap-x-4">
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.password.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="password"
				type="password" />
			<mat-icon matIconPrefix>vpn_key</mat-icon>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="flex flex-1">
			<mat-label>{{ "signup.confirm.label" | translate }}</mat-label>
			<input
				matInput
				required
				formControlName="passwordConfirm"
				type="password" />
			<mat-icon matIconPrefix>vpn_key</mat-icon>
		</mat-form-field>
	</div>
	<div>
		<span class="text-xs">Le mot de passe doit respecter les règles suivantes :</span>
		<ul class="list-inside list-disc text-xs">
			<li>
				{{ "SETTINGS-USER_PASSWORD_CONDITIONS_CHARACTERS" | translate }}
			</li>
			<li>
				{{ "SETTINGS-USER_PASSWORD_CONDITIONS_LETTER" | translate }}
			</li>
			<li>
				{{ "SETTINGS-USER_PASSWORD_CONDITIONS_NUMBER" | translate }}
			</li>
			<li>
				{{ "SETTINGS-USER_PASSWORD_CONDITIONS_CAPSMALL_LETTERS" | translate }}
			</li>
			<li>
				{{ "SETTINGS-USER_PASSWORD_CONDITIONS_INDICATION" | translate }}
			</li>
		</ul>
	</div>
	<p class="text-xs">
		Votre compte ne sera créé que si votre adresse mail ou le code utilisé sont rattachés à un établissement abonné.
	</p>
	<p class="text-xs">
		Si vous souhaitez bénéficier de la plateforme au sein de votre service, merci de nous contacter sur
		contact&#64;medinthepocket.com
	</p>
	<mat-checkbox formControlName="isCGV">J'accepte les Conditions Générales de Vente de MedInThePocket. </mat-checkbox>
</form>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close="">
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		color="primary"
		diameter="40"
		*ngIf="isLoading"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="signup()"
		[disabled]="!userFormGroup.valid"
		*ngIf="!isLoading">
		{{ "core.create" | translate }}
	</button>
</div>
