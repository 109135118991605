import { Component, OnInit } from "@angular/core";
import {
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from "@angular/material/autocomplete";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { Video } from "../../../../entities/videos/video";
import { first, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { VideosService } from "../../../videos/videos.service";
import * as fuzz from "fuzzball";
import { OrderByPipe } from "ngx-pipes";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatDivider } from "@angular/material/divider";
import { UserService } from "../../../../services/user.service";
import { ToastrService } from "ngx-toastr";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-search-videos",
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		AsyncPipe,
		FormsModule,
		MatAutocomplete,
		MatAutocompleteTrigger,
		MatFormField,
		MatIcon,
		MatInput,
		MatLabel,
		MatOption,
		MatSuffix,
		NgForOf,
		NgIf,
		ReactiveFormsModule,
		MatPrefix,
		MatDivider,
		NgxTolgeeModule,
	],
	templateUrl: "./search-videos.component.html",
	styleUrl: "./search-videos.component.scss",
})
export class SearchVideosComponent implements OnInit {
	selectedVideo = null;
	m1Control = new UntypedFormControl();
	filteredVideos: Observable<Video[]>;
	videos: Video[];
	private minLength: number = 3;

	constructor(
		private videosService: VideosService,
		public userService: UserService,
		private oPipe: OrderByPipe,
		private dialogRef: MatDialogRef<SearchVideosComponent>,
		private toastr: ToastrService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.videosService
			.getVideos()
			.pipe(first())
			.subscribe((users) => {
				this.videos = users;
				this.filteredVideos = this.m1Control.valueChanges.pipe(
					startWith(""),
					map((value: string | null) => (value.length > this.minLength ? this._filter(value) : []))
				);
			});
	}

	displayFn(video: Video): string {
		return video && `${video.label}` ? `${video.label}` : "";
	}

	private _filter(value: any): Video[] {
		if (typeof value === "string") {
			const resultUsers = this.videos.filter((it) => {
				return fuzz.partial_ratio(it.label.toLowerCase(), value.trim().toLowerCase()) > 75;
			});
			return this.oPipe.transform(resultUsers, ["label"]);
		}
	}

	selectVideo($event: MatOptionSelectionChange<Video>): void {
		this.selectedVideo = $event.source.value;
	}

	insertVideo(): void {
		if (!this.selectedVideo) {
			this.toastr.warning(this.translate.instant("protocol.edit.tools.integration.videos.error.select"));
			return;
		}
		this.dialogRef.close(`<a href="mitp://videos/${this.selectedVideo.id}">${this.selectedVideo.label}</a>`);
	}
}
