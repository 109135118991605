<div
	mat-dialog-content
	class="flex flex-col">
	<p [innerHTML]="'protocol.tools.embed.instructions.main' | translate"></p>
	<ul class="list-inside list-disc">
		<li>{{ "protocol.tools.embed.instructions.step1" | translate }}</li>
		<li>{{ "protocol.tools.embed.instructions.step2" | translate }}</li>
		<li *ngIf="type === EMBEDDED">
			{{ "protocol.tools.embed.instructions.step3" | translate }}
		</li>
		<li *ngIf="type === EMBEDDED">
			{{ "protocol.tools.embed.instructions.step4" | translate }}
		</li>
	</ul>
	<mat-form-field
		appearance="outline"
		class="w-full">
		<mat-label>{{ "protocol.tools.embed.input.protocol" | translate }}</mat-label>
		<input
			type="text"
			matInput
			[formControl]="m1Control"
			[matAutocomplete]="auto" />
		<mat-autocomplete
			#auto="matAutocomplete"
			[displayWith]="displayFn">
			<mat-option *ngIf="(filteredProtocols | async)?.length > 0">
				<div class="flex flex-row items-center">
					<span class="w-3/4 flex-initial"><mat-icon>assignment</mat-icon></span>
					<span class="w-1/4 flex-initial"><mat-icon>location_city</mat-icon></span>
				</div>
			</mat-option>
			<mat-option
				*ngFor="let option of filteredProtocols | async"
				[value]="option"
				(onSelectionChange)="selectProtocol($event)">
				<div class="flex flex-row items-center">
					<span class="w-3/4 flex-initial">{{ option.label }}</span>
					<div class="mx-4"></div>
					<span class="w-1/4 flex-initial">{{ option.institute.label }}</span>
				</div>
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix>assignment</mat-icon>
	</mat-form-field>
	<mat-radio-group
		class="flex flex-row"
		[(ngModel)]="type">
		<mat-radio-button
			[value]="LINK"
			class="mr-6"
			>{{ "protocol.tools.embed.input.type.link" | translate }}</mat-radio-button
		>
		<mat-radio-button [value]="EMBEDDED">{{
			"protocol.tools.embed.input.type.embed" | translate
		}}</mat-radio-button>
	</mat-radio-group>
	<div
		class="mt-4"
		*ngIf="type === LINK"></div>
	<div
		class="mt-4"
		*ngIf="type === EMBEDDED">
		<div class="flex flex-row items-center justify-around">
			<mat-form-field
				appearance="outline"
				class="flex-1">
				<mat-label>{{ "protocol.tools.embed.input.embedded.label" | translate }}</mat-label>
				<input
					matInput
					[(ngModel)]="caption"
					maxlength="255"
					minlength="4" />
				<mat-hint align="end">{{ caption.length }} / 255</mat-hint>
				<mat-icon matSuffix>label</mat-icon>
			</mat-form-field>
			<div class="mx-4"></div>
			<ngx-colors
				ngx-colors-trigger
				[(ngModel)]="color"
				class="mb-2"></ngx-colors>
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="add()"
		[disabled]="!isEnabled()">
		{{ "core.add" | translate }}
	</button>
</div>
