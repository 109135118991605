<div
	mat-dialog-content
	class="flex flex-col">
	<div class="flex flex-row">
		<mat-form-field
			appearance="outline"
			class="flex-1">
			<mat-label>{{ "PROTOCOL-INIT_DATE" | translate }}</mat-label>
			<input
				matInput
				[matDatepicker]="picker"
				[max]="maxDate"
				disabled
				[(ngModel)]="currentVersion.date" />
			<mat-datepicker-toggle
				matSuffix
				[for]="picker"></mat-datepicker-toggle>
			<mat-datepicker
				#picker
				disabled="false"></mat-datepicker>
		</mat-form-field>
		<div class="mx-1"></div>
		<mat-form-field
			appearance="outline"
			class="flex-1"
			style="margin-right: 16px">
			<mat-label>{{ "PROTOCOL-INIT_VERSION_MAT_LABEL" | translate }}</mat-label>
			<input
				matInput
				[(ngModel)]="currentVersion.version" />
			<mat-icon matIconPrefix>looks_one</mat-icon>
		</mat-form-field>
		<button
			mat-mini-fab
			color="primary"
			style="margin-top: 8px"
			(click)="addVersion()"
			*ngIf="versions.length === 0">
			<mat-icon>add</mat-icon>
		</button>
	</div>
	<div
		*ngFor="let item of versions"
		class="mb-4 flex flex-row items-center">
		<mat-icon
			(click)="deleteVersion(item)"
			style="margin-right: 8px; cursor: pointer"
			>delete
		</mat-icon>
		<label class="w-full"
			>{{ "PROTOCOL-INIT_VERSION_LABEL" | translate }} {{ item.version }}
			{{ "PROTOCOL-INIT_PUBLISHED_ON" | translate }}
			{{ item.date | date: "dd/MM/yyyy" }}</label
		>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="initProtocol()"
		*ngIf="!isLoading"
		[disabled]="versions.length === 0">
		{{ "core.init" | translate }}
	</button>
</div>
