import { Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { Package } from "../../../interfaces/package";
import { OrderByPipe } from "ngx-pipes";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { MatDialogClose } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { NgFor } from "@angular/common";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatStep, MatStepLabel, MatStepper, MatStepperNext } from "@angular/material/stepper";
import { SubscriptionService } from "../../../services/subscription.service";

@Component({
	selector: "app-subscription-process",
	templateUrl: "./subscription-process.component.html",
	styleUrls: ["./subscription-process.component.scss"],
	standalone: true,
	imports: [
		MatStepper,
		MatStep,
		MatStepLabel,
		MatRadioGroup,
		ReactiveFormsModule,
		FormsModule,
		NgFor,
		MatRadioButton,
		MatButton,
		MatDialogClose,
		MatStepperNext,
		MatIcon,
		MatCheckbox,
	],
})
export class SubscriptionProcessComponent implements OnInit {
	packageForm: UntypedFormGroup;
	packages: Package[];
	resume: string;
	selectedPackage: Package;
	isCGV: boolean = false;

	constructor(
		private subscriptionService: SubscriptionService,
		private oPipe: OrderByPipe
	) {}

	ngOnInit(): void {
		this.subscriptionService.getEnabledPackages().subscribe((packs) => {
			this.packages = this.oPipe.transform(packs, "label");
		});
	}

	pay(): void {
		this.subscriptionService.createStripeSession(this.selectedPackage.id).subscribe((response) => {
			if (response && response.session_url != "") {
				window.location.href = response.session_url;
			}
		});
	}
}
