<div mat-dialog-content>
	<mat-horizontal-stepper
		linear
		#stepper>
		<mat-step [stepControl]="firstFormGroup">
			<form [formGroup]="firstFormGroup">
				<ng-template matStepLabel
					>{{ "CREATE-PROTOCOL_TITLE_PROTOCOL_MAT_STEP_LABEL" | translate }}
				</ng-template>
				<p></p>
				<div class="flex flex-col">
					<mat-form-field
						class="w-full"
						appearance="outline">
						<mat-label>{{ "CREATE-PROTOCOL_TITLE_PROTOCOL_LABEL" | translate }}</mat-label>
						<input
							cdkFocusInitial
							formControlName="firstCtrl"
							matInput
							maxlength="255"
							minlength="4"
							required />
						<mat-hint align="end">{{ firstFormGroup.get("firstCtrl").value.length }} / 255</mat-hint>
						<mat-icon matIconPrefix>label</mat-icon>
					</mat-form-field>
					<div class="flex flex-row justify-end">
						<button
							mat-button
							mat-dialog-close>
							{{ "core.cancel" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							color="primary">
							{{ "core.next" | translate }}
						</button>
					</div>
				</div>
			</form>
		</mat-step>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "tags.title.label" | translate }}</ng-template>
				<p></p>
				<app-tag-filter
					placeholder="{{ 'tags.title.label' | translate }}"
					[(tags)]="protocol.tags"
					enabledTagCreation="true"></app-tag-filter>
				<p
					class="italic"
					style="color: red"
					[hidden]="isOfflineAvailable()">
					{{ "CREATE-PROTOCOL_INDICATION_ACCESSIBILITY_PROTOCOL" | translate }}<br />
					{{ "CREATE-PROTOCOL_SPECIALITY_TAG" | translate }}
				</p>
				<div class="flex flex-row justify-end">
					<button
						mat-button
						mat-dialog-close>
						{{ "core.cancel" | translate }}
					</button>
					<button
						mat-button
						matStepperPrevious>
						{{ "core.previous" | translate }}
					</button>
					<button
						mat-button
						matStepperNext
						color="primary">
						{{ "core.next" | translate }}
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "CREATE-PROTOCOL_AUTHORS" | translate }}</ng-template>
				<p></p>
				<app-authors-filter
					placeholder="{{ 'CREATE-PROTOCOL_USERS' | translate }}"
					[modeCreate]="true"
					[(authors)]="protocol.authors"
					[(approvers)]="protocol.approvers"
					[(managers)]="protocol.managers"></app-authors-filter>
				<div
					class="mt-4 flex flex-row justify-end"
					style="margin-top: 16px">
					<button
						mat-button
						mat-dialog-close>
						{{ "core.cancel" | translate }}
					</button>
					<button
						mat-button
						matStepperPrevious>
						{{ "core.previous" | translate }}
					</button>
					<button
						mat-button
						matStepperNext
						color="primary">
						{{ "core.next" | translate }}
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step>
			<ng-template matStepLabel>{{ "CREATE-PROTOCOL_SUMMARY" | translate }}</ng-template>
			<div class="flex flex-row items-center justify-start">
				<mat-icon
					style="color: green"
					*ngIf="doneLabel"
					>check
				</mat-icon>
				<span
					><b>{{ "CREATE-PROTOCOL_TITLE_SPAN_BOLD" | translate }}</b>
					{{ firstFormGroup.get("firstCtrl").value }}</span
				>
			</div>
			<div class="flex flex-row items-center justify-start">
				<mat-icon
					style="color: green"
					*ngIf="doneTags"
					>check
				</mat-icon>
				<span
					><b>{{ "CREATE-PROTOCOL_TAGS" | translate }}</b> {{ listTags() }}</span
				>
			</div>
			<div class="flex flex-row items-center justify-start">
				<mat-icon
					style="color: green"
					*ngIf="doneAuthors"
					>check
				</mat-icon>
				<span
					><b>{{ "CREATE-PROTOCOL_EDITORS" | translate }}</b> {{ listWriters() }}</span
				>
			</div>
			<div class="flex flex-row items-center justify-start">
				<mat-icon
					style="color: green"
					*ngIf="doneApprovers"
					>check
				</mat-icon>
				<span
					><b>{{ "CREATE-PROTOCOL_APPROVERS" | translate }}</b> {{ listApprovers() }}</span
				>
			</div>
			<div class="flex flex-row items-center justify-start">
				<mat-icon
					style="color: green"
					*ngIf="doneManagers"
					>check
				</mat-icon>
				<span
					><b>{{ "CREATE-PROTOCOL_MANAGERS" | translate }}</b> {{ listManagers() }}</span
				>
			</div>
			<div class="flex flex-row items-center justify-end">
				<button
					mat-button
					mat-dialog-close>
					{{ "core.cancel" | translate }}
				</button>
				<button
					mat-button
					matStepperPrevious>
					{{ "core.previous" | translate }}
				</button>
				<mat-spinner
					*ngIf="isLoading"
					color="primary"
					[diameter]="40"></mat-spinner>
				<button
					mat-raised-button
					color="primary"
					(click)="createProtocol()"
					*ngIf="!isLoading">
					{{ "core.create" | translate }}
				</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>
