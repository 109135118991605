<mat-horizontal-stepper
	linear
	#stepper>
	<mat-step [stepControl]="packageForm">
		<div>
			<ng-template matStepLabel>Forfait</ng-template>
			<div class="flex flex-col">
				<mat-radio-group
					[(ngModel)]="selectedPackage"
					class="flex flex-col">
					<mat-radio-button
						*ngFor="let pack of packages"
						[value]="pack"
						>{{ pack.label }} à {{ pack.price }} €/{{ pack.duration }}
						mois
					</mat-radio-button>
				</mat-radio-group>
				<div class="flex flex-row items-center justify-end">
					<button
						mat-button
						mat-dialog-close>
						Annuler
					</button>
					<button
						mat-button
						matStepperNext
						color="primary"
						[disabled]="selectedPackage === null">
						Suivant
					</button>
				</div>
			</div>
		</div>
	</mat-step>
	<mat-step>
		<div>
			<ng-template matStepLabel>Récapitulatif</ng-template>
			<div class="flex flex-col">
				<div class="flex flex-row items-center">
					<mat-icon>check</mat-icon>
					<span><b>Forfait :</b> {{ selectedPackage?.label }}</span>
				</div>
				<div class="flex flex-row items-center">
					<mat-icon>check</mat-icon>
					<span><b>Durée :</b> {{ selectedPackage?.duration }} mois</span>
				</div>
				<div class="flex flex-row items-center">
					<mat-icon>check</mat-icon>
					<span><b>Montant à régler :</b> {{ selectedPackage?.price }}€</span>
				</div>
				<p></p>
				<mat-checkbox [(ngModel)]="isCGV"
					>J'accepte les
					<a
						href="https://medinthepocket.com/cgv.html"
						target="_blank"
						>Conditions Générales de Vente</a
					>
				</mat-checkbox>

				<div class="flex flex-row items-center justify-end">
					<button
						mat-button
						mat-dialog-close>
						Annuler
					</button>
					<button
						mat-raised-button
						color="primary"
						(click)="pay()"
						[disabled]="!isCGV">
						Payer
					</button>
				</div>
			</div>
		</div>
	</mat-step>
</mat-horizontal-stepper>
