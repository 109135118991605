<div
	mat-dialog-content
	class="flex flex-row items-center">
	<div
		style="font-size: 1.4em; margin-right: 8px"
		class="flex-1">
		Paiement réussi.<br />Votre compte sera activé d'ici quelques instants.
	</div>
	<img
		src="../../../../assets/images/payment-check.png"
		width="80px"
		style="margin-left: 16px" />
</div>
<div
	mat-dialog-actions
	class="flex flex-row justify-end">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
