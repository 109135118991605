import { Component, Inject, ViewChild } from "@angular/core";
import { FilePond, FilePondOptions } from "filepond";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogTitle,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatHint, MatPrefix } from "@angular/material/form-field";
import { FilePondModule } from "ngx-filepond";
import { ProtocolService } from "../../../../services/protocol.service";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-protocol-attachments",
	templateUrl: "./protocol-attachments.component.html",
	styleUrls: ["./protocol-attachments.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		ReactiveFormsModule,
		FilePondModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatHint,
		MatIcon,
		MatPrefix,
		MatCheckbox,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class ProtocolAttachmentsComponent {
	isLoading = false;
	extension = "";
	formGroup: UntypedFormGroup;
	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.embed.document.placeholder"),
		acceptedFileTypes: [
			"image/png",
			"image/jpeg",
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
			"application/zip",
		],
		maxFileSize: "2MB",
		labelMaxFileSizeExceeded: this.translate.instant("protocol.tools.embed.document.error.size-exceeded"),
		labelMaxFileSize: this.translate.instant("protocol.tools.embed.document.size-max") + " {filesize}",
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.embed.document.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.embed.document.authorized-document") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: null,
		credits: false,
	};
	@ViewChild("myPond") myPond!: FilePond;

	constructor(
		@Inject(MAT_DIALOG_DATA) public protocol: Protocol,
		private protocolService: ProtocolService,
		private translate: TranslateService,
		private formBuilder: UntypedFormBuilder,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<ProtocolAttachmentsComponent>
	) {
		this.formGroup = this.formBuilder.group({
			label: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
			notes: ["", []],
			enableSharing: [false, []],
		});
	}

	apply(): void {
		const formData = new FormData();
		formData.append("file", this.myPond.getFile().file);
		formData.append("label", this.formGroup.get("label").value);
		formData.append("notes", this.formGroup.get("notes").value);
		formData.append("enableSharing", this.formGroup.get("enableSharing").value);
		formData.append("extension", this.extension);
		this.protocolService.addAttachment(this.protocol.id, formData).subscribe({
			next: (response) => {
				if (response.status === 201) {
					this.toastr.success(this.translate.instant("protocols.attachments.create-dialog.creation.success"));
					this.dialogRef.close(response.body);
				} else {
					this.toastr.error(this.translate.instant("core.message.error.unknown"));
				}
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	isFileSelected(): boolean {
		return this.myPond.getFile().file != null;
	}

	onAddFile($event: any): void {
		if ($event.error == null) {
			this.extension = $event.file.fileExtension;
			this.formGroup.get("label").setValue($event.file.filenameWithoutExtension);
		} else {
			this.toastr.error($event.error.main);
			this.formGroup.get("label").setValue("");
			this.extension = "";
		}
	}
}
