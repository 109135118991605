import { HostBinding, Injectable } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Theme } from "../enums/Theme";
import { MitpManagerService } from "./mitp-manager.service";
import { OverlayContainer } from "@angular/cdk/overlay";
import { UserService } from "./user.service";

@Injectable({
	providedIn: "root",
})
export class ThemeService {
	@HostBinding("class") className = "";
	themeControl = new UntypedFormControl(false);

	constructor(
		private manager: MitpManagerService,
		private overlay: OverlayContainer,
		private userService: UserService
	) {}

	/*
	 * Check the user's theme preference and set the theme accordingly.
	 * toogleControl = false -> light theme
	 * toogleControl = true -> dark theme
	 */
	checkUserTheme(): void {
		this.userService.getUserConfig().subscribe((response) => {
			this.manager.setUserConfig(response);
			if (response.theme == Theme.LIGHT) {
				this.themeControl.patchValue(false);
			} else if (response.theme == Theme.DARK) {
				this.themeControl.patchValue(true);
			}
		});
	}

	setTheme(): void {
		this.themeControl.valueChanges.subscribe((darkMode) => {
			const darkClassName = "darkTheme";
			this.className = darkMode ? darkClassName : "";
			if (darkMode) {
				this.overlay.getContainerElement().classList.add(darkClassName);
			} else {
				this.overlay.getContainerElement().classList.remove(darkClassName);
			}
		});
		if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
			this.themeControl.setValue(true);
			window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
				this.themeControl.setValue(event.matches);
			});
		}
	}

	isDarkTheme(): boolean {
		return this.themeControl.value;
	}

	setDarkTheme(): void {
		this.themeControl.setValue(true);
	}

	setLightTheme(): void {
		this.themeControl.setValue(false);
	}
}
