import { Component, Inject, OnInit } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogTitle,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { SelectiveAlgorithm } from "../../../../interfaces/selective-algorithm";
import { FlatNode } from "../../../../interfaces/flat-node";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
	MatTree,
	MatTreeNodeDef,
	MatTreeNode,
	MatTreeNodePadding,
} from "@angular/material/tree";
import { MitpManagerService } from "../../../../services/mitp-manager.service";
import { ToastrService } from "ngx-toastr";
import { OrderByPipe } from "ngx-pipes";
import { SelectiveAlgorithmContainer } from "../../../../interfaces/selective-algorithm-container";
import { InteractiveComponent } from "../../../../interfaces/interactive-component";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatDivider } from "@angular/material/divider";
import { MatIcon } from "@angular/material/icon";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatHint, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-selective-algorithms",
	templateUrl: "./selective-algorithms.component.html",
	styleUrls: ["./selective-algorithms.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatHint,
		MatIcon,
		MatSuffix,
		MatDivider,
		MatTree,
		MatRadioGroup,
		MatTreeNodeDef,
		MatTreeNode,
		MatTreeNodePadding,
		MatIconButton,
		MatRadioButton,
		NgIf,
		MatButton,
		MatDialogActions,
		MatTooltip,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class SelectiveAlgorithmsComponent implements OnInit {
	static MITP_ALGO = "MITP-ALGO";
	nextId = 1;
	item: SelectiveAlgorithm = {
		description: "",
		id: 1,
		label: "",
		question: "",
		responses: [],
	};
	selectedItem = null;
	selectiveAlgoContainer: SelectiveAlgorithmContainer = {
		algo: [],
		title: "",
	};
	treeControl = new FlatTreeControl<FlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SelectiveAlgorithmContainer | null,
		private oPipe: OrderByPipe,
		private manager: MitpManagerService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<SelectiveAlgorithmsComponent>,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		if (this.data != null) {
			this.selectiveAlgoContainer = this.data;
			this.preAlgo();
		}
	}

	hasChild = (_: number, node: FlatNode) => node.expandable;

	apply(): void {
		if (!this.isAlgoReady()) {
			return;
		}
		const id = Date.now().toString(36);
		const content = `<div id="${id}" style="border: solid rgb(40, 147, 237); padding: 16px">
                    <button id="mitp-algo-reset" style="height: 30px; float:right">X</button>
                    <div>${this.selectiveAlgoContainer.title}</div><div id="steps"></div>
                    <hr>
                    <div id="question" style="font-weight: bold"></div>
                    <div id="container"></div>
                </div>`;
		const mitpAlgo = document.createElement("mitp-algo");
		mitpAlgo.setAttribute("id", id);
		mitpAlgo.dataset.dataAlgo = JSON.stringify(this.selectiveAlgoContainer);
		mitpAlgo.innerHTML = content;
		const serializer = new XMLSerializer();
		const html = serializer.serializeToString(mitpAlgo);
		const script = `\n/* ${id} */\nvar algo_${id} = ${JSON.stringify(
			this.selectiveAlgoContainer
		)};\nloadSelectiveAlgorithm('${id}', algo_${id}['algo'][0], algo_${id}['algo'][0]);\n/* ${id} */\n`;
		const output: InteractiveComponent = { html: html, script: script };
		this.dialogRef.close(output);
	}

	addRoot(): void {
		this.selectiveAlgoContainer.algo.push(this.item);
		this.postItemInsert(this.selectiveAlgoContainer.algo[0]);
	}

	addAsChild(): void {
		if (this.selectedItem == null) {
			this.toastr.warning(this.translate.instant("protocol.tools.diagram.selective.error.select"));
			return;
		}
		const target = this.findNode(this.selectiveAlgoContainer.algo[0], this.selectedItem.node.id);
		if (target != null) {
			target.responses.push(this.item);
			this.postItemInsert(target);
		}
	}

	addAsBrother(): void {
		if (this.selectedItem == null) {
			this.toastr.warning(this.translate.instant("protocol.tools.diagram.selective.error.select"));
			return;
		}
		if (this.selectedItem.level == 0) {
			this.toastr.warning(this.translate.instant("protocol.tools.diagram.selective.add.error.parent-node"));
			return;
		}
		const parent = this.findParent(this.selectiveAlgoContainer.algo[0], this.selectedItem.node.id);
		if (parent != null) {
			parent.responses.push(this.item);
			this.postItemInsert(parent);
		}
	}

	newNode(): void {
		this.item = {
			id: this.nextId,
			label: "",
			description: "",
			question: "",
			responses: [],
		};
	}

	editNode(): void {
		this.item = this.selectedItem.node;
	}

	deleteNode(): void {
		if (this.selectedItem == null) {
			this.toastr.warning(this.translate.instant("protocol.tools.diagram.selective.delete.error.select"));
			return;
		}
		if (this.selectedItem.level == 0) {
			this.toastr.warning(this.translate.instant("protocol.tools.diagram.selective.delete.error.parent-node"));
			return;
		}
		const parent = this.findParent(this.selectiveAlgoContainer.algo[0], this.selectedItem.node.id);
		const i = parent.responses.map((e) => e.id).indexOf(this.selectedItem.node.id);
		if (i > -1) {
			parent.responses.splice(i, 1);
			this.postItemInsert(null);
		}
	}

	restoreAlgo(): void {
		const algo = this.manager.readFromTemp(SelectiveAlgorithmsComponent.MITP_ALGO);
		this.selectiveAlgoContainer = JSON.parse(algo);
		this.preAlgo();
	}

	private _transformer = (node: SelectiveAlgorithm, level: number) => {
		return {
			expandable: node.responses.length > 0,
			name: node.question,
			node: node,
			level: level,
		};
	};

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.responses
	);
	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	private preAlgo(): void {
		this.getNextId(this.selectiveAlgoContainer.algo[0]);
		this.nextId++;
		this.newNode();
		this.dataSource.data = this.selectiveAlgoContainer.algo;
		this.treeControl.expandAll();
	}

	private getNextId(node: SelectiveAlgorithm): void {
		if (node.id > this.nextId) {
			this.nextId = node.id;
		}
		node.responses.forEach((item) => {
			this.getNextId(item);
		});
	}

	private isAlgoReady(): boolean {
		return true;
	}

	private findParent(item: SelectiveAlgorithm, childId: number): SelectiveAlgorithm | null {
		let parent = null;
		if (item.responses.length > 0) {
			for (const child of item.responses) {
				if (child.id == childId) {
					parent = item;
				} else {
					parent = this.findParent(child, childId);
				}
				if (parent != null) {
					break;
				}
			}
		}
		return parent;
	}

	private findNode(item: SelectiveAlgorithm, targetId: number): SelectiveAlgorithm | null {
		if (item.id == targetId) {
			return item;
		} else {
			if (item.responses.length > 0) {
				let target = null;
				for (let i = 0; target == null && i < item.responses.length; i++) {
					target = this.findNode(item.responses[i], targetId);
				}
				return target;
			}
		}
		return null;
	}

	private postItemInsert(node: SelectiveAlgorithm): void {
		this.manager.saveToTemp(SelectiveAlgorithmsComponent.MITP_ALGO, JSON.stringify(this.selectiveAlgoContainer));
		this.dataSource.data = this.selectiveAlgoContainer.algo;
		this.treeControl.expandAll();
		this.selectedItem = null;
		if (node != null) {
			this.nextId++;
			if (node.responses.length > 0) {
				node.responses = this.oPipe.transform(node.responses);
			}
		}
		this.newNode();
	}
}
