import { Component, Inject, OnInit } from "@angular/core";
import {
	AbstractControlOptions,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "app-reset-password",
	standalone: true,
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"],
	imports: [
		MatIconModule,
		MatDialogModule,
		MatLabel,
		FormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatProgressSpinner,
		NgIf,
		MatInput,
		ReactiveFormsModule,
		NgxTolgeeModule,
	],
})
export class ResetPasswordComponent implements OnInit {
	pwdGroup: UntypedFormGroup;
	hide: boolean = true;
	isLoading: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public token: string,
		private userService: UserService,
		private formBuilder: UntypedFormBuilder,
		private toastr: ToastrService,
		private translator: TranslateService,
		private dialogRef: MatDialogRef<ResetPasswordComponent>
	) {}

	ngOnInit(): void {
		this.pwdGroup = this.formBuilder.group(
			{
				newPwd: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
				confirmPwd: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
			},
			{ validator: this.validatePwdAndPwd2 } as AbstractControlOptions
		);
	}

	validatePwdAndPwd2(group: UntypedFormGroup) {
		const pwd = group.get("newPwd").value;
		const pwd2 = group.get("confirmPwd").value;
		return pwd == pwd2 ? null : { notSame: true };
	}

	save(): void {
		this.isLoading = true;
		this.userService.publicResetPassword(this.token, this.pwdGroup.get("newPwd").value).subscribe(
			(response) => {
				if (response.status === 204) {
					this.toastr.success(this.translator.instant("auth.reset-password.success"));
					this.dialogRef.close(true);
				} else {
					this.toastr.error(this.translator.instant("auth.reset-password.error"));
				}
				this.isLoading = false;
			},
			() => (this.isLoading = false)
		);
	}
}
