<div mat-dialog-content>
	<file-pond
		#myPond
		[options]="pondOptions"></file-pond>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="upload()"
		*ngIf="!isLoading"
		[disabled]="isDisabled()">
		{{ "core.replace" | translate }}
	</button>
</div>
