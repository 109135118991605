import { Component, Inject } from "@angular/core";
import { MitpManagerService } from "../../../../services/mitp-manager.service";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { ERequestType, ESharingManagerMode, ESharingMode } from "../../../../interfaces/protocol/isharing";
import { Institute } from "../../../../interfaces/institute";
import { IZone } from "../../../../interfaces/protocol/izone";
import { ProtocolShareSearchResultsComponent } from "../protocol-share-search-results/protocol-share-search-results.component";
import { ToastrService } from "ngx-toastr";
import { OrderByPipe, UniquePipe } from "ngx-pipes";
import { IGlobalShareRequest } from "../../../../interfaces/protocol/iglobal-share-request";
import { MatCard, MatCardTitle } from "@angular/material/card";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { TagFilterComponent } from "../../../tags/tag-filter/tag-filter.component";
import { NgFor, NgIf } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious } from "@angular/material/stepper";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { ProtocolService } from "../../../../services/protocol.service";

@Component({
	selector: "app-share-my-protocols",
	templateUrl: "./share-my-protocols.component.html",
	styleUrls: ["./share-my-protocols.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatIcon,
		MatStepper,
		MatStep,
		MatStepLabel,
		MatCheckbox,
		ReactiveFormsModule,
		FormsModule,
		MatRadioGroup,
		MatRadioButton,
		MatButton,
		MatDialogClose,
		MatStepperNext,
		NgIf,
		TagFilterComponent,
		MatStepperPrevious,
		MatFormField,
		MatLabel,
		MatPrefix,
		MatInput,
		MatSuffix,
		MatProgressSpinner,
		MatCard,
		MatCardTitle,
		NgFor,
		MatIconButton,
		NgxTolgeeModule,
	],
})
export class ShareMyProtocolsComponent {
	isLoading: boolean = false;
	isLoading2: boolean = false;
	searchKeyword: string = "";
	ESharingMode = ESharingMode;
	ERequestType = ERequestType;
	ESharingManagerMode = ESharingManagerMode;
	share: IGlobalShareRequest = {
		enable: false,
		request: ERequestType.ADD,
		mode: ESharingMode.DISABLED,
		protocols: ESharingManagerMode.ALL_PROTOCOLS,
		tags: [],
		institutes: [],
		zones: [],
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private protocolService: ProtocolService,
		private manager: MitpManagerService,
		private dialogRef: MatDialogRef<ShareMyProtocolsComponent>,
		private translate: TranslateService,
		private toastr: ToastrService,
		private dialog: MatDialog,
		private uniquePipe: UniquePipe,
		private orderByPipe: OrderByPipe
	) {}

	apply() {
		this.isLoading = true;
		this.protocolService
			.updateSharedProtocols({
				enable: this.share.enable,
				request: this.share.request,
				mode: this.share.mode,
				protocols: this.share.protocols,
				tags: this.share.tags.map((e) => e.id),
				institutes: this.share.institutes.map((e) => e.id),
				zones: this.share.zones.map((e) => e.id),
			})
			.subscribe(
				(response) => {
					this.isLoading = false;
					if (response.status === 204) {
						this.manager.openInfoDialog(this.translate.instant("protocol.share.manager.success"));
						this.dialogRef.close();
					} else {
						this.manager.openErrorDialog(this.translate.instant("protocol.share.manager.error"));
					}
				},
				() => (this.isLoading = false)
			);
	}

	getSharingEnableLabel(): string {
		return this.share.enable
			? this.translate.instant("protocol.share.enabled")
			: this.translate.instant("protocol.share.disabled");
	}

	getArrayJoinedLabel(items: any[]) {
		if (items.length === 0) {
			return "-";
		}
		return items.map((e) => e.label).join(", ");
	}

	getSharedProtocolsCount(): number {
		const tagIds = this.share.tags.map((e) => e.id);

		switch (this.share.protocols) {
			case ESharingManagerMode.ALL_PROTOCOLS:
				return this.data.protocols.length;
			case ESharingManagerMode.CUSTOM_ALL_TAGS:
			case ESharingManagerMode.CUSTOM_MIN_TAG:
				return this.data.protocols.filter((protocol) => {
					return tagIds.some((e) => protocol.tags.map((t) => t.id).includes(e));
				}).length;
			default:
				return -1;
		}
	}

	getModeLabel(): string {
		switch (this.share.mode) {
			case ESharingMode.DISABLED:
				return this.translate.instant("protocol.share.mode.disabled");
			case ESharingMode.ALL:
				return this.translate.instant("protocol.share.mode.all");
			case this.ESharingMode.CUSTOM:
				return this.translate.instant("protocol.share.mode.custom");
			default:
				return "-";
		}
	}

	getRequestTypeLabel(): string {
		switch (this.share.request) {
			case ERequestType.ADD:
				return this.translate.instant("protocol.share.request-type.add");
			case ERequestType.REPLACE:
				return this.translate.instant("protocol.share.request-type.replace");
			default:
				return "-";
		}
	}

	search(keyword: string): void {
		if (keyword.trim() === "") {
			this.toastr.warning(this.translate.instant("protocol.share.search.input.warning"));
			return;
		}
		this.isLoading = true;
		this.protocolService.searchProtocolSharingRecipients(keyword).subscribe({
			next: (e) => {
				if (e.institutes.length == 0 && e.zones.length === 0) {
					this.toastr.info(this.translate.instant("core.message.no-result"));
				} else {
					this.dialog
						.open(ProtocolShareSearchResultsComponent, {
							minWidth: "600px",
							data: { protocol: null, result: e },
							disableClose: true,
							autoFocus: false,
						})
						.afterClosed()
						.subscribe((result) => {
							this.searchKeyword = "";

							const mergedArraysI = [...this.share.institutes, ...result.institutes];
							this.share.institutes = this.uniquePipe.transform(mergedArraysI, "id");
							this.share.institutes = this.orderByPipe.transform(this.share.institutes, "label");

							const mergedArraysZ = [...this.share.zones, ...result.zones];
							this.share.zones = this.uniquePipe.transform(mergedArraysZ, "id");
							this.share.zones = this.orderByPipe.transform(this.share.zones, "label");
						});
				}
				this.isLoading = false;
			},
			error: () => (this.isLoading = false),
		});
	}

	removeShareInstitute(institute: Institute): void {
		const i = this.share.institutes.map((e) => e.id).indexOf(institute.id);
		if (i > -1) {
			this.share.institutes.splice(i, 1);
		}
	}

	removeShareZone(zone: IZone): void {
		const i = this.share.zones.map((e) => e.id).indexOf(zone.id);
		if (i > -1) {
			this.share.zones.splice(i, 1);
		}
	}
}
