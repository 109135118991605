<div
	mat-dialog-title
	[align]="'end'">
	<button
		mat-icon-button
		mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<mat-horizontal-stepper linear>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "tags.title.label" | translate }}</ng-template>
				<app-tag-filter
					placeholder="{{ 'tags.title.label' | translate }}"
					enabledTagCreation="true"
					[(tags)]="protocol.tags"
					[protocolId]="protocol.id"></app-tag-filter>
				<p
					style="color: red; font-style: italic"
					[hidden]="isOfflineAvailable()">
					{{ "PROTOCOL-VALIDATE_INDICATION_PROTOCOL_POSSESSION_TAG_ACCESSIBILITY_OFFLINE" | translate }}<br />
					{{ "PROTOCOL-VALIDATE_INDICATION_TAG_SPECIALITY" | translate }}
				</p>
				<div class="flex flex-row items-center justify-end">
					<button
						mat-button
						matStepperNext
						color="primary">
						{{ "core.next" | translate }}
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step>
			<div>
				<ng-template matStepLabel>{{ "PROTOCOL-VALIDATE_AUTHORS_MAT_STEP_LABEL" | translate }}</ng-template>
				<app-authors-filter
					placeholder="{{ 'PROTOCOL-VALIDATE_AUTHORS_PLACEHOLDER' | translate }}"
					[protocolId]="protocol.id"
					[(authors)]="protocol.authors"
					[(approvers)]="protocol.approvers"
					[(managers)]="protocol.managers"></app-authors-filter>
				<p
					style="color: red; font-style: italic"
					[hidden]="protocol.authors.length > 0 && protocol.approvers.length > 0">
					{{ "PROTOCOL-VALIDATE_INDICATION_ABSENCE_EDITORS_APPROVERS" | translate }}
				</p>
				<div
					class="flex flex-row items-center justify-end"
					style="margin-top: 16px">
					<button
						mat-button
						matStepperPrevious
						color="primary">
						{{ "core.previous" | translate }}
					</button>
					<button
						mat-button
						matStepperNext
						color="primary">
						{{ "core.next" | translate }}
					</button>
				</div>
			</div>
		</mat-step>
		<mat-step [completed]="safety.safe">
			<ng-template matStepLabel>{{ "core.security" | translate }}</ng-template>
			<div class="flex flex-col">
				<div
					*ngIf="isVerifying; else securityBlock"
					class="m-8">
					<div>{{ "protocol.management.publication.verifying" | translate }}</div>
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				</div>
				<ng-template #securityBlock>
					<div
						*ngIf="safety.safe"
						class="flex flex-col items-center">
						<img
							class="my-8"
							alt="secure"
							ngSrc="assets/images/shield.png"
							height="64"
							width="64" />
						<div>{{ "protocol.management.publication.secured-protocol" | translate }}</div>
						<div class="flex flex-row items-center justify-end w-full">
							<button
								mat-button
								matStepperPrevious
								color="primary">
								{{ "core.previous" | translate }}
							</button>
							<button
								mat-button
								matStepperNext
								color="primary">
								{{ "core.next" | translate }}
							</button>
						</div>
					</div>
					<div
						*ngIf="!safety.safe"
						class="flex flex-col items-center">
						<img
							class="my-8"
							alt="secure"
							ngSrc="assets/images/unlock.png"
							height="64"
							width="64" />
						<div>{{ "protocol.management.publication.unsecured-protocol" | translate }}</div>
						<button
							class="my-4"
							mat-raised-button
							color="primary"
							(click)="requestReview()"
							*ngIf="!requestLoading">
							<mat-icon>rate_review</mat-icon>
							<div>{{ "protocol.management.publication.request-review" | translate }}</div>
						</button>
						<mat-spinner
							class="my-4"
							diameter="40"
							color="primary"
							*ngIf="requestLoading"></mat-spinner>
					</div>
				</ng-template>
			</div>
		</mat-step>
		<mat-step class="w-full">
			<ng-template matStepLabel>{{ "PROTOCOL-VALIDATE_UPDATE" | translate }}</ng-template>
			<div class="flex flex-col">
				<p>
					<mat-radio-group
						[(ngModel)]="publishType.update"
						class="flex flex-col">
						<mat-radio-button
							value="major"
							[disabled]="isManager()">
							{{ "PROTOCOL-VALIDATE_UPDATE_MAJOR" | translate }}
						</mat-radio-button>
						<mat-radio-button
							value="minor"
							[disabled]="protocol.updates.length === 0">
							{{ "PROTOCOL-VALIDATE_UPDATE_MINOR" | translate }}
						</mat-radio-button>
					</mat-radio-group>
				</p>
				<mat-form-field
					class="w-full"
					appearance="outline">
					<mat-label>{{ "PROTOCOL-VALIDATE_NOTES" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="publishType.notes" />
					<mat-icon matSuffix>notes</mat-icon>
				</mat-form-field>
			</div>
			<div class="flex w-full flex-row items-center justify-end">
				<button
					mat-button
					matStepperPrevious
					color="primary">
					{{ "core.previous" | translate }}
				</button>
				<mat-spinner
					*ngIf="isLoading"
					color="primary"
					[diameter]="40"></mat-spinner>
				<button
					mat-raised-button
					color="primary"
					(click)="validateProtocol()"
					*ngIf="!isLoading"
					[disabled]="
						protocol.authors.length === 0 || protocol.approvers.length === 0 || publishType.update === ''
					">
					{{ "PROTOCOL-VALIDATE_VALIDATE_PUBLISH" | translate }}
				</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>
