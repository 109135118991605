import { Component, Inject, OnInit } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { first, Observable } from "rxjs";
import { ProtocolAttachment } from "../../../../entities/protocol-attachment";
import { AsyncPipe, NgIf } from "@angular/common";
import { ProtocolService } from "../../../../services/protocol.service";
import { OrderByPipe } from "ngx-pipes";
import * as fuzz from "fuzzball";
import { map, startWith } from "rxjs/operators";
import { MatOptionSelectionChange } from "@angular/material/core";
import { ToastrService } from "ngx-toastr";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-search-attachments",
	standalone: true,
	imports: [
		MatDivider,
		MatDialogActions,
		MatButton,
		MatDialogTitle,
		MatDialogContent,
		MatFormField,
		MatAutocomplete,
		MatOption,
		MatInput,
		MatLabel,
		MatDialogClose,
		MatIcon,
		ReactiveFormsModule,
		MatAutocompleteTrigger,
		AsyncPipe,
		NgIf,
		NgxTolgeeModule,
	],
	templateUrl: "./search-attachments.component.html",
	styleUrl: "./search-attachments.component.scss",
})
export class SearchAttachmentsComponent implements OnInit {
	selectedAttachment: ProtocolAttachment = null;
	m1Control = new UntypedFormControl();
	filteredAttachments: Observable<ProtocolAttachment[]>;
	attachments: ProtocolAttachment[];
	private minLength = 3;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: number,
		private protocolService: ProtocolService,
		private oPipe: OrderByPipe,
		private dialogRef: MatDialogRef<SearchAttachmentsComponent>,
		private toastr: ToastrService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.protocolService
			.getAttachments(this.data)
			.pipe(first())
			.subscribe((attachments) => {
				this.attachments = attachments;
				this.filteredAttachments = this.m1Control.valueChanges.pipe(
					startWith(""),
					map((value: string | null) => (value.length > this.minLength ? this._filter(value) : []))
				);
			});
	}

	private _filter(value: any): ProtocolAttachment[] {
		if (typeof value === "string") {
			const resultUsers = this.attachments.filter((it) => {
				return fuzz.partial_ratio(it.label.toLowerCase(), value.trim().toLowerCase()) > 75;
			});
			return this.oPipe.transform(resultUsers, ["label"]);
		}
	}

	displayFn(attachment: ProtocolAttachment): string {
		return attachment && `${attachment.label}` ? `${attachment.label}` : "";
	}

	insertAttachment(): void {
		if (!this.selectedAttachment) {
			this.toastr.warning(this.translate.instant("protocol.edit.tools.integration.attachments.error.select"));
			return;
		}
		this.dialogRef.close(
			`<a href="mitp://attachments/${this.selectedAttachment.id}">${this.selectedAttachment.label}</a>`
		);
	}

	selectAttachment($event: MatOptionSelectionChange<ProtocolAttachment>): void {
		this.selectedAttachment = $event.source.value;
	}
}
