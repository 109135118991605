import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { MatButton } from "@angular/material/button";
import { FilePondModule } from "ngx-filepond";
import { FilePond, FilePondOptions } from "filepond";
import { PDFDocumentProxy, PdfViewerModule } from "ng2-pdf-viewer";
import html2canvas from "html2canvas";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";

@Component({
	selector: "app-extract-pdf",
	standalone: true,
	imports: [
		MatDialogContent,
		MatDialogActions,
		NgxTolgeeModule,
		MatButton,
		MatDialogClose,
		FilePondModule,
		PdfViewerModule,
		MatProgressSpinner,
		NgIf,
	],
	templateUrl: "./extract-pdf.component.html",
	styleUrl: "./extract-pdf.component.scss",
})
export class ExtractPdfComponent {
	isLoading = false;
	pdfSrc: ArrayBuffer | string;
	pdfViewer: PDFDocumentProxy;
	currentPage: number = 1;
	private isSelecting = false;
	private startX = 0;
	private startY = 0;
	private endX = 0;
	private endY = 0;
	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: this.translate.instant("protocol.tools.extract-pdf.dropzone.label"),
		acceptedFileTypes: ["application/pdf"],
		labelFileTypeNotAllowed: this.translate.instant("protocol.tools.embed.document.error.bad-format"),
		fileValidateTypeLabelExpectedTypes:
			this.translate.instant("protocol.tools.embed.document.authorized-document") + " : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: null,
		credits: false,
	};
	@ViewChild("myPond") myPond!: FilePond;
	@ViewChild("selectionCanvas") selectionCanvas!: ElementRef<HTMLCanvasElement>;
	@ViewChild("parent") parent!: ElementRef<HTMLDivElement>;

	constructor(
		private translate: TranslateService,
		private dialogRef: MatDialogRef<ExtractPdfComponent>
	) {}

	loadPdf() {
		if (this.myPond.getFile()) {
			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				this.pdfSrc = e.target.result;
			};
			const file = this.myPond.getFile().file;
			reader.readAsArrayBuffer(file);
		}
	}

	onLoadComplete(pdf: PDFDocumentProxy) {
		this.pdfViewer = pdf;
		this.setCanvasSize();
	}

	private setCanvasSize(): void {
		const canvas = this.selectionCanvas.nativeElement;
		const pdfContainer = canvas.parentElement;
		if (pdfContainer) {
			canvas.width = pdfContainer.clientWidth - 50;
			canvas.height = pdfContainer.clientHeight - 100;
		}
	}

	startSelection(event: MouseEvent): void {
		this.isSelecting = true;
		const rect = this.selectionCanvas.nativeElement.getBoundingClientRect();
		this.startX = event.clientX - rect.left;
		this.startY = event.clientY - rect.top;
	}

	drawSelection(event: MouseEvent): void {
		if (!this.isSelecting) {
			return;
		}
		const canvas = this.selectionCanvas.nativeElement;
		const ctx = canvas.getContext("2d");
		if (ctx) {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.strokeStyle = "gray";
			ctx.setLineDash([5, 5]);
			ctx.lineWidth = 1;

			const rect = this.selectionCanvas.nativeElement.getBoundingClientRect();
			this.endX = event.clientX - rect.left;
			this.endY = event.clientY - rect.top;

			const width = this.endX - this.startX;
			const height = this.endY - this.startY;

			ctx.strokeRect(this.startX, this.startY, width, height);
		}
	}

	endSelection(): void {
		this.isSelecting = false;
		console.log(this.startX, this.startY, this.endX, this.endY);
	}

	async extract(): Promise<void> {
		this.isLoading = true;
		const pdfContainer = this.selectionCanvas.nativeElement.parentElement;
		if (pdfContainer) {
			const canvas = this.selectionCanvas.nativeElement;
			const ctx = canvas.getContext("2d");
			if (!ctx) return;

			const canvasScreenshot = await html2canvas(pdfContainer, { scale: 1 });

			const cropX = Math.min(this.startX, this.endX) + 29;
			const cropY = Math.min(this.startY, this.endY) + 2;
			const cropWidth = Math.abs(this.endX - this.startX) - 5;
			const cropHeight = Math.abs(this.endY - this.startY) - 3;

			// Create a cropped canvas
			const croppedCanvas = document.createElement("canvas");
			croppedCanvas.width = cropWidth;
			croppedCanvas.height = cropHeight;
			const croppedCtx = croppedCanvas.getContext("2d");
			if (croppedCtx) {
				croppedCtx.drawImage(
					canvasScreenshot,
					cropX,
					cropY,
					cropWidth,
					cropHeight,
					0,
					0,
					cropWidth,
					cropHeight
				);

				const imageData = croppedCanvas.toDataURL("image/png");
				const element = `<img src="${imageData}" alt="" width="${cropWidth}" height="${cropHeight}"/>`;
				this.dialogRef.close(element);
			} else {
				this.isLoading = false;
			}
		}
		this.isLoading = false;
	}

	nextPage(): void {
		if (this.pdfViewer) {
			this.currentPage++;
		}
	}

	isNextPage(): boolean {
		if (!this.pdfViewer) {
			return false;
		}
		return this.currentPage < this.pdfViewer.numPages;
	}

	previousPage(): void {
		if (this.pdfViewer) {
			this.currentPage--;
		}
	}

	isPreviousPage(): boolean {
		if (!this.pdfViewer) {
			return false;
		}
		return this.currentPage > 1;
	}
}
